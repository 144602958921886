import { IRectConfig } from '@/types/common.type';
import React, { useLayoutEffect, useMemo, useState } from 'react';

type IProps = (
  open: boolean,
  listRef: React.RefObject<HTMLUListElement>,
  elementRef: React.RefObject<HTMLDivElement>,
  blockRef: React.RefObject<HTMLDivElement> | undefined,
) => IRectConfig | undefined;

function getElementValue(value: number) {
  return parseInt(String(value), 10);
}

function checkDropDownPosition<T extends HTMLElement>(
  elementRef: React.RefObject<T>,
  blockRef: React.RefObject<T>,
  listHeight: number
) {
  const elementSize: DOMRect | undefined = elementRef.current?.getBoundingClientRect();
  const blockSize: DOMRect | undefined = blockRef.current?.getBoundingClientRect();
  const initialValues = {
    isOverflow: false,
    isPortal: false,
    width: 0,
    left: 0,
    top: 0,
  };

  if (!(blockSize && elementSize)) {
    return initialValues;
  }
  const isOverflow = !!(
    window.innerHeight < elementSize.bottom + listHeight || blockSize?.bottom < elementSize?.bottom
  );
  return {
    isOverflow,
    isPortal: true,
    width: getElementValue(elementSize.width),
    left: getElementValue(elementSize.left),
    top: getElementValue(isOverflow ? elementSize.top - listHeight : elementSize.bottom),
  };
}

const useRect: IProps = (open, listRef, elementRef, blockRef) => {
  const [listHeight, setListHeight] = useState(0);
  const config: IRectConfig | undefined = useMemo(() => {
    if (open && listRef && blockRef && listHeight) {
      return checkDropDownPosition(elementRef, blockRef, listHeight);
    }
    return undefined;
  }, [open, listHeight, listRef, elementRef, blockRef]);

  useLayoutEffect(() => {
    if (open && listRef.current) {
      setListHeight(listRef.current.clientHeight);
    }
  }, [open, listRef, blockRef]);

  return config;
};

export default useRect;
