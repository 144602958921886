import { TableSortOrders } from '@/components/Table/types';
import { DropWidget, ReportLayout, ReportWidget, WidgetAction } from '@/types/report.type';
import { arrayMoveImmutable } from '@/utils/array';
import React, { FC, useCallback, useMemo } from 'react';
import { v4 as guid } from 'uuid';
import mapping from '../widgets/mapping';
import * as Styled from './style';
import useDropLayout from './useDropLayout';

type Props = {
  readonly: boolean;
  sortable: boolean;
  id: string | number;
  widgets: ReportWidget<unknown>[];
  structure: ReportLayout;
  reportName: string | undefined;
  reportId: number | undefined;
  tableResultsCount: number | undefined;
  pageNumber: number | undefined;
  order: TableSortOrders | undefined;
  orderBy: string | undefined;
  onAddWidget?: (widget: DropWidget, layoutId: string | number) => void;
  onMoveWidget?: (widget: DropWidget, layoutId: string | number) => void;
  onSortWidgets?: (layoutId: string | number, newWidgets: ReportWidget<unknown>[]) => void;
  onRemoveWidget?: (widget: ReportWidget<unknown>, layoutId: string | number) => void;
  onUpdateWidget?: (widget: ReportWidget<unknown>, layoutId: string | number) => void;
  handleUpdateRunningQuery?: (query: {
    page: number;
    perPage: number;
    orderBy: string | undefined;
    order: string | undefined;
    type: string;
  }) => void;
};

type SortType = {
  oldIndex: number;
  newIndex: number;
};
const dumpFunc = () => {};

const Target: FC<Props> = ({
  readonly,
  sortable,
  id,
  widgets,
  structure,
  reportName,
  reportId,
  tableResultsCount,
  pageNumber,
  order,
  orderBy,
  onAddWidget,
  onMoveWidget,
  onSortWidgets,
  onRemoveWidget,
  onUpdateWidget,
  handleUpdateRunningQuery,
}) => {
  const onUpdate = (item: DropWidget) => {
    if (onAddWidget && onMoveWidget) {
      if (item.type === WidgetAction.ADD) {
        onAddWidget({ ...item, widget: { ...item.widget, id: `temp-id-${guid()}` } }, id);
      } else {
        onMoveWidget(item, id);
      }
    }
  };
  const { ref: dropRef, isActive } = useDropLayout(onUpdate);

  const Canvas = useMemo(
    () => ({
      Container: !sortable ? Styled.Canvas : Styled.SortableCanvas,
      Item: !sortable ? Styled.CanvasItem : Styled.SortableCanvasItem,
    }),
    [sortable]
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortType) => {
      const newWidgets = arrayMoveImmutable(widgets, oldIndex, newIndex) as ReportWidget<unknown>[];
      if (onSortWidgets) {
        onSortWidgets(id, newWidgets);
      }
    },
    [id, onSortWidgets, widgets]
  );

  return (
    <Styled.DropContainer isActive={isActive} readonly={readonly} ref={!readonly ? dropRef : null}>
      <Canvas.Container {...(sortable ? { onSortEnd } : {})}>
        {widgets.map((item: ReportWidget<any>, index: number) => {
          const Component = mapping[item.key];
          if (!Component) {
            return null;
          }
          return (
            <Canvas.Item index={index} key={item.id} sortable={sortable}>
              <Component
                data={item}
                key={item.id}
                id={id}
                reportName={reportName}
                reportId={reportId}
                readonly={readonly}
                structure={structure}
                tableResultsCount={tableResultsCount}
                pageNumber={pageNumber}
                order={order}
                orderBy={orderBy}
                onRemoveWidget={onRemoveWidget || dumpFunc}
                onUpdateWidget={onUpdateWidget || dumpFunc}
                handleUpdateRunningQuery={handleUpdateRunningQuery || dumpFunc}
              />
            </Canvas.Item>
          );
        })}
      </Canvas.Container>
    </Styled.DropContainer>
  );
};

export default Target;
