import { Header, Sidebar, SplashScreen } from '@/components';
import { useGetProfileQuery, useGetRolesQuery } from '@/redux/user/user.api';
import React, { FC, PropsWithChildren, useState } from 'react';
import * as Styled from './style';

export enum SidebarStates {
  open = 'open',
  closed = 'closed',
}

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading: isProfileLoading } = useGetProfileQuery();
  const { isLoading: isRolesLoading } = useGetRolesQuery();

  const initialSidebarState: string = localStorage.getItem('sidebar-state') || SidebarStates.open;

  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(
    initialSidebarState === SidebarStates.open
  );

  const toogleSidebar = () => {
    localStorage.setItem(
      'sidebar-state',
      String(!isOpenSidebar ? SidebarStates.open : SidebarStates.closed)
    );
    setIsOpenSidebar(!isOpenSidebar);
  };

  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = useState(false);
  const toogleMobileSidebar = () => {
    setIsOpenMobileSidebar(!isOpenMobileSidebar);
  };

  const sidebarHandler = () => {
    if (window.innerWidth <= 768) {
      toogleMobileSidebar();
    } else {
      toogleSidebar();
    }
  };

  return (
    <Styled.Container>
      <SplashScreen active={isProfileLoading || isRolesLoading} />
      <Sidebar
        sidebarOpen={isOpenSidebar}
        sidebarClose={toogleSidebar}
        mobileSidebarOpen={isOpenMobileSidebar}
        mobileSidebarClose={toogleMobileSidebar}
      />
      <Styled.ContentWrapper isOpenSidebar={isOpenSidebar}>
        <Header sidebarControl={sidebarHandler} />
        <Styled.Content >{children}</Styled.Content>
      </Styled.ContentWrapper>
    </Styled.Container>
  );
};

export default AppLayout;
