import useCustomTranslation from '@/localization/useCustomTranslation';
import { FilterAttributes, FilterContext } from '@/Providers/FilterProvider';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useContext } from 'react';
import FilterGroup from './FilterGroup/FilterGroup';
import * as Styled from './style';

type FilterFormProps = {
  onClose: () => void;
};

const FilterForm: FC<FilterFormProps> = ({ onClose }) => {
  const { t } = useCustomTranslation('');
  const { setFilterAttributes, filterAttributes } = useContext(FilterContext);
  const initialValues = filterAttributes;

  const onSubmit = (
    values: FilterAttributes,
    { setSubmitting }: FormikHelpers<FilterAttributes>
  ) => {
    setFilterAttributes(values);
    setSubmitting(false);
    onClose();
  };

  return (
    <Styled.Container>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {() => (
          <Form>
            <Styled.TitlesContainer>
              <h3>{t('REPORT.FILTERS.FILTER.BUTTON')}</h3>
              <Styled.SubmitBtn type="submit">{t('REPORT.FILTERS.SAVE.BUTTON')}</Styled.SubmitBtn>
            </Styled.TitlesContainer>
            <FilterGroup
              title={t('REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE')}
              filterKey="predicted_size"
              filterOptions={[{
                label: t('REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.SMALL'),
                formName: 'Small',
              }, {
                label: t('REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.MEDIUM'),
                formName: 'Medium',
              }, {
                label: t('REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.LARGE'),
                formName: 'Large',
              }]}
            />
            <FilterGroup
              title={t('REPORT.FILTERS.DROPDOWN.STATUS')}
              filterKey="status"
              filterOptions={[
                {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.AWAITING_SCHEDULING'),
                  formName: 'Awaiting Scheduling',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.SCHEDULED'),
                  formName: 'Scheduled',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.CONFIRMED_NOISE_SOURCE'),
                  formName: 'Confirmed Noise Source',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.MARKED_UP'),
                  formName: 'Marked Up',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.UNCONFIRMED_NOISE_SOURCE'),
                  formName: 'Unconfirmed Noise Source',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.SCHEDULING'),
                  formName: 'Scheduling',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.CLOSED'),
                  formName: 'Closed',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.LEAK_SUBMITTED_TO_CLIENT'),
                  formName: 'Leak Submitted To Client',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.REPAIRED'),
                  formName: 'Repaired',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.DRY_DIG'),
                  formName: 'Dry Dig',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.EDITED'),
                  formName: 'Edited',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.PROPOSED'),
                  formName: 'Proposed',
                }, {
                  label: t('REPORT.FILTERS.DROPDOWN.STATUS.FURTHER_WORK_REQUESTED'),
                  formName: 'Further Work Requested',
                },
              ]}
            />
          </Form>
        )}
      </Formik>
    </Styled.Container>
  );
};

export default FilterForm;
