import {
  ChangeLogContainer,
  CompaniesContainer,
  DashboardContainer,
  EditCompanyContainer,
  ForgotPasswordContainer,
  LoginContainer,
  ProjectStatusContainer,
  RecoveryContainer,
  RecoveryExpiredContainer,
  ReportBuilderContainer,
  ReportsContainer,
  RunningReportContainer,
  UserDetailsContainer,
  UsersContainer
} from '@/containers';
import ConsumptionProfileContainer from '@/containers/ConsumptionProfile/ConsumptionProfileContainer';
import CorrelationContainer from '@/containers/Correlation/CorrelationContainer';
import DeliveryPerformanceBDEContainer from '@/containers/DeliveryPerformanceBDE/DeliveryPerformanceBDEContainer';
import DeliveryPerformanceLEContainer from '@/containers/DeliveryPerformanceLE/DeliveryPerformanceLEContainer';
import { ProfileContainer } from '@/containers/Profile';
import { RunningReportOutlet } from '@/containers/RunningReport';
import SessionContainer from '@/containers/Session/SessionContainer';
import TopSoundContainer from '@/containers/TopSound/TopSoundContainer';
import WaypointContainer from '@/containers/Waypoint/WaypointContainer';
import { AuthGuard } from '@/guards/AuthGuard';
import { PermissionGuard } from '@/guards/PermissionGuard';
import { AppLayout } from '@/layouts';
import { DashboardProvider } from '@/Providers/DashboardProvider';
import { DeliveryPerformanceProvider } from '@/Providers/DeliveryPerformanceProvider';
import type { AppRoute, AppRoutes } from '@/types/router.type';
import { UserRoles } from '@/types/user.type';
import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { FilterProvider } from '../Providers/FilterProvider';

const LOGIN_REDIRECT = '/auth/login';

export const routes: AppRoutes = [
  {
    path: '/',
    element: <Navigate to={LOGIN_REDIRECT} />,
  },
  {
    path: 'auth',
    guards: [{ Guard: AuthGuard, props: { redirect: '/app/dashboard' } }],
    children: [
      {
        index: true,
        element: <Navigate to="login" />,
      },
      {
        path: 'login',
        element: <LoginContainer />,
      },
      {
        path: 'forgot',
        element: <ForgotPasswordContainer />,
      },
      {
        path: 'recovery/expired',
        element: <RecoveryExpiredContainer />,
      },
      {
        path: 'recovery/:token',
        element: <RecoveryContainer />,
      },
      {
        path: 'confirm',
        element: <></>,
      },
    ],
  },
  {
    path: 'app',
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: 'dashboard',
        element: <DashboardProvider><DashboardContainer /></DashboardProvider>,
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <DashboardProvider><DashboardContainer /></DashboardProvider>,
          },
          {
            path: ':id',
            element: <DashboardProvider><DashboardContainer /></DashboardProvider>,
          },
        ],
      },
      {
        path: 'companies',
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        handle: {
          crumb: () => <CompaniesContainer.Crumb />,
        },
        children: [
          {
            path: '',
            index: true,
            element: <CompaniesContainer />,
            // @ts-ignore
            guards: [
              {
                Guard: PermissionGuard,
                props: { roles: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN] },
              },
            ],
          },
          {
            path: ':id',
            element: <EditCompanyContainer />,
            handle: {
              crumb: () => <EditCompanyContainer.Crumb />,
            },
            guards: [
              {
                Guard: PermissionGuard,
                props: { roles: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN] },
              },
            ],
          },
        ],
      },
      {
        path: 'users',
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: { roles: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN] },
          },
        ],
        handle: {
          crumb: () => <UsersContainer.Crumb />,
        },
        children: [
          {
            path: '',
            index: true,
            element: <UsersContainer />,
          },
          {
            path: ':id',
            element: <UserDetailsContainer />,
            handle: {
              crumb: () => <UserDetailsContainer.Crumb />,
            },
          },
        ],
      },
      {
        path: 'reports',
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <ReportsContainer />,
          },
          {
            path: ':id',
            element: <ReportBuilderContainer />,
          },
          {
            path: ':id/running',
            element: <RunningReportOutlet />,
          },
        ],
      },
      {
        path: 'project-status',
        element: (
          <FilterProvider>
            <ProjectStatusContainer />
          </FilterProvider>
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <FilterProvider>
              <ProjectStatusContainer />
            </FilterProvider>,
          },
          {
            path: ':id',
            element: <FilterProvider>
              <ProjectStatusContainer />
            </FilterProvider>,
          },
        ],
      },
      {
        path: 'delivery-performance-BDE',
        element: (
          <DeliveryPerformanceProvider>
            <DeliveryPerformanceBDEContainer />
          </DeliveryPerformanceProvider>
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <DeliveryPerformanceProvider>
              <DeliveryPerformanceBDEContainer />
            </DeliveryPerformanceProvider>,
          },
          {
            path: ':id',
            element: <DeliveryPerformanceProvider>
              <DeliveryPerformanceBDEContainer />
            </DeliveryPerformanceProvider>,
          },
        ],
      },
      {
        path: 'delivery-performance-LE',
        element: (
          <DeliveryPerformanceProvider>
            <DeliveryPerformanceLEContainer />
          </DeliveryPerformanceProvider>
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <DeliveryPerformanceProvider>
              <DeliveryPerformanceLEContainer />
            </DeliveryPerformanceProvider>,
          },
          {
            path: ':id',
            element: <DeliveryPerformanceProvider>
              <DeliveryPerformanceLEContainer />
            </DeliveryPerformanceProvider>,
          },
        ],
      },
      {
        path: 'logs',
        element: <ChangeLogContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: { roles: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN] },
          },
        ],
      },
      {
        path: 'waypoint',
        element: (
          <WaypointContainer />
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <WaypointContainer />,
          },
          {
            path: ':id',
            element: <WaypointContainer />,
          },
        ],
      },
      {
        path: 'session',
        element: (
          <SessionContainer />
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <SessionContainer />,
          },
          {
            path: ':id',
            element: <SessionContainer />,
          },
        ],
      },
      {
        path: 'top-sound',
        element: (
          <TopSoundContainer />
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <TopSoundContainer />,
          },
          {
            path: ':id',
            element: <TopSoundContainer />,
          },
        ],
      },
      {
        path: 'correlation',
        element: (
          <CorrelationContainer />
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <CorrelationContainer />,
          },
          {
            path: ':id',
            element: <CorrelationContainer />,
          },
        ],
      },
      {
        path: 'consumption-profile',
        element: (
          <ConsumptionProfileContainer />
        ),
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
        children: [
          {
            path: '',
            index: true,
            element: <ConsumptionProfileContainer />,
          },
          {
            path: ':id',
            element: <ConsumptionProfileContainer />,
          },
        ],
      },
      {
        path: 'logs',
        element: <ChangeLogContainer />,
        guards: [
          { Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } },
          {
            Guard: PermissionGuard,
            props: { roles: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN] },
          },
        ],
      },
      {
        path: 'profile',
        element: <ProfileContainer />,
        guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
      },
    ],
  },
  {
    path: 'reports/:id/running',
    guards: [{ Guard: AuthGuard, props: { fallbackRedirect: LOGIN_REDIRECT } }],
    element: <RunningReportContainer />,
  },
];


export const generateRouterObject = (router: AppRoutes): RouteObject[] =>
  // @ts-ignore
  router.map(({ children, element, guards = [], ...rest }: AppRoute) => {
    const outlet = element || <Outlet />;
    const component = guards.reduce(
      (acc, { Guard, props }) => <Guard {...props}>{acc}</Guard>,
      outlet
    );

    return {
      ...rest,
      element: component,
      children: children && generateRouterObject(children),
    };
  });
