import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const ProgressBarWrapper = styled.div`
  margin-bottom: 20px;
`;

export const DMAHeading = styled.h2`
  margin-bottom: 10px;
  font-weight: 500;
`;
