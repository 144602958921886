import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Button = styled.button`
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #e6e6e6;
  }
`;

export const ExpandButton = styled.button`
filter: invert(1);
background: transparent;
border: none;
margin-bottom: 20px;
cursor: pointer;
transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); /* Add custom cubic-bezier */

&:hover {
  transform: scale(1.1); /* Increase size on hover */
}
`;

