import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC, useState } from 'react';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import * as Styled from './style';

interface GraphToggleProps {
  ID: string | number[] | string[];
  location: string;
  numberOfRecordings?: number;
  filename?: string;
}

const GraphToggle: FC<GraphToggleProps> = ({ location, ID, numberOfRecordings = 0, filename = '' }) => {
  const [imageType, setImageType] = useState('stft');
  const { t } = useCustomTranslation('');
  const onClickHandler = (image: string) => setImageType(image);
  const mediaItems = [];
  if (Array.isArray(ID)) {
    ID.map((id: number | string, key: number) => {
      const audioURL = `https://storage.googleapis.com/${location}/${id}/${
        filename ? `audio/${filename}` : 'audio'
      }.wav`;
      const url = `https://storage.googleapis.com/${location}/${id}/${imageType}${filename && `/${filename}`}.png`;
      return mediaItems.push(
        <Styled.ItemContainer key={key}>
          <div>
            <AudioPlayer audioKey={key} audioUrl={audioURL} />
          </div>
          <Styled.Image alt={`graph ${key}`} src={url} key={key} />
        </Styled.ItemContainer>
      );
    });
  } else {
    for (let i = 0; i < numberOfRecordings; i++) {
      const url = `https://storage.googleapis.com/${location}/${ID}/${imageType}/${i}.png`;
      mediaItems.push(
        <Styled.ItemContainer key={i}>
          <div>
            <AudioPlayer
              audioKey={i}
              style={{ backgroundColor: '#f1f3f4' }}
              audioUrl={`https://storage.googleapis.com/${location}/${ID}/audio/${i}.wav`}
            />
          </div>
          <Styled.Image alt={`graph ${i}`} src={url} key={i} />
        </Styled.ItemContainer>
      );
    }
  }
  return (
    <Styled.Container>
      <Styled.btnContainerTitle>{t('COMPONENTS.GRAPH_TOGGLE.SPECTRAL_PROFILE')}</Styled.btnContainerTitle>
      <Styled.btnContainer>
        <Styled.Button onClick={() => onClickHandler('stft')}>
          STFT
        </Styled.Button>
        <Styled.Button onClick={() => onClickHandler('psd')}>
          PSD
        </Styled.Button>
      </Styled.btnContainer>
      <Styled.Wrapper>
        {mediaItems}
      </Styled.Wrapper>
    </Styled.Container>
  );
};
export default GraphToggle;
