import { Button, Switch, TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { CreateReportValues, Layout, Layouts } from '@/types/report.type';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import * as Styled from '../style';
import { ValidationSchema } from './schema';

type CreateReportProps = {
  onSubmit: (data: CreateReportValues) => void;
  layouts: Layouts;
  initialValues: CreateReportValues;
};

const CreateReport: FC<CreateReportProps> = ({ onSubmit, layouts, initialValues }) => {
  const { t, prefixedT } = useCustomTranslation('REPORTS_PAGE.CREATE_REPORT');

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, setFieldValue, dirty, values }) => (
        <Styled.ReportForm data-testid="add-company-form">
          <Field
            name="name"
            as={TextField}
            label={prefixedT('FORM.REPORT_NAME.LABEL')}
            placeholder={prefixedT('FORM.REPORT_NAME.PLACEHOLDER')}
            error={errors.name && t(errors.name, { limit: 100 })}
          />
          <Styled.LayoutsWrapper>
            <Styled.LayoutLabel isError={!!errors.layoutId}>
              {prefixedT('FORM.CHOOSE_LAYOUT.LABEL')}
            </Styled.LayoutLabel>
            <Styled.LayoutsContainer>
              {layouts.map((item: Layout) => (
                <Styled.Layout
                  key={item.id}
                  onClick={() => setFieldValue('layoutId', item.id)}
                  choosen={values.layoutId === item.id}
                >
                  <Styled.LayoutImage imageSrc={item.image} />
                </Styled.Layout>
              ))}
            </Styled.LayoutsContainer>
            {Boolean(errors.layoutId) && (
              <Styled.LayoutError>{t(String(errors.layoutId))}</Styled.LayoutError>
            )}
          </Styled.LayoutsWrapper>
          <Styled.CreateFormFooter>
            <Switch
              checked={values.redirectToEdit}
              onChange={() => setFieldValue('redirectToEdit', !values.redirectToEdit)}
              name="redirectToEdit"
              label={prefixedT('FORM.REDIRECT_TO_EDIT')}
            />
            <Styled.FormButton>
              <Button width="140px" height="34px" type="submit" disabled={!dirty}>
                <Styled.ButtonContent>
                  <Styled.PlusIcon />
                  {prefixedT('FORM.SUBMIT')}
                </Styled.ButtonContent>
              </Button>
            </Styled.FormButton>
          </Styled.CreateFormFooter>
        </Styled.ReportForm>
      )}
    </Formik>
  );
};

export default CreateReport;
