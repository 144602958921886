import { Button, DropDown, TextField } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { ICompany } from '@/types/company.type';
import { IRole, UserRoles } from '@/types/user.type';
import { checkRestictedPermissions, filterRolesAccordingToUserRole } from '@/utils/user';
import { Field, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { FC } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

export interface CreateUserForm {
  branchId: string | number | null;
  companyId: string | number | null;
  roleId: string | number;
  email: string;
  firstName: string;
  lastName: string;
}

interface CreateUserProps {
  onSubmit: (data: CreateUserForm) => void;
  company: ICompany;
  roles: IRole[];
}

const initialValues: CreateUserForm = {
  branchId: '',
  companyId: '',
  roleId: '',
  email: '',
  firstName: '',
  lastName: '',
};

const branchRestictedPermissions = [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN];
const filterRoles = (roles: IRole[], userRole: string) => filterRolesAccordingToUserRole(roles, userRole).filter(
  (role) => role.code !== UserRoles.SUPER_ADMIN
);

const CreateUser: FC<CreateUserProps> = ({ onSubmit, company, roles = [] }) => {
  const { data: profile } = useGetProfileQuery();
  const { t, prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE.ADD_EMPLOYEE');
  initialValues.companyId = company.id;

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      validate={(values: CreateUserForm) => {
        try {
          validateYupSchema(values, ValidationSchema, true, {
            roles,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
      }}
    >
      {({ errors, values, setFieldValue, dirty }) => {
        const branches = company.branches;
        const selectedRole = roles.find((el) => el.id === values.roleId);

        return (
          <Styled.CompanyForm data-testid="add-user-form">
            <Field
              name="roleId"
              as={DropDown}
              label={prefixedT('FORM.ROLE.LABEL')}
              error={errors.roleId && t(errors.roleId)}
              options={filterRoles(roles, profile?.role?.code || '').map((el) => ({
                value: el.id,
                label: el.role,
              }))}
              placeholder={prefixedT('FORM.ROLE.PLACEHOLDER')}
              onChange={(option: DropDownOption) => setFieldValue('roleId', option.value)}
              testId="add-user-roleId"
            />
            <Field
              name="companyId"
              as={DropDown}
              disabled
              label={prefixedT('FORM.COMPANY.LABEL')}
              error={errors.companyId && t(errors.companyId)}
              value={company.id}
              options={[{ value: company.id, label: company.name }]}
              placeholder={prefixedT('FORM.COMPANY.PLACEHOLDER')}
            />
            {selectedRole &&
              !checkRestictedPermissions(branchRestictedPermissions, selectedRole?.code) && (
              <Field
                name="branchId"
                as={DropDown}
                label={prefixedT('FORM.BRANCH.LABEL')}
                error={errors.branchId && t(errors.branchId)}
                disabled={!branches?.length}
                options={(branches || []).map((el) => ({ value: el.id, label: el.name }))}
                placeholder={prefixedT('FORM.BRANCH.PLACEHOLDER')}
                onChange={(option: DropDownOption) => setFieldValue('branchId', option.value)}
                testId="add-user-branchId"
              />
            )}
            <Field
              name="firstName"
              as={TextField}
              label={prefixedT('FORM.FIRST_NAME.LABEL')}
              placeholder={prefixedT('FORM.FIRST_NAME.PLACEHOLDER')}
              error={errors.firstName && t(errors.firstName)}
              testId="add-user-firstName"
            />
            <Field
              name="lastName"
              as={TextField}
              label={prefixedT('FORM.LAST_NAME.LABEL')}
              placeholder={prefixedT('FORM.LAST_NAME.PLACEHOLDER')}
              error={errors.lastName && t(errors.lastName)}
              testId="add-user-lastName"
            />
            <Field
              name="email"
              as={TextField}
              label={prefixedT('FORM.EMAIL.LABEL')}
              placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
              error={errors.email && t(errors.email)}
              testId="add-user-email"
            />
            <Styled.FormButton>
              <Button
                testId="add-user-submit"
                disabled={!dirty}
                width="100%"
                height="100%"
                type="submit"
              >
                <Styled.ButtonContent>
                  <Styled.PlusIcon />
                  {prefixedT('FORM.SUBMIT')}
                </Styled.ButtonContent>
              </Button>
            </Styled.FormButton>
          </Styled.CompanyForm>
        );
      }}
    </Formik>
  );
};

export default CreateUser;
