import {
  AuthButtonContainer,
  AuthFields,
  AuthForm,
  AuthFormSubtitle,
  AuthFormTitle,
  AuthLogo,
  AuthWrapper
} from '@/layouts/AuthLayout/style';
import { Theme } from '@/theme';
import { Link } from 'react-router-dom';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled(AuthWrapper)``;

export const LogoContainer = styled(AuthLogo)``;

export const Title = styled(AuthFormTitle)``;

export const SubTitle = styled(AuthFormSubtitle)``;

export const Form = styled(AuthForm)``;

export const Fields = styled(AuthFields)``;

export const ButtonContainer = styled(AuthButtonContainer)``;

export const ForgotLink = styled(Link)`
  display: block;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
  text-align: center;
  margin-top: 15px;
  @media(max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}){
    margin: 0;
    margin-bottom: 15px;
  }
`;
