import { Button, Tabs } from '@/components';
import { ButtonVariant } from '@/components/Button/Button';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import { Tab } from '@/types/profile.type';
import { EditUserValues, IUser, UserRoles } from '@/types/user.type';
import { UserRole } from '@/utils/user';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { FC, useMemo, useState } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';
import AccountSettings from './tabs/AccountSettings/AccountSettings';

interface ProfileProps {
  onEditActive: () => void;
  onEditUser: (values: EditUserValues) => void;
  onDiscard: () => void;
  isEditable: boolean;
  initialValues: EditUserValues;
  profile?: IUser;
}

const getControls = (
  options: FormikHelpers<EditUserValues> & FormikProps<EditUserValues>,
  onDiscard: () => void,
  prefixedT: (value: string) => string
) => {
  const handleReset = () => {
    options.resetForm();
    onDiscard();
  };
  return (
    <Styled.Controls>
      <Button
        variant={ButtonVariant.outline}
        color={theme.colors.coral}
        width="122px"
        height="34px"
        onClick={handleReset}
        testId="dirty"
      >
        {prefixedT('DISCARD')}
      </Button>
      <Button
        type="submit"
        variant={ButtonVariant.outline}
        disabled={!options.dirty}
        width="122px"
        height="34px"
        testId="submit"
      >
        {prefixedT('SUBMIT')}
      </Button>
    </Styled.Controls>
  );
};

const Profile: FC<ProfileProps> = ({
  profile,
  initialValues,
  isEditable,
  onDiscard,
  onEditUser,
  onEditActive,
}) => {
  const { t, prefixedT } = useCustomTranslation('PROFILE_PAGE');
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: 1,
        title: prefixedT('TABS.ACCOUNT_SETTINGS'),
        component: AccountSettings,
        props: { isEditable },
        disabled: false,
      },
      { id: 2, title: prefixedT('TABS.COMPANY_SETTINGS'), disabled: true },
      { id: 3, title: prefixedT('TABS.DOCUMENTS'), disabled: true },
      { id: 4, title: prefixedT('TABS.CHANGE_PASSWORD'), disabled: true },
    ],
    [isEditable, prefixedT]
  );

  const handleTabClick = (id: number) => {
    setSelectedTab(id);
  };

  return (
    profile ? (
      <Formik
        initialValues={initialValues}
        onSubmit={onEditUser}
        enableReinitialize
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formProps) => (
          <Form style={{padding: '40px'}}>
            <Styled.Header>
              <Styled.Wrapper data-testid="profilePage">
                <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
              </Styled.Wrapper>
              <>
                {isEditable ? (
                  getControls(formProps, onDiscard, prefixedT)
                ) : (
                  <Button
                    type="button"
                    variant={ButtonVariant.default}
                    width="122px"
                    height="34px"
                    testId="edit"
                    onClick={onEditActive}
                  >
                    {prefixedT('EDIT')}
                  </Button>
                )}
              </>
            </Styled.Header>
            <Styled.ProfileWrapper>
              <Styled.Info>
                <Styled.AvatarContainer>
                  <Styled.AvatarIcon />
                  {profile?.role?.code === UserRoles.SUPER_ADMIN ? (
                    <Styled.SuperIcon data-testid="super-icon" />
                  ) : null}
                  <Styled.CameraIcon />
                </Styled.AvatarContainer>
                <Styled.ProfileNameContainer>
                  <Styled.ProfileName>
                    {initialValues.firstName} {initialValues.lastName}
                  </Styled.ProfileName>
                </Styled.ProfileNameContainer>
                {profile && (
                  <Styled.ProfileRole>
                    {profile.role && t(UserRole[profile.role.code])}
                  </Styled.ProfileRole>
                )}
                <Styled.Separator />
              </Styled.Info>
              <Styled.MobileTabsContainer>
                <Styled.MobileTabTitle>
                  {prefixedT('TABS.ACCOUNT_SETTINGS')}
                </Styled.MobileTabTitle>
                <Styled.MobileOptionsWrapper>
                  <AccountSettings isEditable={isEditable} />
                </Styled.MobileOptionsWrapper>
              </Styled.MobileTabsContainer>
              <Styled.Details />
              <Styled.Options>
                <Tabs tabs={tabs} selected={selectedTab} onChange={handleTabClick} />
              </Styled.Options>
            </Styled.ProfileWrapper>
          </Form>
        )}
      </Formik>
    ) : null
  );
};

export default Profile;
