import theme from '@/theme';
import React, { FC, PropsWithChildren } from 'react';
import * as Styled from './style';

interface IProps {
  mainColor?: string;
  primaryColor?: string;
  active: boolean;
}

const FacebookLoader: FC<PropsWithChildren<IProps>> = ({
  children,
  active,
  mainColor = theme.colors.gray,
  primaryColor = theme.colors.lightGray,
}) => !active ? (
  <>{children}</>
) : (
  <Styled.Container data-testid="facebook-loader">
    <Styled.AnimatedBackground mainColor={mainColor} primaryColor={primaryColor} />
  </Styled.Container>
);

export default FacebookLoader;
