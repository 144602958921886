import { Table } from '@/components';
import Button, { ButtonVariant } from '@/components/Button/Button';
import {
  ITableHeader,
  TableActionTypes,
  TableCellAlignment,
  TableSortOrders
} from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import { IBranch } from '@/types/branch.type';
import React, { FC } from 'react';
import * as Styled from '../style';
interface IProps {
  branches: IBranch[];
  totalCount: number;
  onBranchAdd: () => void;
  onBranchDelete: (branch: IBranch) => void;
  onBranchEdit: (branch: IBranch) => void;
}

const headers: ITableHeader[] = [
  { id: 'index', title: 'INDEX', sortable: true, align: TableCellAlignment.left },
  { id: 'name', title: 'NAME', sortable: true, align: TableCellAlignment.left },
  {
    id: 'actions',
    title: 'ACTIONS',
    sortable: false,
    align: TableCellAlignment.right,
  },
];

const BranchesTable: FC<IProps> = ({
  branches,
  totalCount,
  onBranchAdd,
  onBranchEdit,
  onBranchDelete,
}) => {
  const { prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE.BRANCHES_TABLE');
  const title = totalCount
    ? prefixedT('BRANCHES', { count: branches.length })
    : prefixedT('NO_BRANCHES');
  const isOnlyBranch = branches.length === 1;

  const headerControls = (
    <Button
      width="120px"
      height="34px"
      variant={ButtonVariant.outline}
      color={theme.colors.lightBlue}
      onClick={onBranchAdd}
      testId="add-branch-button"
    >
      <Styled.EditButtonContent>
        <Styled.PlusIcon />
        {prefixedT('ADD')}
      </Styled.EditButtonContent>
    </Button>
  );

  const getRowStructure = (row: IBranch, index: number) => (
    <Table.Row key={row.id}>
      <Table.Cell align={TableCellAlignment.left} headerId="index">
        {index + 1}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.left} headerId="name">
        {row.name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.right} headerId="actions">
        <Table.Action onClick={() => onBranchEdit(row)} type={TableActionTypes.edit} />
        {isOnlyBranch ? null : (
          <Table.Action onClick={() => onBranchDelete(row)} type={TableActionTypes.delete} />
        )}
      </Table.Cell>
    </Table.Row>
  );

  const getHeaderStructure = (header: ITableHeader) => <div>{prefixedT(header.title)}</div>;
  return (
    <>
      <Table.Header title={title} controls={headerControls} />
      {Boolean(branches.length) && (
        <Table data={branches} order={TableSortOrders.asc} orderBy="id" headers={headers}>
          <Table.Head getHeaderStructure={getHeaderStructure} />
          <Table.Body getRowStructure={getRowStructure} striped />
        </Table>
      )}
    </>
  );
};

export default BranchesTable;
