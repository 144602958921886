/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IRole, UserRoles } from '@/types/user.type';
import { EmailValidator } from '@/utils/validators';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

type ValueType = number | undefined | string;

const companyAdminRequiredFields = ['companyId'];
const companyWorkersRequiredFields = ['companyId', 'branchId'];

const checkFieldAccordingToRole = (context: Yup.TestContext, value: ValueType) => {
  const { roles } = context.options.context as { roles: IRole[] };
  const companyAdminRole = roles.find((el: IRole) => el.code === UserRoles.COMPANY_ADMIN);
  const companyWorkersRoles = roles
    .filter((el: IRole) =>
      [UserRoles.COMPANY_ANALYST, UserRoles.COMPANY_ENGINEER, UserRoles.COMPANY_MANAGER].includes(
        el.code
      )
    )
    .map((el) => el.id);

  if (
    companyAdminRequiredFields.includes(context.path) &&
    context.parent.roleId === companyAdminRole?.id
  ) {
    return Boolean(value);
  }
  if (
    companyWorkersRequiredFields.includes(context.path) &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    companyWorkersRoles.includes(context.parent.roleId)
  ) {
    return Boolean(value);
  }
  return true;
};

const checkRequiredRule: Yup.TestFunction<ValueType, AnyObject> = (
  value,
  context: Yup.TestContext
) => checkFieldAccordingToRole(context, value);

export const ValidationSchema = Yup.object({
  firstName: Yup.string().required('VALIDATION.REQUIRED'),
  lastName: Yup.string().required('VALIDATION.REQUIRED'),
  email: EmailValidator.required('VALIDATION.REQUIRED'),
  roleId: Yup.number().required('VALIDATION.REQUIRED'),
  companyId: Yup.string().test('companyId', 'VALIDATION.REQUIRED', checkRequiredRule),
  branchId: Yup.string().test('branchId', 'VALIDATION.REQUIRED', checkRequiredRule),
});
