import theme from '@/theme';
import { ButtonVariant } from './Button';

export default class ButtonPresenter {
  static getHoverStyles = (color: string, variant: ButtonVariant, disabled: boolean) => {
    if (disabled) {
      return '';
    }
    if (variant === ButtonVariant.outline) {
      return `
      background-color: ${color};
      color: ${theme.colors.white}
    `;
    }
    return `box-shadow: 0px 8px 16px ${color}80`;
  };

  static getDisabledStyles = (variant: ButtonVariant, color: string) => `
    color: ${variant === ButtonVariant.outline ? `${color}80` : `${theme.colors.white}80`};
    background-color: ${variant === ButtonVariant.outline ? 'transparent' : `${color}80`};
    border: 1px solid ${`${color}80`};
  `;
}
