
import useModalStatus from '@/hooks/useModalStatus';
import { WidgetLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDataSourcesQuery } from '@/redux/reports/reports.api';
import { ReportSummarySettings, ReportWidget } from '@/types/report.type';
import { ContainerVariant } from '@/types/widget.type';
import { Formik } from 'formik';
import React, { FC, useMemo } from 'react';
import useDragMove from '../useDragMove';
import {
  getRunningSummaryObject,
  getSummaryObject,
  InitialSummaryValues,
  parseSettingsForInitialValues,
  prepareSubmit,
  validateSummaryConfig
} from './service';
import * as Styled from './style';
import SummarySettings from './SummarySettings';

type Props = {
  data: ReportWidget<ReportSummarySettings>;
  id: string | number;
  readonly: boolean;
  onRemoveWidget: (widget: ReportWidget<ReportSummarySettings>, layoutId: string | number) => void;
  onUpdateWidget: (widget: ReportWidget<ReportSummarySettings>, layoutId: string | number) => void;
};

const initialValues: InitialSummaryValues = {
  source: null,
  options: [],
};

const Summary: FC<Props> = ({ data, id, readonly, onRemoveWidget, onUpdateWidget }) => {
  const { ref } = useDragMove(data, id, Boolean(readonly));
  const modal = useModalStatus();
  const { data: sources } = useGetDataSourcesQuery();
  const { prefixedT } = useCustomTranslation('WIDGET_LAYOUT');

  const onOpenSettings = () => {
    modal.show();
  };

  const items = useMemo(
    () => [
      {
        title: prefixedT('SETTINGS'),
        handler: onOpenSettings,
        icon: Styled.GearIcon,
      },
    ],
    []
  );

  return (
    <WidgetLayout
      dropRef={ref}
      name={prefixedT('SUMMARY')}
      readonly={readonly}
      items={items}
      onDelete={() => onRemoveWidget(data, id)}
      variant={ContainerVariant.overflow}
    >
      <Styled.Container>
        <Formik
          initialValues={parseSettingsForInitialValues(data.settings) || initialValues}
          onSubmit={(values) => {
            const settings = prepareSubmit(values);
            const _widget = { ...data, settings };
            onUpdateWidget(_widget, id);
            modal.hide();
          }}
          validationSchema={validateSummaryConfig}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
        >
          <SummarySettings
            open={modal.isOpen}
            sources={sources}
            onClose={modal.hide}
            onUpdateWidget={onUpdateWidget}
            widget={data}
            layoutId={id}
          />
        </Formik>

        {data.settings?.summary_title.map((_, index) => {
          const summary = readonly
            ? getRunningSummaryObject(data?.data, index)
            : getSummaryObject(data?.settings, index);
          return (
            <Styled.Info readonly={readonly} key={index}>
              <Styled.Placeholder readonly={readonly}>{summary?.name}</Styled.Placeholder>
              {readonly ? <Styled.SummaryContent>{summary?.value}</Styled.SummaryContent> : null}
            </Styled.Info>
          );
        })}
      </Styled.Container>
    </WidgetLayout>
  );
};

export default React.memo(Summary);
