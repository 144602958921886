import { ReactComponent as MoreIcon } from '@/assets/icons/more.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 5px;
`;

export const SettingIcon = styled(MoreIcon)`
  cursor: pointer;
  & > path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }
`;

export const SettingButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
`;

export const Option = styled.div`
  padding: 5px 2px;
`;

export const OptionContent = styled.span`
  margin-left: 5px;
`;

export const Label = styled.label``;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
