import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.form`
position: relative;
`;

export const Input = styled.input`
  margin: 0 auto;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 14px;
  border: 1px solid #D0CFCE;
  border-radius: 10px;
  outline: none;
  &:focus{
    border: 1px solid #008ABF;
    transition: 0.35s ease;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue}
    &::-webkit-input-placeholder{
      transition: opacity 0.45s ease; 
  	  opacity: 0;
     }
    &::-moz-placeholder {
      transition: opacity 0.45s ease; 
  	  opacity: 0;
     }
    &:-ms-placeholder {
     transition: opacity 0.45s ease; 
  	 opacity: 0;
     }    
   }
 }
 `;

export const Icon = styled.div`
  position: absolute;
  top: 12px;
  right: -30px;
`;
