import { useDashboardContext } from '@/Providers/DashboardProvider';
import { useGetDashboardQuery } from '@/redux/dashboard/dashboard.api';
import { InfoItem } from '@/types/report.type';
import React from 'react';
import { useLocation } from 'react-router-dom';
import StickyMenu from './Components/StickyMenu/StickyMenu';
import Dashboard from './Dashboard';

export interface IDashboardInfo {
  id: number;
  name: string;
  summary: InfoItem;
}

interface Dashboard {
  data: {
    data: IDashboardInfo[];
  };
  isFetching: boolean;
}

const DashboardContainer = () => {
  const { timeRange } = useDashboardContext();

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();
  const partner = query.get('partner');
  const company = query.get('company');

  const dashboardInfo = useGetDashboardQuery(
    {
      dateFrom: timeRange.startDate,
      dateTo: timeRange.endDate,
      partnerID: partner,
      companyID: company,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  ) as Dashboard;

  const isLoading = dashboardInfo.isFetching;

  return (
    <>
      <StickyMenu />
      <Dashboard
        loading={isLoading}
        dashboardInfo={dashboardInfo.data?.data }
      />
    </>
  );
};

export default DashboardContainer;
