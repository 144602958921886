import { TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { EditUserValues } from '@/types/user.type';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import * as Styled from './style';

export interface AccountSettingsProps {
  isEditable: boolean;
}

const AccountSettings: FC<AccountSettingsProps> = ({ isEditable }) => {
  const { t, prefixedT } = useCustomTranslation('PROFILE_PAGE');
  const { errors } = useFormikContext<EditUserValues>();

  return (
    <Styled.Card>
      <Styled.CardWrapper>
        <Field
          disabled={!isEditable}
          as={TextField}
          name="firstName"
          error={errors.firstName && t(errors.firstName)}
          label={prefixedT('FORM.FIRST_NAME.LABEL')}
          placeholder={prefixedT('FORM.FIRST_NAME.PLACEHOLDER')}
          testId="firstName-field"
        />
        <Field
          disabled={!isEditable}
          as={TextField}
          name="lastName"
          error={errors.lastName && t(errors.lastName)}
          label={prefixedT('FORM.LAST_NAME.LABEL')}
          placeholder={prefixedT('FORM.LAST_NAME.PLACEHOLDER')}
          testId="lastName-field"
        />
        <Field
          disabled
          as={TextField}
          name="email"
          error={errors.email && t(errors.email)}
          label={prefixedT('FORM.EMAIL.LABEL')}
          placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
          testId="email-field"
        />
      </Styled.CardWrapper>
    </Styled.Card>
  );
};

export default AccountSettings;
