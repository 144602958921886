import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 16px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const SubTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  text-align: center;
  margin-bottom: 10px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & > * {
    margin-right: 20px;
  }
  &>*: last-child {
    margin-right: 0;
  }
`;
