import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import { Funnel, FunnelChart, Tooltip, Cell, LabelList } from 'recharts';
import * as Styled from './style';

const COLORS = ['#009FE3', '#59C4E9', '#F09425', '#F3F3F3'];

interface FunnelChartComponentProps {
  dimensions: number;
  data: Data[];
}

interface Data {
  value: number;
  name: string;
}

const FunnelChartComponent: FC<FunnelChartComponentProps> = ({ dimensions, data }) => {
  const noData = data.every((e) => e.value === 0);
  const { t } = useCustomTranslation('');

  const renderCustomizedLabel = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/prop-types
    const { x, y, width, height, index, value } = props;
    const isLast = index === data.length - 1;
    const fillColor = isLast ? '#007CBB' : '#fff';

    return (
      <text
        x={Number(x) + width / 2}
        y={Number(y) + height / 2}
        fill={fillColor}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  return (
    <Styled.Container data-testid="funnel-chart-container">
      {noData ? (
        <Styled.errorMsg>{t('COMPONENTS.FUNNEL_CHART.ERROR_MSG')}</Styled.errorMsg>
      ) : (
        <FunnelChart width={dimensions + 100} height={dimensions}>
          <Tooltip />
          <Funnel dataKey="value" data={data} isAnimationActive={false}>
            <LabelList position="inside" content={renderCustomizedLabel} />
            {data.map((_entry, index: number) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Funnel>
        </FunnelChart>
      )}
    </Styled.Container>
  );
};

export default FunnelChartComponent;
