
import { ContentLoader } from '@/components';
import { useLoginMutation } from '@/redux/user/user.api';
import { login } from '@/redux/user/user.slice';
import { ILoginValues } from '@/types/auth.type';
import { getRTKError } from '@/utils/rtk';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Login from './Login';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const [onLogin, { error, isSuccess, isLoading }] = useLoginMutation();
  const loginError = useMemo(() => getRTKError(error), [error]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(login());
    }
  }, [isSuccess]);

  const initialValues = useMemo(
    (): ILoginValues => ({
      email: '',
      password: '',
    }),
    []
  );

  const onSubmit = (values: ILoginValues) => {
    onLogin(values);
  };

  return (
    <>
      <ContentLoader active={isLoading} />
      <Login onSubmit={onSubmit} initialValues={initialValues} loginError={loginError} />
    </>
  );
};
export default LoginContainer;
