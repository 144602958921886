import { useField } from 'formik';
import React, { FC } from 'react';
import * as Styled from './checkboxStyle';

type Props = {
  label: string;
  formName: string;
};

const Checkbox: FC<Props> = ({ formName, label }) => {
  const [field, , helpers] = useField(formName);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(e.target.checked);
  };

  return (
    <>
      <Styled.CheckboxInput
        id={field.name}
        name={field.name}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        checked={field.value}
        onChange={handleChange}
      />
      <label htmlFor={field.name}>{label}</label>
    </>
  );
};

export default Checkbox;
