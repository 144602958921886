import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow_down.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 0;
  padding: 20px;
  z-index: 1;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const ArrowDownIcon = styled(ArrowIcon)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  font-size: 14px;
  border: none;
  height: 45px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;
  width: 170px;
  font-weight: 500;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ArrowDown = styled.span`
  position: absolute;
  top: 15px;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const SearchBarContainer = styled.div``;

