import { IBranch } from './branch.type';
import { ICompany } from './company.type';

export interface IInitialUserState {
  isAuthorized: boolean;
}

export enum UsetStatus {
  active = 'active',
  pending = 'pending',
}

export enum UserRoles {
  SUPER_ADMIN = 'SAD',
  COMPANY_ADMIN = 'CA',
  COMPANY_MANAGER = 'COM',
  COMPANY_ANALYST = 'COA',
  COMPANY_ENGINEER = 'COE',
}

export interface IRole {
  id: string | number;
  role: string;
  code: UserRoles;
}

export interface IUser {
  id: string | number;
  firstName: string;
  lastName: string;
  companyId?: string | number;
  branchId?: string | number;
  company?: ICompany;
  partner?: {
    id: string;
    name: string;
  };
  role?: IRole;
  branch?: IBranch;
  roleId: string | number;
  status: UsetStatus;
  email: string;
}

export interface CreateUserValues {
  firstName: string;
  lastName: string;
  companyId: number | null;
  branchId: number | null;
  roleId: number;
  email: string;
  redirectUrl: string;
}

export interface EditUserValues {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  companyId?: string | number | null;
  branchId?: string | number | null;
  roleId?: string | number;
}
