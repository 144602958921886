import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
  alignitems: center;
  justifycontent: space-between;
  padding: 0 20px;
  height: 30px;
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.celestialBlue};
  border-radius: 5px;
  position: relative;
`;

export const Dot = styled.div<{
  completion: number;
}>`
  position: absolute;
  left: ${({ completion }) => completion}%;
  background: #1681FF;
  transform: translateX(-50%);
  border-radius: 50%;
  top: 8px;
  width: 12px;
  height: 12px;
`;

export const DotContainer = styled.div`
  padding-left: 10px;
  width: calc(100% - 10px);
  position: absolute;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  font-weight: 600;
  margin-bottom: 5px;
`;
