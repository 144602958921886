/* eslint-disable react/no-unknown-property */
import React, { FC } from 'react';
import * as Styled from './style';

const Wave: FC = () => (
  <Styled.InnerHeader>
    <Styled.Waves xmlns="http://www.w3.org/2000/svg"
      viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <Styled.Parallax className="parallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="#01699d" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="#0176b1" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="#017cb9" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#0184c6" />
      </Styled.Parallax>
    </Styled.Waves>
  </Styled.InnerHeader>
);
export default Wave;
