import React, { createContext, FC, useState } from 'react';

export type DayKey = 'day1' | 'day2' | 'day3' | 'day4' | 'day5' | 'day6' | 'day7' | 'total';

export type DateFilter = {
  dateFrom: Date;
  dateTo: Date;
};

export interface DeliveryPerformanceContextProps {
  dayDropdownActiveValue: DayKey;
  setDayDropdownActiveValue: React.Dispatch<React.SetStateAction<DayKey>>;
  projectActiveValue: { value: number; name: string };
  setProjectActiveValue: React.Dispatch<React.SetStateAction<{ value: number; name: string }>>;
  engineerActiveValue: { value: number; name: string };
  setEngineerActiveValue: React.Dispatch<React.SetStateAction<{ value: number; name: string }>>;
  dmaActiveValue: { value: number; name: string };
  setDmaActiveValue: React.Dispatch<React.SetStateAction<{ value: number; name: string }>>;
  dateFilters: {
    dateFrom: Date;
    dateTo: Date;
  };
  setDateFilters: React.Dispatch<React.SetStateAction<DateFilter>>;
}

const lastYear = new Date();
lastYear.setFullYear(lastYear.getFullYear() - 1);

export const DeliveryPerformanceContext = createContext<DeliveryPerformanceContextProps>({
  dayDropdownActiveValue: 'total',
  setDayDropdownActiveValue: () => '',
  projectActiveValue: { value: 0, name: '' },
  setProjectActiveValue: () => {},
  engineerActiveValue: { value: 0, name: '' },
  setEngineerActiveValue: () => {},
  dmaActiveValue: { value: 0, name: '' },
  setDmaActiveValue: () => {},
  dateFilters: {
    dateFrom: lastYear,
    dateTo: new Date(),
  },
  setDateFilters: () => {},
});

interface DeliveryPerformanceProviderProps {
  children: React.ReactNode;
}

export const DeliveryPerformanceProvider: FC<DeliveryPerformanceProviderProps> = ({ children }) => {
  const [dayDropdownActiveValue, setDayDropdownActiveValue] = useState<DayKey>('total');
  const [projectActiveValue, setProjectActiveValue] = useState<{ value: number; name: string }>({
    value: 0,
    name: '',
  });
  const [engineerActiveValue, setEngineerActiveValue] = useState<{ value: number; name: string }>({
    value: 0,
    name: '',
  });
  const [dmaActiveValue, setDmaActiveValue] = useState<{ value: number; name: string }>({
    value: 0,
    name: '',
  });
  const [dateFilters, setDateFilters] = useState<DateFilter>({
    dateFrom: lastYear,
    dateTo: new Date(),
  });

  return (
    <DeliveryPerformanceContext.Provider
      value={{
        dayDropdownActiveValue,
        setDayDropdownActiveValue,
        projectActiveValue,
        setProjectActiveValue,
        engineerActiveValue,
        setEngineerActiveValue,
        dmaActiveValue,
        setDmaActiveValue,
        dateFilters,
        setDateFilters,
      }}
    >
      {children}
    </DeliveryPerformanceContext.Provider>
  );
};
