import useCustomTranslation from '@/localization/useCustomTranslation';
import { RootState } from '@/redux';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { IUser } from '@/types/user.type';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISidebarRoutes, getSidebarRoutes } from '../sidebarRoutes';
import * as Styled from './style';

const SidebarNav: FC = () => {
  const { data: profile } = useGetProfileQuery();
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>({});

  const activeAccount = useSelector((state: RootState) => state.activeAccount.currentAccount);

  const { prefixedT } = useCustomTranslation('SIDEBAR');

  const { parents, children } = getSidebarRoutes(profile as IUser, activeAccount).reduce(
    (acc, route) => {
      if (route.dropdownParent) {
        if (!acc.children[route.dropdownParent]) {
          acc.children[route.dropdownParent] = [];
        }
        acc.children[route.dropdownParent].push(route);
      } else {
        acc.parents[route.id] = route;
      }
      return acc;
    },
    {
      parents: {} as Record<string, ISidebarRoutes>,
      children: {} as Record<string, ISidebarRoutes[]>,
    }
  );

  const toggleDropdown = (dropdownParent: string) => {
    setOpenDropdowns({
      ...openDropdowns,
      [dropdownParent]: !openDropdowns[dropdownParent],
    });
  };

  return (
    <Styled.List>
      {Object.values(parents).map((route) => {
        const { id, path, translation, permissions } = route;
        return permissions.includes('*') || permissions.includes(profile?.role?.code || '*') ? (
          <Styled.ItemLink to={path} data-testid={`parent-${id}`} key={id}>
            <Styled.ItemText>{prefixedT(translation)}</Styled.ItemText>
          </Styled.ItemLink>
        ) : null;
      })}
      {Object.keys(children).map((key) => (
        <Styled.Dropdown key={key}>
          <Styled.DropdownParentContainer onClick={() => toggleDropdown(key)}>
            <Styled.DropdownParent>{prefixedT(key)}</Styled.DropdownParent>
            <Styled.ClickableAreaIndicator open={openDropdowns[key] ? true : false} />
          </Styled.DropdownParentContainer>
          {openDropdowns[key] && (
            <Styled.DropdownContent>
              {children[key].map(({ id, path, translation, permissions }) =>
                permissions.includes('*') || permissions.includes(profile?.role?.code || '*') ? (
                  <Styled.ItemLink to={path} key={id}>
                    <Styled.ItemText>{prefixedT(translation)}</Styled.ItemText>
                  </Styled.ItemLink>
                ) : null
              )}
            </Styled.DropdownContent>
          )}
        </Styled.Dropdown>
      ))}
    </Styled.List>

  );
};

export default SidebarNav;
