import { ReactComponent as LeftArrowIcon } from '@/assets/icons/arrow_down.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const CrumbList = styled.ul`
  display: flex;
  list-style: none;
  font-weight: 500;
  & a {
    opacity: 0.6;
    color: inherit;
    text-decoration: none;
  }
`;
export const CrumbItem = styled.li`
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  display: flex;
  align-items: center;
  &:first-child svg {
    margin-left: 0;
  }
`;

export const ArrowIcon = styled(LeftArrowIcon)`
  margin-top: 1px;
  transform: rotate(90deg);
  &.visible{
    opacity: 1;
  }
  &.semi-visible{
    opacity: 0.6;
  }
`;
