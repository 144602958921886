import { ReactComponent as PlusButtonIcon } from '@/assets/icons/plus.svg';
import { Form } from 'formik';
import styled from 'styled-components';

export const CompanyForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormButton = styled.div`
  height: 34px;
  width: 140px;
  align-self: flex-end;
  display: flex;
  align-items: baseline;
`;

export const PlusIcon = styled(PlusButtonIcon)`
  margin-right: 9px;
`;
export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
