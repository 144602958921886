import { IReactChildren } from '@/types/common.type';
import React, { FC } from 'react';
import * as Styled from './style';

export interface IHeaderProps {
  title?: string;
  subtitle?: string;
  controls?: IReactChildren;
}

const Header: FC<IHeaderProps> = ({ title, subtitle, controls }) => (
  <Styled.TableHeader>
    <Styled.TableHeaderTitleWrapper>
      <Styled.TableHeaderTitle>{title}</Styled.TableHeaderTitle>
      <Styled.TableHeaderSubTitle visible={!!subtitle}>{subtitle}</Styled.TableHeaderSubTitle>
    </Styled.TableHeaderTitleWrapper>
    <Styled.TableHeaderControlsWrapper visible={!!controls}>{controls}</Styled.TableHeaderControlsWrapper>
  </Styled.TableHeader>
);

export default Header;
