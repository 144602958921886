import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  font-weight: bold;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  font-size: 12px;
`;

export const ListObject = styled.span`
    font-weight: normal;
`;

export const ListContainer = styled.div`
  margin-bottom: 20px;
`;

export const PipeSegments = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const PipeSegment = styled.div`
  border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;

export const PipeSegmentText = styled.div`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;


export const ListTitle = styled.h3`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  margin-bottom: 20px;
`;

export const ListTitleContainer = styled.div`
  margin-top: 20px;
`;

