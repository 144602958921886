import { ActiveAccount } from '@/redux/activeAccount/activeAccount.slice';
import { UserRoles, IUser } from '@/types/user.type';

export interface ISidebarRoutes {
  id: string;
  translation: string;
  path: string;
  permissions: (UserRoles | '*')[];
  dropdownParent?: string;
}

export const getSidebarRoutes = (
  profile: IUser,
  activeAccount: ActiveAccount | null
): ISidebarRoutes[] => [
  {
    id: 'dashboardLink',
    translation: 'DASHBOARD',
    path: activeAccount ? `/app/dashboard?${
      activeAccount.type === 'partner'
        ? 'partner=' + activeAccount.id.toString()
        : 'company=' + activeAccount.id.toString()
    }` : '/app/dashboard',
    permissions: ['*'],
  },
  {
    id: 'projectStatusLink',
    translation: 'PROJECT_STATUS',
    path: '/app/project-status',
    permissions: ['*'],
  },
  {
    id: 'deliveryPerformanceBDELink',
    translation: 'DELIVERY_PERFORMANCE_BDE',
    path: '/app/delivery-performance-BDE',
    permissions: ['*'],
    dropdownParent: 'DELIVERY_PERFORMANCE',
  },
  {
    id: 'deliveryPerformanceLELink',
    translation: 'DELIVERY_PERFORMANCE_LE',
    path: '/app/delivery-performance-LE',
    permissions: ['*'],
    dropdownParent: 'DELIVERY_PERFORMANCE',
  },
  {
    id: 'companiesLink',
    translation: 'COMPANIES',
    path: '/app/companies',
    permissions: [UserRoles.SUPER_ADMIN],
  },
  {
    id: 'companyLink',
    translation: 'COMPANY',
    path: `/app/companies/${profile?.companyId}`,
    permissions: [UserRoles.COMPANY_ADMIN],
  },
  {
    id: 'usersLink',
    translation: 'USERS',
    path: '/app/users',
    permissions: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN],
  },
  {
    id: 'reportsLink',
    translation: 'REPORTS',
    path: '/app/reports',
    permissions: ['*'],
  },
  {
    id: 'logsLink',
    translation: 'LOGS',
    path: '/app/logs',
    permissions: [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN],
  },
];
