/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Asset, DMA, Leak, NonLeak, Pipe, Waypoint } from '@/assets/images';
import CheckboxWithIcon from '@/components/CheckboxWithIcon/CheckboxWithIcon';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FilterContext } from '@/Providers/FilterProvider';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import MapViewContext from '../../context/MapViewContext';
import { Button } from '../../style';
import * as Styled from './style';

export type FilterName =
  | 'ASSETS'
  | 'PIPES'
  | 'DMAS'
  | 'LEAK_SESSIONS'
  | 'NON_LEAK_SESSIONS'
  | 'WAYPOINTS';

type ImageMap = {
  [K in FilterName]: string;
};

const filterValues: FilterName[] = [
  'ASSETS',
  'PIPES',
  'DMAS',
  'LEAK_SESSIONS',
  'NON_LEAK_SESSIONS',
  'WAYPOINTS',
];

const FilterForm = () => {
  const { t, prefixedT } = useCustomTranslation('MAP_VIEW.FILTERS.TITLES');
  const { mapContainerWidth, onSubmit } = useContext(MapViewContext);
  const { mapFilters } = useContext(FilterContext);

  const initValues = mapFilters.filters.length === 0 ? { filters: ['DMAS'] } : mapFilters;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values }) => (
          <Form>
            <Styled.CheckboxContainer mapContainerWidth={mapContainerWidth}>
              {filterValues.map((filter: FilterName, index: number) => {
                const imageMap: ImageMap = {
                  ASSETS: Asset,
                  PIPES: Pipe,
                  DMAS: DMA,
                  LEAK_SESSIONS: Leak,
                  NON_LEAK_SESSIONS: NonLeak,
                  WAYPOINTS: Waypoint,
                };
                const imageSrc = imageMap[filter];

                return (
                  <CheckboxWithIcon
                    key={index}
                    mapContainerWidth={mapContainerWidth}
                    formName={'filters'}
                    label={prefixedT(filter)}
                    image={imageSrc}
                  />
                );
              })}
            </Styled.CheckboxContainer>

            <Styled.BtnContainer>
              <Button
                type="submit"
                disabled={values.filters.length < 1}
                // @ts-ignore
                width="200px"
                height="50px"
              >
                {t('MAP_VIEW.FILTERS.SUBMIT')}
              </Button>
            </Styled.BtnContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FilterForm;
