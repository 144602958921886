import { Button, Table } from '@/components';
import {
  ITableHeader,
  ITablePaginate,
  TableActionTypes,
  TableCellAlignment,
  TableSortOrders
} from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IUser } from '@/types/user.type';
import React, { FC } from 'react';
import * as Styled from '../style';

interface IProps {
  users: IUser[];
  onPageChange: (page: ITablePaginate) => void;
  page: number;
  pages: number;
  totalCount: number;
  onAddUserModalShow: () => void;
  onUserEdit: (user: IUser) => void;
  onUserDelete: (user: IUser) => void;
  setSorting: (orderBy: string, order: string) => void;
  order: TableSortOrders;
  orderBy: string;
}

const headers: ITableHeader[] = [
  { id: 'name', title: 'NAME', sortable: true, align: TableCellAlignment.left },
  { id: 'company_name', title: 'COMPANY', sortable: true, align: TableCellAlignment.center },
  { id: 'role_name', title: 'ROLE', sortable: true, align: TableCellAlignment.center },
  { id: 'email', title: 'EMAIL', sortable: true, align: TableCellAlignment.center },
  { id: 'status', title: 'STATUS', sortable: true, align: TableCellAlignment.center },
  { id: 'actions', title: 'ACTIONS', sortable: false, align: TableCellAlignment.right },
];

const UserTable: FC<IProps> = ({
  users,
  onPageChange,
  page,
  pages,
  totalCount,
  onAddUserModalShow,
  onUserEdit,
  onUserDelete,
  setSorting,
  order,
  orderBy,
}) => {
  const { t, prefixedT } = useCustomTranslation('USERS_PAGE.TABLE');

  const getRowStructure = (row: IUser) => (
    <Table.Row key={row.id}>
      <Table.Cell align={TableCellAlignment.left} headerId="name">
        <Styled.Avatar />
        <Styled.CellText>{`${row.firstName} ${row.lastName}`}</Styled.CellText>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="company">
        {row.company?.name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="company">
        {row.role?.role}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="email">
        {row.email}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="status">
        {row.status}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.right} headerId="actions">
        <Table.Action onClick={() => onUserEdit(row)} type={TableActionTypes.edit} />
        <Table.Action onClick={() => onUserDelete(row)} type={TableActionTypes.delete} />
      </Table.Cell>
    </Table.Row>
  );

  const getHeaderStructure = (header: ITableHeader) => <div>{prefixedT(header.title)}</div>;

  const headerControl = (
    <Button width="100%" height="100%" testId="add-user-button" onClick={onAddUserModalShow}>
      <Styled.TableHeaderButton>
        <Styled.PlusIcon />
        {t('USERS_PAGE.ADD_USER.BUTTON')}
      </Styled.TableHeaderButton>
    </Button>
  );

  return (
    <>
      <Table.Header
        title={
          !totalCount
            ? t('USERS_PAGE.TITLE.NO_USERS')
            : t('USERS_PAGE.TITLE.TOTAL_COUNT', { count: totalCount })
        }
        subtitle={t('USERS_PAGE.SUBTITLE')}
        controls={headerControl}
      />
      {Boolean(totalCount) && (
        <>
          <Table
            data={users}
            order={order}
            orderBy={orderBy as keyof IUser}
            headers={headers}
            onSort={setSorting}
          >
            <Table.Head getHeaderStructure={getHeaderStructure} />
            <Table.Body getRowStructure={getRowStructure} striped />
          </Table>
          <Table.Pagination pages={pages} onChange={onPageChange} initialPage={page} />
        </>
      )}
    </>
  );
};

export default UserTable;
