/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { FC } from 'react';
import { useSpring, animated } from 'react-spring';

interface Props {
  progress: number;
  dimensions: number;
  label?: string;
  percent?: boolean;
}

const PercentageProgressbar: FC<Props> = ({ progress, dimensions, label, percent }) => {
  const defaultPercentage = progress;
  const circumferenceInner = 2 * Math.PI * 50;
  const strokeDashoffsetInner = ((100 - defaultPercentage) / 100) * circumferenceInner;

  const circumferenceOuter = 2 * Math.PI * 70;
  const strokeDashoffsetOuter = ((100 - defaultPercentage) / 100) * circumferenceOuter;

  const { offset: offsetInner } = useSpring({
    from: { offset: circumferenceInner },
    to: { offset: strokeDashoffsetInner },
    config: { duration: 200 },
  });

  const { offset: offsetOuter } = useSpring({
    from: { offset: circumferenceOuter },
    to: { offset: strokeDashoffsetOuter },
    config: { duration: 200 },
  });

  const { text } = useSpring({
    from: { text: 0 },
    to: { text: defaultPercentage },
    config: { duration: 200 },
  });

  const yPosition = label ? '90' : '107';

  return (
    <svg
      width={dimensions}
      height={dimensions}
      role='percentage-progress-bar'
      viewBox="0 0 200 200"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <circle stroke="lightGrey" fill="none" strokeWidth="10" r="50" cx="100" cy="100" />
      <animated.circle
        stroke="#007CBB"
        fill="none"
        strokeWidth="10"
        r="50"
        cx="100"
        cy="100"
        strokeDasharray={circumferenceInner}
        strokeDashoffset={offsetInner}
      />
      <circle stroke="lightGrey" fill="none" strokeWidth="2" r="70" cx="100" cy="100" />
      <animated.circle
        stroke="#007CBB"
        fill="none"
        strokeWidth="2"
        r="70"
        cx="100"
        cy="100"
        strokeDasharray={circumferenceOuter}
        strokeDashoffset={offsetOuter}
      />
      <animated.text
        x="100"
        y={yPosition}
        textAnchor="middle"
        fontSize="20"
        fill="#007cbb"
        fontWeight="600"
      >
        {text.to(() => `${progress}${percent ? '%' : ''}`)}
      </animated.text>
      {label && (
        <animated.text
          x="100"
          y="115"
          textAnchor="middle"
          fontSize="12"
          fill="#007cbb"
          fontWeight="600"
        >
          {label}
        </animated.text>
      )}
    </svg>
  );
};

export default PercentageProgressbar;
