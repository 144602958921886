import config from '@/config';
import { userApi } from '@/redux/user/user.api';
import { logout } from '@/redux/user/user.slice';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const [, , removeCookie] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    removeCookie('fido-payload', { path: config.COOKIE_PATH, domain: config.COOKIE_DOMAIN });
    dispatch(logout());
    navigate('/auth');
    dispatch(userApi.util.resetApiState());
  };
  return logoutHandler;
};

export default useLogout;
