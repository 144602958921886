/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import mapbox, { Map, MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl';
import React from 'react';
import { renderToString } from 'react-dom/server';
import GeneralToolTip from '../../tooltips/GeneralTooltip';
import { MapElement } from '../../utils/types';

const drawLines = (map: Map, items: MapElement[]): void => {
  const combinedFeatures = items.map((item) => ({
    type: 'Feature',
    properties: {
      tooltip: item.tooltip,
      id: item.id,
    },
    geometry: {
      type: 'LineString',
      coordinates: item.geometry,
    },
  })) as Feature<Geometry, GeoJsonProperties>[];

  map.addSource('lines-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: combinedFeatures,
    },
  });

  const linesLayerID = 'lines-layer';
  if (!map.getLayer(linesLayerID)) {
    map.addLayer({
      id: 'lines-layer',
      type: 'line',
      source: 'lines-source',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': {
          type: 'identity',
          property: 'color',
        },
        'line-width': 3,
      },
      minzoom: 16,
    });
  }

  const tooltipMap: { [id: string]: MapElement } = {};

  for (const item of items) {
    tooltipMap[item.id] = item;
  }

  const clickHandler = (e: MapMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, { layers: ['lines-layer'] });
    const clickedFeature: MapboxGeoJSONFeature = features[0];

    if (clickedFeature) {
      const clickedItemId: string = clickedFeature?.properties?.id;
      const clickedItem = tooltipMap[clickedItemId];

      const clusters = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      const unclusteredPoint = map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point'],
      });

      const areAllArraysEmpty = [unclusteredPoint, clusters].every((array) => array.length < 1);

      if (clickedItem && areAllArraysEmpty) {
        new mapbox.Popup()
          .setLngLat(e.lngLat)
          .setHTML(renderToString(<GeneralToolTip tooltip={clickedItem.tooltip} />))
          .addTo(map);
      }
    }
  };

  map.on('click', 'lines-layer', clickHandler);

  map.on('mouseenter', 'lines-layer', () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', 'lines-layer', () => {
    map.getCanvas().style.cursor = '';
  });
};

export default drawLines;
