import { useTransition } from '@react-spring/web';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { FC } from 'react';
import FilterForm from '../FilterForm/FilterForm';
import * as Styled from './style';

type Props = {
  showFilters: boolean;
};

const FilterList: FC<Props> = ({ showFilters }) => {
  const transitions = useTransition([showFilters], {
    from: { transform: 'translateX(-100%)', opacity: 0 },
    enter: { transform: 'translateX(0%)', opacity: 1 },
    leave: { transform: 'translateX(-100%)', opacity: 0 },
  });

  return (
    <>
      {transitions((styles, item) =>
        item ? <Styled.MapOptions style={styles}>
          <FilterForm />
        </Styled.MapOptions> : null
      )}
    </>
  );
};

export default FilterList;
