/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { MapElement } from '@/components/ReportLayout/widgets/MapView/utils/types';
import { DateFilters, FilterAttributes, MapFilters } from '@/Providers/FilterProvider';
import { ProjectStatusReportType } from '@/types/report.type';
import moment from 'moment';

export const filterEnumMapping: { [key: string]: number } = {
  WAYPOINTS: 1,
  DMAS: 2,
  PIPES: 3,
  ASSETS: 4,
  LEAK_SESSIONS: 5,
  NON_LEAK_SESSIONS: 6,
};

export const objectHasTruthyValue = (object: object): boolean => {
  if (object === null || object === undefined) {
    return false;
  }

  return Object.values(object).some((value: any) => {
    if (typeof value === 'object' && value !== null) {
      return Object.values(value).some((nestedValue) => !!nestedValue);
    }
    return !!value;
  });
};

export const getSelectedValues = (obj: {[key: string]: any}, propertyName: string) => Object.entries(obj[propertyName])
  .filter(([, value]) => value === true)
  .map(([key]) => key);

export interface RequestBody {
  options: number[];
  filters: {
    status: string[];
    predicted_size: string[];
    dates: string[];
  };
}

export const requestBody = (mapFilters: MapFilters, dateFilters: DateFilters, filterAttributes: FilterAttributes): RequestBody => {
  const mappedFilters = mapFilters?.filters?.map((filter: string) => filterEnumMapping[filter]);

  const options = mappedFilters?.length > 0 ? mappedFilters : [filterEnumMapping.DMAS];

  const dateFrom = moment(dateFilters.dateFrom).format('YYYY-MM-DD');
  const dateTo = moment(dateFilters.dateTo).format('YYYY-MM-DD');

  const status = objectHasTruthyValue(filterAttributes)
    ? getSelectedValues(filterAttributes, 'status')
    : [];

  const predicted_size = objectHasTruthyValue(filterAttributes)
    ? getSelectedValues(filterAttributes, 'predicted_size')
    : [];

  return {
    options,
    filters: {
      status,
      predicted_size,
      dates: [dateFrom, dateTo],
    },
  };
};

export const concatenateMapData = (data: ProjectStatusReportType): MapElement[] => [
  ...(data.map.dmas?.data || []),
  ...(data.map.pipes?.data || []),
  ...(data.map.assets?.data || []),
  ...(data.map.sweepinfofordmaperformance_leak?.data || []),
  ...(data.map.sweepinfofordmaperformance_non_leak?.data || []),
  ...(data.map.waypointsinfo?.data || []),
];
