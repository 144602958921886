export interface Theme {
  colors: {
    black: string;
    white: string;
    body: string;
    deepDark: string;
    mediumDark: string;
    celestialBlue: string;
    gray: string;
    lightGray: string;
    deepBlue: string;
    blue: string;
    seashell: string;
    mediumPersianBlue: string;
    malibu: string;
    frenchBlue: string;
    mediumBlue: string;
    lightBlue: string;
    lochmara: string;
    sky: string;
    lighterBlue: string;
    softBlue: string;
    coral: string;
    disabled: string;
    green: string;
    lightGreen: string;
    purple: string;
    mediumGreen: string;
    artyClickRed: string;
  };
  breakpoints: {
    xs: string;
    s: string;
    m: string;
    l: string;
    xl: string;
  };
}

const theme: Theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    body: '#F9F9F9',
    deepDark: '#192A3E',
    mediumDark: '#2E3A59',
    gray: '#9FA2B4',
    lightGray: '#E4E6EE',
    deepBlue: '#00699D',
    frenchBlue: '#007CBB',
    celestialBlue: '#469DDD',
    mediumPersianBlue: '#00699D',
    seashell: '#F1F1F1',
    blue: '#007DBB',
    mediumBlue: '#009FE3',
    lightBlue: '#4298D7',
    malibu: '#5DC6FB',
    sky: '#82D0F5',
    lighterBlue: '#21CCFF',
    softBlue: '#BCD0F8',
    lochmara: '#007CBB',
    coral: '#E95F70',
    disabled: '#F3F3F3',
    green: '#1A947E',
    lightGreen: '#86DF6C',
    purple: '#846BCE',
    mediumGreen: '#34B639',
    artyClickRed: '#FF0101',
  },
  breakpoints: {
    xs: '480px',
    s: '600px',
    m: '768px',
    l: '960px',
    xl: '1200px',
  },
};
export default theme;
