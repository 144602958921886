import GraphToggle from '@/components/GraphToggle/GraphToggle';
import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDropdownQuery, useGetTopSoundInformationQuery } from '@/redux/reports/reports.api';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WaypointHeadings from '../Waypoint/Components/WaypointHeadings/WaypointHeadings';
import * as Styled from './style';

const TopSoundContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation('TOP_SOUND_PAGE.ENGINEERING_DETAILS');
  const location = useLocation();
  const [waypointID, setWaypointID] = useState<string>('');
  const [topSoundID, setTopSoundID] = useState<string>('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedTopSoundID = queryParams.get('topSound') as string;
    const waypoint = queryParams.get('waypoint') as string;
    setTopSoundID(updatedTopSoundID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: topSoundData } = useGetTopSoundInformationQuery(topSoundID);
  const { data: dropDownData } = useGetDropdownQuery(waypointID);

  return (
    <div style={{ padding: '40px' }}>
      {dropDownData && (
        <WaypointHeadings
          correlationIds={dropDownData.correlation_ids}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessionIds={dropDownData.session_ids}
          active='Top Sound'
        />
      )}

      {topSoundData && (
        <>
          <Styled.ListContainer>
            <Styled.ListTitle>{prefixedT('TITLE')}</Styled.ListTitle>
            <Styled.List>
              {topSoundData?.company && (
                <li>
                  {prefixedT('COMPANY')}: <Styled.ListObject>{topSoundData?.company}</Styled.ListObject>
                </li>
              )}
              {topSoundData?.engineer && (
                <li>
                  {prefixedT('ENGINEER')}: <Styled.ListObject>{topSoundData?.engineer}</Styled.ListObject>
                </li>
              )}
              {topSoundData?.location && (
                <li>
                  {prefixedT('LOCATION')}: <Styled.ListObject>{topSoundData?.location}</Styled.ListObject>
                </li>
              )}
              {topSoundData?.postcode && (
                <li>
                  {prefixedT('POSTCODE')}: <Styled.ListObject>{topSoundData?.postcode}</Styled.ListObject>
                </li>
              )}
              {topSoundData?.what3words && (
                <li>
                  {prefixedT('WHAT3WORDS')}: <Styled.ListObject>{topSoundData?.what3words}</Styled.ListObject>
                </li>
              )}
            </Styled.List>
            <Styled.ListTitleContainer>
              <Styled.ListTitle>{t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.TITLE')}</Styled.ListTitle>
              <Styled.List>
                {topSoundData?.HF_cutoff && (
                  <li>
                    {t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.HIGH_FREQUENCY')}:{' '}
                    <Styled.ListObject>{topSoundData?.HF_cutoff}</Styled.ListObject>
                  </li>
                )}
                {topSoundData?.LF_cutoff && (
                  <li>
                    {t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.LOW_FREQUENCY')}:{' '}
                    <Styled.ListObject>{topSoundData?.LF_cutoff}</Styled.ListObject>
                  </li>
                )}
                {topSoundData?.number_of_paths && (
                  <li>
                    {t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.NUMBER_PATHS')}:{' '}
                    <Styled.ListObject>{topSoundData?.number_of_paths}</Styled.ListObject>
                  </li>
                )}
                {topSoundData?.spacing && (
                  <li>
                    {t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.SPACING')}: <Styled.ListObject>{topSoundData?.spacing}</Styled.ListObject>
                  </li>
                )}
                {topSoundData?.starting_distance && (
                  <li>
                    {t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.STARTING_DISTANCE')}:{' '}
                    <Styled.ListObject>{topSoundData?.starting_distance}</Styled.ListObject>
                  </li>
                )}
                {topSoundData?.time_of_top_sounding && (
                  <li>
                    {t('TOP_SOUND_PAGE.FREQUENCY_CUTOFF.TIME_TOP_SOUNDING')}:{' '}
                    <Styled.ListObject>{topSoundData?.time_of_top_sounding}</Styled.ListObject>
                  </li>
                )}
              </Styled.List>
            </Styled.ListTitleContainer>
          </Styled.ListContainer>

          <GraphToggle location="ble-recordings" ID={topSoundData.list_of_recordings} />

          <Styled.MapAndGraphContainer>
            <Styled.GraphContainer>
              <img
                width="100%"
                src={`https://storage.googleapis.com/top-sounding/${topSoundID}/tops.png`}
              />
            </Styled.GraphContainer>
            <Styled.MapContainer>
              <GenerateMap data={topSoundData.map_info.data} readonly={true} />
            </Styled.MapContainer>
          </Styled.MapAndGraphContainer>
        </>
      )}
    </div>
  );
};

export default TopSoundContainer;
