import { Button, DropDown, TextField } from '@/components';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ButtonVariant } from '@/components/Button/Button';
import { DropDownOption } from '@/components/DropDown/DropDown';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import { IBranch } from '@/types/branch.type';
import { ICompany } from '@/types/company.type';
import { EditUserValues, IRole, IUser, UserRoles } from '@/types/user.type';
import { checkRestictedPermissions, filterRolesAccordingToUserRole } from '@/utils/user';
import {
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  validateYupSchema,
  yupToFormErrors
} from 'formik';
import React, { FC } from 'react';
import { ValidationSchema } from '../Users/CreateUser/schema';
import * as Styled from './style';

interface IProps {
  initialValues: EditUserValues;
  profile: IUser;
  companies: ICompany[];
  roles: IRole[];
  onEditUser: (values: EditUserValues) => void;
  user: IUser;
}

const companyPermissions = [UserRoles.SUPER_ADMIN];
const branchPermissions = [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN];

const getControls = (
  options: FormikHelpers<EditUserValues> & FormikProps<EditUserValues>,
  prefixedT: (value: string) => string
) => (
  <Styled.Controls>
    {options.dirty && (
      <Button
        variant={ButtonVariant.outline}
        color={theme.colors.coral}
        width="122px"
        height="34px"
        onClick={() => options.resetForm()}
        testId="dirty"
      >
        {prefixedT('DISCARD')}
      </Button>
    )}
    <Button
      type="submit"
      variant={ButtonVariant.outline}
      disabled={!options.dirty}
      width="122px"
      height="34px"
      testId="submit"
    >
      {prefixedT('SUBMIT')}
    </Button>
  </Styled.Controls>
);

const UserDetails: FC<IProps> = ({ initialValues, companies, profile, roles, onEditUser }) => {
  const { t, prefixedT } = useCustomTranslation('USER_DETAILS_PAGE');
  return (
    <>
      <Breadcrumbs />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onEditUser({
            ...values,
            companyId: values.companyId ? Number(values.companyId) : null,
            branchId: values.branchId ? Number(values.branchId) : null,
          });
        }}
        enableReinitialize
        validate={(values: EditUserValues) => {
          try {
            validateYupSchema(values, ValidationSchema, true, {
              roles,
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formProps) => {
          const { errors, setFieldValue, values } = formProps;
          const role = roles?.find((el: IRole) => el.id === values.roleId);
          const selectedCompany = companies.find(
            ({ id }) => Number(id) === Number(values.companyId)
          );
          const branches = selectedCompany?.branches || [];

          return (
            <Form>
              <Styled.Header>
                <Styled.Title>
                  {initialValues.firstName} {initialValues.lastName}
                </Styled.Title>
                <Styled.Buttons>{getControls(formProps, prefixedT)}</Styled.Buttons>
              </Styled.Header>
              <Styled.FormContainer>
                <Styled.FormWrapper>
                  <Field
                    as={TextField}
                    name="firstName"
                    error={errors.firstName && t(errors.firstName)}
                    label={prefixedT('FORM.FIRST_NAME.LABEL')}
                    placeholder={prefixedT('FORM.FIRST_NAME.PLACEHOLDER')}
                    testId="firstName-field"
                    styles={{ label: Styled.Label }}
                  />
                  <Field
                    as={TextField}
                    name="lastName"
                    error={errors.lastName && t(errors.lastName)}
                    label={prefixedT('FORM.LAST_NAME.LABEL')}
                    placeholder={prefixedT('FORM.LAST_NAME.PLACEHOLDER')}
                    testId="lastName-field"
                    styles={{ label: Styled.Label }}
                  />
                  <Field
                    as={TextField}
                    name="email"
                    disabled
                    label={prefixedT('FORM.EMAIL.LABEL')}
                    placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
                    error={errors.email && t(errors.email)}
                    testId="email-field"
                    styles={{ label: Styled.Label }}
                  />
                  <Field
                    name="roleId"
                    as={DropDown}
                    options={filterRolesAccordingToUserRole(roles, profile?.role?.code || '').map(
                      (el: IRole) => ({ value: el.id, label: el.role })
                    )}
                    onChange={(option: DropDownOption) => setFieldValue('roleId', option.value)}
                    disabled
                    label={prefixedT('FORM.ROLE.LABEL')}
                    placeholder={prefixedT('FORM.ROLE.PLACEHOLDER')}
                    error={errors.roleId && t(errors.roleId)}
                    testId="role-field"
                    styles={{ label: Styled.Label }}
                  />
                  {!checkRestictedPermissions(companyPermissions, role?.code) && (
                    <Field
                      name="companyId"
                      as={DropDown}
                      options={companies.map((el: ICompany) => ({
                        value: el.id,
                        label: el.name,
                      }))}
                      onChange={(option: DropDownOption) => {
                        setFieldValue('companyId', option.value);
                        setFieldValue('branchId', '');
                      }}
                      disabled={!checkRestictedPermissions(companyPermissions, profile?.role?.code)}
                      label={prefixedT('FORM.COMPANY.LABEL')}
                      placeholder={prefixedT('FORM.COMPANY.PLACEHOLDER')}
                      error={errors.companyId && t(errors.companyId)}
                      testId="company-field"
                      styles={{ label: Styled.Label }}
                    />
                  )}
                  {!checkRestictedPermissions(branchPermissions, role?.code) && (
                    <Field
                      name="branchId"
                      as={DropDown}
                      options={branches.map((el: IBranch) => ({ value: el.id, label: el.name }))}
                      onChange={(option: DropDownOption) => setFieldValue('branchId', option.value)}
                      disabled={
                        !checkRestictedPermissions(branchPermissions, profile?.role?.code) ||
                        !selectedCompany
                      }
                      label={prefixedT('FORM.BRANCH.LABEL')}
                      placeholder={prefixedT('FORM.BRANCH.PLACEHOLDER')}
                      error={errors.branchId && t(errors.branchId)}
                      testId="branch-field"
                      styles={{ label: Styled.Label }}
                    />
                  )}
                </Styled.FormWrapper>
              </Styled.FormContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withPageLoader(UserDetails);
