import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDropdownQuery, useGetSessionInformationQuery } from '@/redux/reports/reports.api';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GraphToggle from '../../components/GraphToggle/GraphToggle';
import WaypointHeadings from '../Waypoint/Components/WaypointHeadings/WaypointHeadings';
import * as Styled from './style';

const SessionContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation('SESSION_PAGE.ENGINEERING_DETAILS');
  const location = useLocation();
  const [waypointID, setWaypointID] = useState<string>('');
  const [sessionID, setSessionID] = useState<string>('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedSessionID = queryParams.get('session') as string;
    const waypoint = queryParams.get('waypoint') as string;
    setSessionID(updatedSessionID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: dropDownData } = useGetDropdownQuery(waypointID);
  const { data: sessionData } = useGetSessionInformationQuery(sessionID);

  return (
    <div style={{ padding: '40px' }}>
      {dropDownData && (
        <WaypointHeadings
          correlationIds={dropDownData.correlation_ids}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessionIds={dropDownData.session_ids}
          active='Session'
        />
      )}

      {sessionData && (
        <>
          <Styled.ListContainer>
            <Styled.ListTitle>{prefixedT('TITLE')}</Styled.ListTitle>
            <Styled.List>
              {sessionData?.collected_engineer && (
                <li>
                  {prefixedT('COLLECTED_ENGINEER')}:{' '}
                  <Styled.ListObject>{sessionData.collected_engineer}</Styled.ListObject>
                </li>
              )}
              {sessionData?.company && (
                <li>
                  {prefixedT('COMPANY')}: <Styled.ListObject>{sessionData.company}</Styled.ListObject>
                </li>
              )}
              {sessionData?.deployed_engineer && (
                <li>
                  {prefixedT('DEPLOYED_ENGINEER')}:{' '}
                  <Styled.ListObject>{sessionData.deployed_engineer}</Styled.ListObject>
                </li>
              )}
              {sessionData?.postcode && (
                <li>
                  {prefixedT('POSTCODE')}: <Styled.ListObject>{sessionData.postcode}</Styled.ListObject>
                </li>
              )}
              {sessionData?.what3words && (
                <li>
                  {prefixedT('WHAT3WORDS')}: <Styled.ListObject>{sessionData.what3words}</Styled.ListObject>
                </li>
              )}
            </Styled.List>
            <Styled.ListTitleContainer>
              <Styled.ListTitle>{t('SESSION_PAGE.SESSION_INFORMATION.TITLE')}</Styled.ListTitle>
              <Styled.List>
                {sessionData?.fido_result && (
                  <li>
                    {t('SESSION_PAGE.SESSION_INFORMATION.FIDO_RESULT')}: <Styled.ListObject>{sessionData.fido_result}</Styled.ListObject>
                  </li>
                )}
                {sessionData?.leak_size && (
                  <li>
                    {t('SESSION_PAGE.SESSION_INFORMATION.LEAK_SIZE')}: <Styled.ListObject>{sessionData.leak_size}</Styled.ListObject>
                  </li>
                )}
              </Styled.List>
            </Styled.ListTitleContainer>
          </Styled.ListContainer>

          <GraphToggle location='ble-logger-files' ID={sessionID} numberOfRecordings={sessionData?.number_of_recording} />

          <Styled.MapAndGraphContainer>
            <Styled.GraphWrapper>
              {sessionData && (
                <GenerateMap
                  data={sessionData.map_info.data}
                  readonly={true}
                />
              )}
            </Styled.GraphWrapper>
            <Styled.MapWrapper>
              <Styled.Graph
                src={`https://storage.googleapis.com/ble-logger-files/${sessionID}/pollen/all_untitled.png`}
              />
            </Styled.MapWrapper>
          </Styled.MapAndGraphContainer>
        </>
      )}
    </div>
  );
};

export default SessionContainer;
