import { RequestBody } from '@/containers/ProjectStatus/utils';
import {
  ConsumptionProfileInformationType,
  CorrelationInformationType,
  DMAPopUpType,
  DeliveryPerformanceBugDeploymentEngineerType,
  DeliveryPerformanceDropdownType,
  DeliveryPerformanceLeakEngineerType,
  DropdownType,
  Layouts,
  ProjectStatusReportType,
  Report,
  ReportDataSource,
  SessionInformationType,
  TopSoundInformationtype,
  UpdateReportValues,
  WaypointInformationType
} from '@/types/report.type';
import { IPaginationProps, IPaginationResponse, ISort } from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

interface ProjectStatusParams {
  userID?: string | null | undefined;
  branch_id?: string | null | undefined;
}

export interface getDeliveryPerformanceQuery {
  engineer_id?: number;
  branch_id?: number;
  dma_id?: number;
  start_date?: string;
  end_date?: string;
}

interface getPostProjectReportQuery {
  file_format: string;
  project_id: number;
}

interface getDMAPopupQuery {
  id: number;
}

export const reportApi = createApi({
  reducerPath: 'reportQuery',
  baseQuery: apiBaseQuery,
  // eslint-disable-next-line max-len
  tagTypes: [
    'Reports',
    'Report',
    'Widgets',
    'Layouts',
    'RunningReport',
    'DataSources',
    'ProjectStatus',
    'PortalPartners',
    'PortalCompanies',
    'WaypointInformation',
    'SessionInformation',
    'TopSoundingInformation',
    'Dropdown',
    'CorrelationInformation',
    'ConsumptionProfileInformation',
    'DeliveryPerformanceLeakEngineer',
    'DropdownDeliveryPerformance',
    'DeliveryPerformanceBugDeploymentEngineer',
    'PostProjectReport',
    'getDMAPopup',
  ],
  endpoints: (builder) => ({
    getReports: builder.query<
    IPaginationResponse<Report> | Report[],
    (IPaginationProps & ISort) | void
    >({
        query: (params) => `/reports?${buildQueryParams(params || {})}`,
        providesTags: (result) => {
          const data = (result as IPaginationResponse<Report>)?.data || result;
          return data
            ? [
              ...data.map(({ id }) => ({ type: 'Reports' as const, id })),
              { type: 'Reports', id: 'PARTIAL-LIST' },
            ]
            : [{ type: 'Reports', id: 'PARTIAL-LIST' }];
        },
      }),
    getReport: builder.query({
      query: (id: string | number) => `/reports/${id}`,
      providesTags: ['Report'],
    }),
    getRunningReport: builder.query({
      query: ({ id, query }: { id: string | number; query: object }) =>
        query
          ? `/reports/${id}/running?${buildQueryParams(query || {})}`
          : `/reports/${id}/running`,
      providesTags: ['RunningReport'],
    }),
    updateReport: builder.mutation({
      query: ({ id, ...body }: UpdateReportValues & { id: number }) => ({
        url: `/reports/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Reports'],
    }),
    createReport: builder.mutation({
      query: (body: any) => ({
        url: '/reports',
        method: 'POST',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body,
      }),
      invalidatesTags: ['Reports'],
    }),
    getReportWidgets: builder.query({
      query: () => '/widgets',
      providesTags: ['Widgets'],
    }),
    getReportLayouts: builder.query<Layouts, void>({
      query: () => '/layouts',
      providesTags: ['Layouts'],
    }),
    getDataSources: builder.query<ReportDataSource[], void>({
      query: () => '/reports/datasources',
      providesTags: ['DataSources'],
    }),
    ProjectStatus: builder.mutation<
    ProjectStatusReportType,
    { body: RequestBody; query: ProjectStatusParams }
    >({
      query: ({ body, query }) => ({
        url: `/reports/project_status?${buildQueryParams(query || {})}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectStatus'],
    }),
    getPortalPartners: builder.query({
      query: () => '/portal_partners/ids_names',
      providesTags: ['PortalPartners'],
    }),
    getPortalCompanies: builder.query<any, void>({
      query: () => '/portal_companies/list',
      providesTags: ['PortalCompanies'],
    }),
    getWaypointInformation: builder.query<WaypointInformationType, string | number>({
      query: (id: string | number) => `/reports/waypoint/${id}`,
      providesTags: ['WaypointInformation'],
    }),
    getSessionInformation: builder.query<SessionInformationType, string | number>({
      query: (id: string | number) => `/reports/${id}/information`,
      providesTags: ['SessionInformation'],
    }),
    getTopSoundInformation: builder.query<TopSoundInformationtype, string | number>({
      query: (id: string | number) => `/reports/topsounding/${id}`,
      providesTags: ['TopSoundingInformation'],
    }),
    getDropdown: builder.query<DropdownType, string | number>({
      query: (id: string | number) => `/reports/dropdown/${id}`,
      providesTags: ['Dropdown'],
    }),
    getCorrelationInformation: builder.query<CorrelationInformationType, string | number>({
      query: (id: string | number) => `/reports/correlation/${id}`,
      providesTags: ['CorrelationInformation'],
    }),
    getConsumptionProfileInformation: builder.query<
    ConsumptionProfileInformationType,
    string | number
    >({
      query: (id: string | number) => `/reports/consumption_profile/${id}`,
      providesTags: ['ConsumptionProfileInformation'],
    }),
    getDeliveryPerformanceLeakEngineer: builder.mutation<
    DeliveryPerformanceLeakEngineerType,
    { body: (string | number)[]; query: getDeliveryPerformanceQuery }
    >({
        query: ({ body, query }) => ({
          url: `/reports/delivery_performance/leak_engineer?${buildQueryParams(query || {})}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['DeliveryPerformanceLeakEngineer'],
      }),
    getDeliveryPerformanceDropdown: builder.query<
    DeliveryPerformanceDropdownType, void
    >({
      query: () => '/reports/dropdown/delivery_performance',
      providesTags: ['DropdownDeliveryPerformance'],
    }),
    getDeliveryPerformanceBugDeploymentEngineer: builder.mutation<
    DeliveryPerformanceBugDeploymentEngineerType,
    { body: (string | number)[]; query: getDeliveryPerformanceQuery }
    >({
        query: ({ body, query }) => ({
          url: `/reports/delivery_performance/bug_deployment_engineer?${buildQueryParams(query || {})}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['DeliveryPerformanceBugDeploymentEngineer'],
      }),
    getPostProjectReport: builder.mutation<
    any,
    getPostProjectReportQuery
    >({
      query: (query: getPostProjectReportQuery) =>
        `/reports/post-project?${buildQueryParams(query || {})}`,
      invalidatesTags: ['PostProjectReport'],
    }),
    getDMAPopup: builder.query<
    DMAPopUpType,
    getDMAPopupQuery
    >({
      query: (query: getDMAPopupQuery) =>
        `/reports/project_status/dma_popup?${buildQueryParams(query || {})}`,
      providesTags: ['getDMAPopup'],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useCreateReportMutation,
  useGetReportQuery,
  useGetReportWidgetsQuery,
  useLazyGetReportLayoutsQuery,
  useUpdateReportMutation,
  useGetRunningReportQuery,
  useGetDataSourcesQuery,
  useProjectStatusMutation,
  useGetPortalPartnersQuery,
  useGetPortalCompaniesQuery,
  useGetWaypointInformationQuery,
  useGetSessionInformationQuery,
  useGetTopSoundInformationQuery,
  useGetDropdownQuery,
  useGetCorrelationInformationQuery,
  useGetConsumptionProfileInformationQuery,
  useGetDeliveryPerformanceLeakEngineerMutation,
  useGetDeliveryPerformanceDropdownQuery,
  useGetDeliveryPerformanceBugDeploymentEngineerMutation,
  useGetPostProjectReportMutation,
  useGetDMAPopupQuery,
} = reportApi;
