import Button from '@/components/Button/Button';
import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { ITableHeader } from '../types';
import * as Styled from './style';
export interface ColumnsSettingsValues {
  display: string[];
}

interface ColumnsSettingsProps {
  onSave: (values: ColumnsSettingsValues) => void;
  headers: ITableHeader[];
}

const ColumnsSettings: FC<ColumnsSettingsProps> = ({ onSave, headers }) => {
  const initialValues = {
    display: headers.reduce(
      (acc, { id, hidden }) => (hidden ? acc : acc.concat(id)),
      [] as string[]
    ),
  };

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      <Form>
        {headers.map(({ id, title }) => (
          <Styled.Option key={id}>
            <Styled.Label>
              <Field type="checkbox" name="display" value={id} />
              <Styled.OptionContent>{title}</Styled.OptionContent>
            </Styled.Label>
          </Styled.Option>
        ))}
        <Styled.ButtonContainer>
          <Button width="120px" height="34px" type="submit">
            Save
          </Button>
        </Styled.ButtonContainer>
      </Form>
    </Formik>
  );
};

export default ColumnsSettings;
