import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import { ITablePaginate } from '../types';
import * as Styled from './style';

export interface IPaginationProps {
  onChange: (page: ITablePaginate) => void;
  pages: number;
  initialPage?: number;
}

const Pagination: FC<IPaginationProps> = ({ onChange, pages, initialPage = 1 }) => {
  const initialValue = initialPage > pages ? 1 : initialPage;
  return (
    <Styled.Container>
      <Styled.Text data-testid="pagination-text">{`${initialPage} of ${pages}`}</Styled.Text>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={onChange}
        pageRangeDisplayed={3}
        initialPage={initialValue - 1}
        pageCount={pages}
        previousLabel="<"
        marginPagesDisplayed={2}
        containerClassName="pagination"
        pageClassName="pagination__page"
        activeClassName="pagination__page-active"
        pageLinkClassName="pagination__link"
        previousClassName="pagination__prev"
        nextClassName="pagination__next"
        previousLinkClassName="pagination__prev-link"
        nextLinkClassName="pagination__next-link"
        disabledClassName="pagination__disabled"
      />
    </Styled.Container>
  );
};

export default Pagination;
