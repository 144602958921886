import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';
import { ButtonVariant } from './Button';
import ButtonPresenter from './ButtonPresenter';

export const Container = styled.div<{
  width: string;
  height: string;
  disabled: boolean;
  variant: ButtonVariant;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const Image = styled.div`
  margin-right: 10px;
`;

export const Button = styled.button<{
  disabled: boolean;
  type: string;
  color: string;
  variant: ButtonVariant;
  icon: string;
}>`
  display: flex;
  width: ${({ icon }) => (icon ? 'auto' : 'calc(100% - 2px)')};
  height: ${({ icon }) => (icon ? 'auto' : 'calc(100% - 2px)')};
  padding: ${({ icon }) => (icon ? '10px' : '10px')};
  justify-content: center;
  align-items: center;
  border-style: none;
  border-radius: 4px;
  color: ${({ theme, variant, color }: ThemeProps<Theme> & { variant: ButtonVariant; color: string }) =>
    variant === ButtonVariant.outline ? color : theme.colors.white};
  background-color: ${({ color, variant }) =>
    variant === ButtonVariant.outline ? 'transparent' : `${color}E6`};
  border: 1px solid ${({ color }) => `${color}E6`};
  outline: none;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease background, 0.3s ease box-shadow, 0.3s ease color, 0.3s ease fill;

  :hover {
    ${({ color, variant, disabled }) => ButtonPresenter.getHoverStyles(color, variant, disabled)};
    & svg > path {
      fill: ${({ variant, theme }: ThemeProps<Theme>  & { variant: ButtonVariant }) =>
    variant === ButtonVariant.outline ? theme.colors.white : null};
    }
  }

  :disabled {
    ${({ variant, color }) => ButtonPresenter.getDisabledStyles(variant, color)};
    cursor: auto;
  }
`;
