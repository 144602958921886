import { IAnimationProps, IRectConfig } from '@/types/common.type';
import React, { FC, RefObject } from 'react';
import { DateRange as RangePicker } from 'react-date-range';
import { createPortal } from 'react-dom';
import * as Styled from './style';

interface IProps {
  dateRangeRef: RefObject<HTMLDivElement>;
  animationProps: IAnimationProps;
  onChange: (data: (Date | undefined)[]) => void;
  value: [Date, Date];
  rectConfig: IRectConfig | undefined;
}
const DateRangePicker: FC<IProps> = ({
  dateRangeRef,
  animationProps,
  onChange,
  value,
  rectConfig,
}) => {
  const PickerList = (
    <Styled.PickerContainer
      config={{
        isOverflow: rectConfig?.isOverflow,
        width: rectConfig?.width || 0,
        top: rectConfig?.top || 0,
        left: rectConfig?.left || 0,
        isPortal: rectConfig?.isPortal,
      }}
      ref={dateRangeRef}
      {...animationProps}
    >
      <RangePicker
        editableDateInputs={true}
        onChange={({ selection }) => onChange([selection.startDate, selection.endDate])}
        moveRangeOnFirstSelection={false}
        ranges={[
          {
            startDate: value ? value[0] : undefined,
            endDate: value ? value[1] : undefined,
            key: 'selection',
          },
        ]}
      />
    </Styled.PickerContainer>
  );
  return rectConfig?.isPortal ? createPortal(PickerList, document.body) : PickerList;
};

export default DateRangePicker;
