import { Loader } from '@/components';
import DateView from '@/components/DateView/DateView';
import DeliveryPerformanceFilters from '@/components/DeliveryPerformanceFilters/DeliveryPerformanceFilters';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import {
  useGetDeliveryPerformanceDropdownQuery,
  useGetDeliveryPerformanceLeakEngineerMutation
} from '@/redux/reports/reports.api';
import { LeakEngineerDayInfo } from '@/types/report.type';
import React, { FC, useContext, useEffect, useState } from 'react';
import DescContainer from './Components/DescContainer/DescContainer';
import * as Styled from './style';
import { QueryParams, extractEngineerIds } from './utils/utils';

const DeliveryPerformanceLEContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation('DELIVERY_PERFORMANCE_PAGE');
  const { data: dropdown } = useGetDeliveryPerformanceDropdownQuery();
  const {
    dayDropdownActiveValue,
    projectActiveValue,
    dmaActiveValue,
    engineerActiveValue,
    dateFilters,
  } = useContext(DeliveryPerformanceContext);

  const [dropdownEngineers, setDropdownEngineers] = useState<(string | number)[]>([]);

  const [mutate, { data: leakEngineerData, isLoading, isError }] =
    useGetDeliveryPerformanceLeakEngineerMutation();

  useEffect(() => {
    setDropdownEngineers(extractEngineerIds(dropdown));
  }, [dropdown]);

  useEffect(() => {
    mutate({
      body: engineerActiveValue.value ? [engineerActiveValue.value] : dropdownEngineers,
      query: QueryParams(projectActiveValue.value, dmaActiveValue.value, dateFilters),
    });
  }, [mutate, projectActiveValue, dmaActiveValue, engineerActiveValue, dateFilters, dropdownEngineers]);

  const data: (LeakEngineerDayInfo | null)[] =
    leakEngineerData?.map((item) => item.info[dayDropdownActiveValue] || null) || [];

  return (
    <Styled.Wrapper>
      <DeliveryPerformanceFilters />
      {isLoading && (
        <Styled.MainContainer data-testid="Loader">
          <Loader />
        </Styled.MainContainer>
      )}
      {isError && (
        <Styled.MainContainer>
          <p>{t('ERROR.REQUEST')}</p>
        </Styled.MainContainer>
      )}
      {!data ||
        (data.length < 1 && !isLoading && !isError && (
          <Styled.MainContainer>
            <p>{t('ERROR.NO_DATA')}</p>
          </Styled.MainContainer>
        ))}
      {data && data.length > 0 && (
        <Styled.DescWrapper>
          {leakEngineerData &&
            data.map((item, index) => (
              <>
                {item && (
                  <Styled.DeliveryPerformanceContainer>
                    <Styled.NameContainer>
                      <p>{prefixedT('NAME')}</p>
                      <Styled.NameWrapper>
                        <p>{leakEngineerData[index].name}</p>
                      </Styled.NameWrapper>
                    </Styled.NameContainer>
                    <Styled.OptionValueContainer>
                      <Styled.DateContainer>
                        {item.start_time && <DateView date={item.start_time} startDate={true} />}
                        {item.start_time && <DateView date={item.end_time} startDate={false} />}
                      </Styled.DateContainer>
                      <Styled.OptionContainers>
                        <DescContainer
                          title={prefixedT('LEAKS_MARKED_UP')}
                          description={item.leaks_marked_up}
                        />
                        <DescContainer
                          title={prefixedT('NON_LEAKS_MARKED_UP')}
                          description={item.non_leaks_marked_up}
                        />
                        <DescContainer
                          title={prefixedT('CONVERSION_RATE')}
                          description={item.non_leaks_marked_up}
                        />
                        <DescContainer
                          title={prefixedT('IN_PROGRESS_WAYPOINTS')}
                          description={item.in_progress_waypoints}
                        />
                        <DescContainer
                          title={prefixedT('NOT_FOUND_WAYPOINTS')}
                          description={item.not_found_waypoints}
                        />
                        <DescContainer
                          title={prefixedT('WAYPOINTS_INVESTIGATED')}
                          description={item.waypoints_investigated}
                        />
                      </Styled.OptionContainers>
                    </Styled.OptionValueContainer>
                  </Styled.DeliveryPerformanceContainer>
                )}
              </>
            ))}
        </Styled.DescWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default DeliveryPerformanceLEContainer;
