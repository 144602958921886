import { IForgotPasswordValues, ILoginValues, IRecoveryValues } from '@/types/auth.type';
import { IDecodeTokenValues } from '@/types/auth.type';
import { IPaginationProps, IPaginationResponse, ISort } from '@/types/rtk.type';
import { EditUserValues, IRole, IUser } from '@/types/user.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const userApi = createApi({
  reducerPath: 'userQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Roles', 'User', 'Profile'],
  endpoints: (builder) => ({
    login: builder.mutation<IUser, ILoginValues>({
      query: (body: ILoginValues) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body: IForgotPasswordValues) => ({
        url: '/forgot',
        method: 'POST',
        body,
      }),
    }),
    recovery: builder.mutation({
      query: (body: IRecoveryValues) => ({
        url: '/recovery',
        method: 'POST',
        body,
      }),
    }),
    decodeToken: builder.mutation({
      query: (body: IDecodeTokenValues) => ({
        url: '/decode',
        method: 'POST',
        body,
      }),
    }),
    createUser: builder.mutation({
      query: (body: FormData) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    getRoles: builder.query<IRole[], void>({
      query: () => '/users/roles',
      providesTags: ['Roles'],
    }),
    getProfile: builder.query<IUser, void>({
      query: () => '/users/profile',
      providesTags: ['Profile'],
    }),
    getUser: builder.query<IUser, string | number>({
      query: (id) => `/users/${id}`,
      providesTags: ['User'],
    }),
    editUser: builder.mutation({
      query: ({ id, ...body }: EditUserValues) => ({
        url: `/users/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (id: number | string) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    getUsers: builder.query<IPaginationResponse<IUser>, IPaginationProps & ISort>({
      query: (params) => `/users?${buildQueryParams(params)}`,
      providesTags: (result) =>
        result
          ? [
            ...result.data.map(({ id }) => ({ type: 'User' as const, id })),
            { type: 'User', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'User', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useLoginMutation,
  useRecoveryMutation,
  useForgotPasswordMutation,
  useDecodeTokenMutation,
  useGetUsersQuery,
  useGetProfileQuery,
  useGetRolesQuery,
  useGetUserQuery,
  useEditUserMutation,
  useDeleteUserMutation,
} = userApi;
