import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './style';

interface WaypointHeadingsProps {
  waypointID: string;
  correlationIds: number[];
  consumptionProfileIds: number[];
  sessionIds: string[];
  topSoundIds: number[];
  active?: string;
}

const WaypointHeadings: FC<WaypointHeadingsProps> = ({
  active,
  waypointID,
  sessionIds,
  topSoundIds,
  correlationIds,
  consumptionProfileIds,
}) => {
  const navigate = useNavigate();
  const handleLink = (option: DropDownOption, location: string): void => {
    switch (location) {
      case 'session':
        navigate(`/app/session?waypoint=${waypointID}&session=${option.value}`);
        break;
      case 'consumptionProfile':
        navigate(`/app/consumption-profile?waypoint=${waypointID}&consumptionProfile=${option.value}`);
        break;
      case 'topSound':
        navigate(`/app/top-sound?waypoint=${waypointID}&topSound=${option.value}`);
        break;
      case 'correlation':
        navigate(`/app/correlation?waypoint=${waypointID}&correlation=${option.value}`);
        break;
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.DropdownContainer>
        <DropDown
          value={'1'}
          options={sessionIds.map((sessionId) => ({
            value: sessionId,
            label: `Session: ${sessionId}`,
          }))}
          onChange={(value) => handleLink(value as DropDownOption, 'session')}
          placeholder={'Sessions'}
          disabled={sessionIds.length > 0 ? false : true}
          active={active === 'Session'}
        />
      </Styled.DropdownContainer>
      <Styled.DropdownContainer>
        <DropDown
          value={'1'}
          options={consumptionProfileIds.map((consumptionProfileId) => ({
            value: consumptionProfileId.toString(),
            label: `Consumption Profile: ${consumptionProfileId}`,
          }))}
          onChange={(value) => handleLink(value as DropDownOption, 'consumptionProfile')}
          placeholder={'Consumption Profile'}
          disabled={consumptionProfileIds.length > 0 ? false : true}
          active={active === 'Consumption Profile'}
        />
      </Styled.DropdownContainer>
      <Styled.DropdownContainer>
        <DropDown
          value={'1'}
          options={topSoundIds.map((topSoundId) => ({
            value: topSoundId.toString(),
            label: `Top Sound: ${topSoundId}`,
          }))}
          onChange={(value) => handleLink(value as DropDownOption, 'topSound')}
          placeholder={'Top Sound'}
          disabled={topSoundIds.length > 0 ? false : true}
          active={active === 'Top Sound'}
        />
      </Styled.DropdownContainer>
      <Styled.DropdownContainer>
        <DropDown
          value={'1'}
          options={correlationIds.map((correlationId) => ({
            value: correlationId.toString(),
            label: `Correlation: ${correlationId}`,
          }))}
          onChange={(value) => handleLink(value as DropDownOption, 'correlation')}
          placeholder={'Correlation'}
          disabled={correlationIds.length > 0 ? false : true}
          active={active === 'Correlation'}
        />
      </Styled.DropdownContainer>
    </Styled.Wrapper>
  );
};

export default WaypointHeadings;
