import { Button, ReportLayout, Switch } from '@/components';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import { SetState } from '@/types/common.type';
import {
  DropWidget,
  Report,
  ReportLayout as ReportLayoutType,
  ReportWidget,
  Widget as WidgetType
} from '@/types/report.type';
import React, { FC } from 'react';
import CustomDragLayer from './CustomDragLayer/CustomDragLayer';
import * as Styled from './style';
import { Widget } from './Widget/Widget';

type Props = {
  onAddWidget: (widget: DropWidget, layoutId: string | number) => void;
  onMoveWidget: (widget: DropWidget, layoutId: string | number) => void;
  onSortWidgets: (layoutId: string | number, newWidgets: ReportWidget<unknown>[]) => void;
  onRemoveWidget: (widget: ReportWidget<unknown>, layoutId: string | number) => void;
  onUpdateWidget: (widget: ReportWidget<unknown>, layoutId: string | number) => void;
  sortable: boolean;
  pageNumber: number | undefined;
  setSortable: SetState<boolean>;
  structure: ReportLayoutType;
  report: Report;
  widgets: WidgetType[];
  onPressSave: () => void;
  onPressRun: () => void;
};

const ReportBuilder: FC<Props> = ({
  sortable,
  pageNumber,
  setSortable,
  structure,
  onAddWidget,
  onMoveWidget,
  onSortWidgets,
  onRemoveWidget,
  onUpdateWidget,
  report,
  widgets,
  onPressSave,
  onPressRun,
}) => {
  const { prefixedT } = useCustomTranslation('REPORT_BUILDER');
  return (
    <Styled.Wrapper>
      <Styled.TitleContainer>
        <Styled.TitleWrapper>
          <Styled.Title>{report?.name}</Styled.Title>
        </Styled.TitleWrapper>

        <Styled.ControlsWrapper>
          <Styled.Controls>
            <Switch
              checked={sortable}
              onChange={() => setSortable(!sortable)}
              label={prefixedT('SORT_MODE')}
            />
            <Button color={theme.colors.green} width="120px" height="34px" onClick={onPressRun}>
              <Styled.ButtonContainer>
                <Styled.RunIcon /> {prefixedT('RUN')}
              </Styled.ButtonContainer>
            </Button>
            <Button color={theme.colors.blue} width="120px" height="34px" onClick={onPressSave}>
              <Styled.ButtonContainer>
                <Styled.CheckIcon /> {prefixedT('SAVE')}
              </Styled.ButtonContainer>
            </Button>
          </Styled.Controls>
        </Styled.ControlsWrapper>
      </Styled.TitleContainer>
      <Styled.Container>
        <CustomDragLayer />
        <Styled.WidgetsContainer>
          {widgets.map((item) => (
            <Widget
              key={item.id}
              id={item.key}
              disabled={item.disabled || sortable}
              name={item.name}
              icon={item.icon}
            />
          ))}
        </Styled.WidgetsContainer>
        <Styled.LayoutWrapper>
          <ReportLayout
            structure={structure}
            onAddWidget={onAddWidget}
            sortable={sortable}
            reportId={undefined}
            reportName={undefined}
            tableResultsCount={undefined}
            pageNumber={pageNumber}
            order={undefined}
            orderBy={undefined}
            onMoveWidget={onMoveWidget}
            onSortWidgets={onSortWidgets}
            onRemoveWidget={onRemoveWidget}
            onUpdateWidget={onUpdateWidget}
          />
        </Styled.LayoutWrapper>
      </Styled.Container>
    </Styled.Wrapper>
  );
};
export default withPageLoader(ReportBuilder);
