import React, { FC } from 'react';
import { BsSearch } from 'react-icons/bs';
import * as Styled from './style';

interface SearchBarProps {
  placeholder: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: FC<SearchBarProps> = ({ placeholder, handleSearch }) => (
  <Styled.Container>
    <Styled.Input
      type="text"
      id="search-bar"
      placeholder={placeholder}
      onChange={handleSearch}
    />
    <Styled.Icon>
      <BsSearch size={20} />
    </Styled.Icon>
  </Styled.Container>
);

export default SearchBar;
