import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import withPageLoader from '@/HOCs/withPageLoader';
import { IBranch } from '@/types/branch.type';
import { ICompany } from '@/types/company.type';
import { ILog, ILogFilters } from '@/types/log.type';
import React, { FC } from 'react';
import { Filters } from './Filters';
import { IFilterValues } from './Filters/Filters';
import { LogTable } from './LogTable';
import * as Styled from './style';

interface IProps {
  page: number;
  totalCount: number;
  logs: ILog[];
  pages: number;
  order: TableSortOrders;
  orderBy: string;
  onPageChange: (p: ITablePaginate) => void;
  onFilter: (values: IFilterValues) => void;
  filters: ILogFilters;
  companies: ICompany[];
  branches: IBranch[];
  setSorting: (orderBy: string, order: string) => void;
}

const ChangeLog: FC<IProps> = ({
  page,
  totalCount,
  logs,
  pages,
  order,
  orderBy,
  onPageChange,
  onFilter,
  filters,
  companies,
  branches,
  setSorting,
}) => (
  <>
    <Styled.Wrapper data-testid="logsPage">
      <Filters onSubmit={onFilter} filters={filters} companies={companies} branches={branches} />
      <LogTable
        page={page}
        pages={pages}
        onPageChange={onPageChange}
        logs={logs}
        totalCount={totalCount}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </Styled.Wrapper>
  </>
);

export default withPageLoader(ChangeLog);
