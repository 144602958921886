import { MapElement } from '../components/ReportLayout/widgets/MapView/utils/types';
import { ReactSVGIcon } from './common.type';
export type Widget = {
  id: number;
  key: WidgetType;
  type: string;
  name: string;
  icon: ReactSVGIcon;
  disabled?: boolean;
};

export type ReportSettingsValue = string | number;

export type ReportFilterSettings = {
  control_field: number[];
  filter_type: number[];
  filter_options: (ReportSettingsValue | ReportSettingsValue[])[];
  datasource: number;
};

export type ReportSummarySettings = {
  column: number[];
  summary_title: string[];
  summary_type: number[];
  datasource: number;
};

export type ReportMapSettings = {
  datasource: number;
};

export type ReportResultsSettings = {
  columns: number[];
  datasource: number;
};

export type ReportWidget<S> = {
  id: string | number;
  key: WidgetType;
  data?: MapElement[];
  settings?: S;
};

export type DropWidget = {
  widget: ReportWidget<unknown>;
  type: WidgetAction;
  layoutId?: number | string;
};

export enum WidgetType {
  RESULTS = 'results',
  MAP = 'map',
  FILTERS = 'filters',
  SUMMARY = 'summary',
}

export enum WidgetAction {
  ADD = 'add',
  MOVE = 'move',
  DELETE = 'delete',
  UPDATE = 'update',
}

export type ReportLayoutItem = {
  layoutId: number;
  children?: ReportLayoutItem[];
  widgets?: ReportWidget<unknown>[];
  type: string;
};

export type ReportLayout = ReportLayoutItem[];

export type PaginatedReport = {
  count: number;
  data: Report;
};
export type Report = {
  id: number | string;
  name: string;
  update_at: string;
  structure: ReportLayout;
  layout_id: number;
};

export type CreateReportValues = {
  name: string;
  redirectToEdit: boolean;
  layoutId?: number | null;
};

export type UpdateReportValues = {
  name: string;
  structure: ReportLayout;
  layoutId?: number | null;
};

export type Layout = {
  id: number;
  description: string;
  name: string;
  image: string;
  structure?: ReportLayout;
};

export type Layouts = Layout[];

export type ReportDataSource = {
  id: number;
  description: string;
  name: string;
  link: string;
  columns: ReportDataSourceColumn[];
};

export type ReportDataSourceFilterOption = {
  option: string;
  id: number;
};

export type ReportDataSourceFilterType = {
  name: string;
  id: number;
};

export type ReportDataSourceColumn = {
  id: number;
  name: string;
  filters: ReportDataSourceFilterOption[];
  types: ReportDataSourceFilterType[];
};

type MapData = {
  data: MapElement[];
};

type Map = {
  dmas?: MapData;
  pipes?: MapData;
  assets?: MapData;
  sweepinfofordmaperformance_leak?: MapData;
  sweepinfofordmaperformance_non_leak?: MapData;
  waypointsinfo?: MapData;
};

type SelectOption = {
  predicted_size: string[][];
  status: string[][];
};

type SummaryData = {
  label: string;
  value: string;
};

type Summary = {
  data: SummaryData[];
};

export interface InfoItem {
  cost_year: {
    label: string;
    value: number;
  };
  end_date: {
    label: string;
    value: string;
  };
  issues?: {
    label: string;
    value: number;
  };
  large_leaks: {
    label: string;
    value: number;
  };
  leaks_identified: {
    label: string;
    value: number;
  };
  medium_leaks: {
    label: string;
    value: number;
  };
  number_marked: {
    label: string;
    value: number;
  };
  number_repaired: {
    label: string;
    value: number;
  };
  number_scheduled: {
    label: string;
    value: number;
  };
  percentage_complete?: {
    label: string;
    value: number;
  };
  sessions_analised: {
    label: string;
    value: number;
  };
  small_leaks: {
    label: string;
    value: number;
  };
  start_date: {
    label: string;
    value: string;
  };
  total_leakage: {
    label: string;
    value: number;
  };
}

export type DmaItem = {
  id: string;
  info: InfoItem;
};

export type ProjectStatusReportType = {
  user_id: number;
  name: string;
  select_options: SelectOption;
  map: Map;
  summary: Summary;
  dmas: DmaItem[];
};

export interface HistoryLog {
  status_changed_from_id?: number | null;
  user_id?: number | null;
  datetime?: string | null;
  reference_waypoint_id?: number | null;
  scheduled_date?: string | null;
  user_name?: string | null;
  changed_to_status_name?: string | null;
  id?: number | null;
  waypoint_id?: number | null;
  status_changed_to_id?: number | null;
  notes?: string | null;
  waypoint_name?: string | null;
  reference_waypoint?: number | null;
}

export interface WaypointInformationType {
  current_status: string;
  waypoint_name: string;
  surface_type: string;
  pipe_material: string;
  AI_size: string;
  address: string;
  what3words: string;
  estimated_size: null;
  leak_type: null;
  CNS: null;
  map_info: {
    area_of_interest: MapData;
    leak_location: MapData;
  };
  session_ids: string[];
  history_log: HistoryLog[];
  overnight: boolean;
  top_sound: boolean;
  correlation: boolean;
  scheduled: boolean;
  marked_up: boolean;
  repair_scheduled: boolean;
  repaired: boolean;
  link_to_assets: string[];
}

export interface SessionInformationType {
  fido_result: string;
  leak_size: string;
  collected_engineer: string;
  map_info: MapData;
  deployed_engineer: string;
  company: string;
  location: string;
  what3words: string;
  postcode: string;
  number_of_recording: number;
}

export interface DropdownType {
  session_ids: string[];
  consumption_profile_ids: number[];
  topsound_ids: number[];
  correlation_ids: number[];
}

export interface TopSoundInformationtype {
  list_of_recordings: number[];
  map_info: MapData;
  engineer: string;
  company: string;
  time_of_top_sounding: number;
  HF_cutoff: number;
  LF_cutoff: number;
  starting_distance: number;
  spacing: number;
  number_of_paths: number;
  location: string;
  what3words: string;
  postcode: string;
}

export interface CorrelationInformationType {
  session_ids: string[];
  map_info: MapData;
  pipe_segments: PipeSegments[];
  engineer: string;
  company: string;
  timestamp: string;
  leak_distance: string;
  speed_of_sound: number;
  total_length: number;
  location_point_a: string;
  location_point_b: string;
}

interface PipeSegments {
  dia_p1: number;
  len_p1: number;
  mat_p1: string;
}

export interface ConsumptionProfileInformationType {
  id: number;
  start_time: string;
  end_time: string | null;
  infinite_mode: boolean;
  expected_samples: number;
  uploaded_samples: number | null;
  collected_engineer: string | null;
  deployed_engineer: string;
  company: string;
  map_info: MapData;
  location: string;
  what3words: string;
  postcode: string;
  link: string;
}

export interface LeakEngineerDayInfo {
  waypoints_investigated: number | null;
  leaks_marked_up: number | null;
  non_leaks_marked_up: number | null;
  in_progress_waypoints: number | null;
  not_found_waypoints: number | null;
  conversion_rate: number | null;
  start_time: string;
  end_time: string;
}

export interface BugDeploymentDayInfo {
  KM_covered: number | null;
  collections: number | null;
  deployment_accuracy: number | null;
  deployments: number | null;
  end_time: string;
  hours_worked: number | null;
  start_time: string;
}

export interface LeakEngineerInfo {
  day1: LeakEngineerDayInfo | null;
  day2: LeakEngineerDayInfo | null;
  day3: LeakEngineerDayInfo | null;
  day4: LeakEngineerDayInfo | null;
  day5: LeakEngineerDayInfo | null;
  day6: LeakEngineerDayInfo | null;
  day7: LeakEngineerDayInfo | null;
  total: LeakEngineerDayInfo | null;
}

export interface BugDeploymentInfo {
  day1: BugDeploymentDayInfo | null;
  day2: BugDeploymentDayInfo | null;
  day3: BugDeploymentDayInfo | null;
  day4: BugDeploymentDayInfo | null;
  day5: BugDeploymentDayInfo | null;
  day6: BugDeploymentDayInfo | null;
  day7: BugDeploymentDayInfo | null;
  total: BugDeploymentDayInfo | null;
}

export type DeliveryPerformanceLeakEngineerType = DeliveryPerformanceLeakEngineerTypes[];
export interface DeliveryPerformanceLeakEngineerTypes {
  id: number;
  name: string;
  info: LeakEngineerInfo;
}

interface DeliveryPerformanceBugDeploymentEngineerTypes {
  id: number;
  name: string;
  info: BugDeploymentInfo;
}

export type DeliveryPerformanceBugDeploymentEngineerType = DeliveryPerformanceBugDeploymentEngineerTypes[];

interface DMAS {
  name: string;
  id: string;
}

interface Engineers {
  name: string;
  id: string;
}

export type DeliveryPerformanceDropdownType = DeliveryPerformanceDropdownTypes[];
interface DeliveryPerformanceDropdownTypes {
  project_id: number;
  project_name: string;
  dmas: DMAS[];
  engineers: Engineers[];
}

export interface DMAPopUpType {
  confirmed_leaks: number;
  id: number;
  name: string;
  region: string;
}
