import {
  AuthButtonContainer,
  AuthFormSubtitle,
  AuthFormTitle,
  AuthLogo,
  AuthWrapper
} from '@/layouts/AuthLayout/style';
import styled from 'styled-components';

export const Wrapper = styled(AuthWrapper)``;

export const LogoContainer = styled(AuthLogo)``;

export const Title = styled(AuthFormTitle)``;

export const SubTitle = styled(AuthFormSubtitle)``;

export const ButtonContainer = styled(AuthButtonContainer)``;
