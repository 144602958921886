
import { IUser, UserRoles } from '@/types/user.type';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckPermissions = (user: IUser, profile: IUser) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (user && profile) {
      const isSuperAdmin = profile.role?.code === UserRoles.SUPER_ADMIN;
      if (!isSuperAdmin && user.companyId !== profile.companyId) {
        navigate('/app/users');
      }
    }
  }, [user, profile]);
};

export default useCheckPermissions;
