import React, { FC } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';
import * as Styled from './style';

type Props = {
  label?: string;
  placeholder: string;
  value: File | null;
  onChange: (file: File) => void;
  disabled?: boolean;
  error?: string;
  styles?: Styles;
  name: string;
  extentions?: string[];
  onClear: () => void;
};

type Styles = {
  label?: FlattenSimpleInterpolation;
};

const UploadFile: FC<Props> = ({
  placeholder,
  value,
  onChange,
  disabled,
  label,
  styles,
  error,
  name,
  extentions = [],
  onClear,
}) => {
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onChange(e.target.files[0]);
    }
  };
  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label labelStyle={styles?.label} isError={!!error}>
          {label}
        </Styled.Label>
      )}
      <Styled.Container disabled={Boolean(disabled)} error={!!error}>
        {value?.name && <Styled.Clear onClick={onClear} />}
        <Styled.InputLabel disabled={Boolean(disabled)}>
          <Styled.Input
            name={name}
            disabled={disabled}
            onChange={changeHandler}
            type="file"
            accept={extentions.filter((el: string) => el[0] === '.').join(',')}
          />
          <Styled.InputLabelText>Choose file</Styled.InputLabelText>
        </Styled.InputLabel>
        <Styled.Placeholder>{value?.name || placeholder}</Styled.Placeholder>
      </Styled.Container>
      {Boolean(error) && <Styled.Error>{error}</Styled.Error>}
    </Styled.Wrapper>
  );
};
export default UploadFile;
