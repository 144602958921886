import React, { createContext, FC, useEffect, useState } from 'react';

export interface DateFilters {
  dateFrom: Date;
  dateTo: Date;
}

export interface FilterAttributes {
  predicted_size: {
    Small: boolean;
    Medium: boolean;
    Large: boolean;
  };
  status: {
    'Awaiting Scheduling': boolean;
    Scheduled: boolean;
    'Confirmed Noise Source': boolean;
    'Marked Up': boolean;
    'Unconfirmed Noise Source': boolean;
    Scheduling: boolean;
    Closed: boolean;
    'Leak Submitted To Client': boolean;
    Repaired: boolean;
    'Dry Dig': boolean;
    Edited: boolean;
    Proposed: boolean;
    'Further Work Requested': boolean;
  };
}

export interface MapFilters {
  filters: string[];
}
export interface FilterContextProps {
  mapFilters: MapFilters;
  setMapFilters: (values: MapFilters) => void;
  dateFilters: DateFilters;
  setDateFilters: (values: DateFilters) => void;
  filterAttributes: FilterAttributes;
  setFilterAttributes: React.Dispatch<React.SetStateAction<FilterAttributes>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export const FilterContext = createContext<FilterContextProps>({
  mapFilters: {
    filters: ['DMAS'],
  },
  setMapFilters: () => [],
  dateFilters: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
  setDateFilters: () => {},
  filterAttributes: {
    predicted_size: {
      Small: false,
      Medium: false,
      Large: false,
    },
    status: {
      'Awaiting Scheduling': false,
      Scheduled: false,
      'Confirmed Noise Source': false,
      'Marked Up': false,
      'Unconfirmed Noise Source': false,
      Scheduling: false,
      Closed: false,
      'Leak Submitted To Client': false,
      Repaired: false,
      'Dry Dig': false,
      Edited: false,
      Proposed: false,
      'Further Work Requested': false,
    },
  },
  setFilterAttributes: () => {},
  search: '',
  setSearch: () => '',
});

interface FilterProviderProps {
  children: React.ReactNode;
}

export const FilterProvider: FC<FilterProviderProps> = ({ children }) => {
  const currentDate = new Date();
  const previousDate = new Date();
  previousDate.setDate(currentDate.getDate() - 30);
  const isoString = previousDate.toISOString();
  const formattedDate = new Date(isoString);

  const [search, setSearch] = useState<string>('');

  const [filterAttributes, setFilterAttributes] = useState<FilterAttributes>(() => {
    const storedFilterAttributes = localStorage.getItem('filterAttributes');
    if (storedFilterAttributes) {
      return JSON.parse(storedFilterAttributes) as FilterAttributes;
    } else {
      return {
        predicted_size: {
          Small: false,
          Medium: false,
          Large: false,
        },
        status: {
          'Awaiting Scheduling': false,
          Scheduled: false,
          'Confirmed Noise Source': false,
          'Marked Up': false,
          'Unconfirmed Noise Source': false,
          Scheduling: false,
          Closed: false,
          'Leak Submitted To Client': false,
          Repaired: false,
          'Dry Dig': false,
          Edited: false,
          Proposed: false,
          'Further Work Requested': false,
        },
      };
    }
  });
  const [mapFilters, setMapFilters] = useState<MapFilters>(() => {
    const storedMapFilters = localStorage.getItem('mapFilters');
    if (storedMapFilters) {
      return JSON.parse(storedMapFilters) as MapFilters;
    } else {
      return { filters: [] };
    }
  });
  const [dateFilters, setDateFilters] = useState<DateFilters>({
    dateFrom: formattedDate,
    dateTo: new Date(),
  });

  useEffect(() => {
    localStorage.setItem('filterAttributes', JSON.stringify(filterAttributes));
  }, [filterAttributes]);

  useEffect(() => {
    localStorage.setItem('mapFilters', JSON.stringify(mapFilters));
  }, [mapFilters]);

  return (
    <FilterContext.Provider
      value={{
        mapFilters,
        setMapFilters,
        dateFilters,
        setDateFilters,
        filterAttributes,
        setFilterAttributes,
        search,
        setSearch,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
