import { Button, TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { AddCompanyValues } from '@/types/company.type';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import * as Styled from '../style';
import { ValidationSchema } from './schema';

interface AddCompanyProps {
  onSubmit: (data: AddCompanyValues) => void;
  isLoading: boolean;
}

const initialValues: AddCompanyValues = {
  name: '',
  address: '',
};

const AddCompany: FC<AddCompanyProps> = ({ onSubmit, isLoading }) => {
  const { t, prefixedT } = useCustomTranslation('COMPANIES_PAGE.ADD_COMPANY');

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors }) => (
        <Styled.CompanyForm data-testid="add-company-form">
          <Field
            name="name"
            as={TextField}
            label={prefixedT('FORM.NAME.LABEL')}
            placeholder={prefixedT('FORM.NAME.PLACEHOLDER')}
            error={errors.name && t(errors.name)}
          />
          <Field
            name="address"
            as={TextField}
            label={prefixedT('FORM.ADDRESS.LABEL')}
            placeholder={prefixedT('FORM.ADDRESS.PLACEHOLDER')}
            error={errors.address && t(errors.address)}
          />
          <Styled.FormButton>
            <Button width="100%" height="100%" type="submit" loading={isLoading}>
              <Styled.ButtonContent>
                <Styled.PlusIcon />
                {prefixedT('FORM.SUBMIT')}
              </Styled.ButtonContent>
            </Button>
          </Styled.FormButton>
        </Styled.CompanyForm>
      )}
    </Formik>
  );
};

export default AddCompany;
