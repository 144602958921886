import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

export const keyTitle = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  font-weight: 600;
  font-size: 12px;
`;

export const KeyWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

export const keyContainer = styled.ul`
  display: flex;
  gap:10px;
`;

export const key = styled.li`
  display: flex;
  align-items: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  font-weight: 600;
  font-size: 12px;
  gap: 10px;
  text-transform: capitalize;
`;

export const Green = styled.div`
  height: 25px;
  width: 25px;
  background: green;
`;

export const Yellow = styled.div`
  height: 25px;
  width: 25px;
  background: yellow;
`;

export const Red = styled.div`
  height: 25px;
  width: 25px;
  background: red;
`;

export const Numbers = styled.div<{
  percentage: number;
  colorKey: number;
}>`
  flex-grow: ${({ percentage }) => percentage}; 
  height: 30px;
  background-color: ${({ colorKey }) => {
    if (colorKey === 0) {
      return ({ theme }: ThemeProps<Theme>) => theme.colors.mediumGreen;
    } else if (colorKey === 1) {
      return '#E59234';
    } else if (colorKey === 2) {
      return ({ theme }: ThemeProps<Theme>) => theme.colors.artyClickRed;
    }
  }}; ;
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const errorMsg = styled.p`
  font-weight: 600;
  color: #027cbb;
  text-align: center;
`;
