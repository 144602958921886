import { ReactComponent as File } from '@/assets/icons/file.svg';
import { ReactComponent as PlusButtonIcon } from '@/assets/icons/plus.svg';
import { Theme } from '@/theme';
import { Form } from 'formik';
import styled, { ThemeProps } from 'styled-components';

export const CompanyForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormButton = styled.div`
  height: 34px;
  width: 140px;
  align-self: flex-end;
  display: flex;
  align-items: baseline;
`;

export const PlusIcon = styled(PlusButtonIcon)`
  margin-right: 9px;
`;
export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

export const SwitchWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  height: 42px;
  width: 140px;
`;

export const BulkContainer = styled.div`
  display: flex;
  margin: 4px 0;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export const DownloadTemplate = styled.a`
  margin-left: 160px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  text-decoration: none;
`;

export const DownloadTemplateText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.mediumBlue};
`;

export const DownloadTemplateIcon = styled(File)`
  width: 13px;
  height: 18px;
  margin-right: 10px;
`;
