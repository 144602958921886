import {
  AuthButtonContainer,
  AuthFields,
  AuthForm,
  AuthFormSubtitle,
  AuthFormTitle,
  AuthLogo,
  AuthSubtitleWrapper,
  AuthWrapper
} from '@/layouts/AuthLayout/style';
import styled from 'styled-components';

export const Wrapper = styled(AuthWrapper)``;

export const LogoContainer = styled(AuthLogo)``;

export const TitleWrapper = styled.div`
  width: 100%;
  height: 25px;
  margin-bottom: 8px;
`;

export const Title = styled(AuthFormTitle)``;

export const SubTitle = styled(AuthFormSubtitle)``;

export const ButtonContainer = styled(AuthButtonContainer)``;

export const SubtitleWrapper = styled(AuthSubtitleWrapper)``;

export const Form = styled(AuthForm)``;

export const Fields = styled(AuthFields)``;

export const FieldWrapper = styled.div`
  width: 100%;
  height: 66px;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 46px;
`;
