import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow_down.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ActiveAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  & :first-child {
    margin-right: 8px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    & :first-child {
      margin-right: 0;
    }
  }
`;

export const ArrowDownIcon = styled(ArrowIcon)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
`;

export const ActiveAccount = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const AccountList = styled.div<{
  open: boolean;
}>`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 170px;
  justify-content: center;
  z-index: 99;
  display: flex;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    right: calc(50% - 34px);
  }
`;

export const AccountItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 15px;
  :hover {
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightBlue}33;
  }
  transition: 0.3s ease background-color;
  padding: 10px 20px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const ItemName = styled.div`
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const CompanyList = styled.div``;

export const CompanyItem = styled.div``;

export const AccountItemWrapper = styled.div`
  position: relative;
`;

export const CompanyItemWrapper = styled.div`
  position: relative;
`;

export const TitleWrapper = styled.div`
  padding: 20px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.malibu};
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  cursor: default;
`;

export const CheckLanguageIcon = styled(ArrowIcon)`
transform: rotate(-90deg);
transition: 0.3s ease transform;
`;

export const ListContainer = styled.div`
  width: 170px;
`;

export const CompanyListContainer = styled.div`
  position: absolute;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  width: max-content;
  max-width: 220px;
  left: 170px;
`;
