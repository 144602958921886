import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const ColumnWrapper = styled.div<{
  type?: string;
}>`
display: flex;
padding: 40px;
flex-direction: ${({ type }) => type === 'company' ? 'column' : 'row'};
flex-wrap: wrap;
gap: ${({ type }) => type === 'company' ? '0' : '20px'};

  @media (min-width: 2120px) {
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
  }
`;

export const RowWrapper = styled.div<{
  mainAxisAlignment?: string;
  crossAxisAlignment?: string;
}>`
display: flex;
flex-direction: row;
position: sticky;
top: 0;
padding: 20px;
z-index: 1;
background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
justify-content: ${({ mainAxisAlignment }) => mainAxisAlignment ? mainAxisAlignment : 'normal'};
align-items: ${({ crossAxisAlignment }) => crossAxisAlignment ? crossAxisAlignment : 'normal'};
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 170px;
  padding: 5px;
`;
export const ArrowDown = styled.span`
  position: absolute;
  top: 22px;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
`;
