import { TextField } from '@/components';
import Button, { ButtonVariant } from '@/components/Button/Button';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import { EditCompanyValues, ICompany } from '@/types/company.type';
import { IUser, UserRoles } from '@/types/user.type';
import { Field, Formik } from 'formik';
import React, { FC, useState } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

interface EditCompanyProps {
  company: ICompany;
  onSubmit: (data: EditCompanyValues, callback?: () => void) => void;
  onDelete: (company: ICompany) => void;
  profile: IUser;
}

interface EditCompanyFormValues {
  name: string;
  address: string;
}

const EditCompanyForm: FC<EditCompanyProps> = ({ company, onSubmit, onDelete, profile }) => {
  const [isEditActive, setEditActive] = useState(false);
  const { t, prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE');
  const initialValues = {
    name: company.name,
    address: company.address,
  };

  const handleSubmit = (values: EditCompanyFormValues) => {
    onSubmit({ id: company.id, ...values }, () => {
      setEditActive(false);
    });
  };

  const handleDelete = () => {
    onDelete(company);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, resetForm, dirty }) => {
        const handleDiscard = () => {
          resetForm();
          setEditActive(false);
        };
        return (
          <Styled.EditForm data-testid="edit-company-form">
            <Styled.EditHeader>
              <Styled.Title data-testid="editCompanyForm-name">{company.name}</Styled.Title>
              {profile?.role?.code === UserRoles.SUPER_ADMIN && (
                <Styled.EditButtons>
                  <Button
                    variant={ButtonVariant.outline}
                    color={theme.colors.coral}
                    width="122px"
                    height="34px"
                    type="button"
                    onClick={isEditActive ? handleDiscard : handleDelete}
                    testId="editButton-delete"
                  >
                    <Styled.EditButtonContent>
                      {!isEditActive && <Styled.TrashIcon />}
                      {prefixedT(isEditActive ? 'FORM.DISCARD' : 'FORM.DELETE')}
                    </Styled.EditButtonContent>
                  </Button>
                  {isEditActive ? (
                    <Button
                      variant={ButtonVariant.default}
                      color={theme.colors.lightBlue}
                      width="122px"
                      height="34px"
                      type="submit"
                      disabled={!dirty}
                      testId="editButton-submit"
                    >
                      {prefixedT('FORM.SUBMIT')}
                    </Button>
                  ) : (
                    <Button
                      variant={ButtonVariant.outline}
                      color={theme.colors.lightBlue}
                      width="122px"
                      height="34px"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditActive(true);
                      }}
                      type="button"
                      testId="editButton-edit"
                    >
                      {prefixedT('FORM.EDIT')}
                    </Button>
                  )}
                </Styled.EditButtons>
              )}
            </Styled.EditHeader>
            <Styled.Container>
              <Styled.Wrapper>
                <Field
                  name="name"
                  as={TextField}
                  label={prefixedT('FORM.NAME.LABEL')}
                  placeholder={prefixedT('FORM.NAME.PLACEHOLDER')}
                  error={errors.name && t(errors.name)}
                  disabled={!isEditActive}
                  styles={{ label: Styled.Label }}
                  testId="editCompany-name"
                />
                <Field
                  name="address"
                  as={TextField}
                  label={prefixedT('FORM.ADDRESS.LABEL')}
                  placeholder={prefixedT('FORM.ADDRESS.PLACEHOLDER')}
                  error={errors.address && t(errors.address)}
                  disabled={!isEditActive}
                  styles={{ label: Styled.Label }}
                  testId="editCompany-address"
                />
              </Styled.Wrapper>
            </Styled.Container>
          </Styled.EditForm>
        );
      }}
    </Formik>
  );
};

export default EditCompanyForm;
