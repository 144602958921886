import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import * as Styled from './style';

interface WaypointTickboxContainerProps {
  overnight: boolean;
  topSound: boolean;
  correlation: boolean;
  scheduled: boolean;
  markedUp: boolean;
  repairScheduled: boolean;
  repaired: boolean;
}

const WaypointTickboxContainer: FC<WaypointTickboxContainerProps> = ({
  overnight,
  topSound,
  correlation,
  scheduled,
  markedUp,
  repairScheduled,
  repaired,
}) => {
  const renderIcon = (condition: boolean) => (
    condition ? <FaCheckCircle color='green' size={14} /> : <FaTimesCircle color='red' size={14} />
  );

  const { prefixedT } = useCustomTranslation('COMPONENTS.WAYPOINT_TICKBOX_CONTAINER');

  return (
    <Styled.Wrapper>
      <h3>{prefixedT('TITLE')}</h3>
      <Styled.List>
        <Styled.ListItem>{prefixedT('OVERNIGHT_SESSION')} {renderIcon(overnight)}</Styled.ListItem>
        <Styled.ListItem>{prefixedT('TOP_SOUND')} {renderIcon(topSound)}</Styled.ListItem>
        <Styled.ListItem>{prefixedT('CORRELATION')} {renderIcon(correlation)}</Styled.ListItem>
        <Styled.ListItem>{prefixedT('SCHEDULED')} {renderIcon(scheduled)}</Styled.ListItem>
        <Styled.ListItem>{prefixedT('MARKED_UP')} {renderIcon(markedUp)}</Styled.ListItem>
        <Styled.ListItem>{prefixedT('REPAIR_SCHEDULED')} {renderIcon(repairScheduled)}</Styled.ListItem>
        <Styled.ListItem>{prefixedT('REPAIRED')} {renderIcon(repaired)}</Styled.ListItem>
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default WaypointTickboxContainer;
