
import React, { FC } from 'react';
import * as Styled from './style';

interface DescContainerProps {
  title: string;
  description: string | number | undefined | null;
}

const DescContainer: FC<DescContainerProps> = ({ title, description }) => (
  <>
    {(description !== null && description !== undefined) && (
      <Styled.DescContainer>
        <Styled.DescTitle>{title}:</Styled.DescTitle>
        <Styled.DescAnswer>{description}</Styled.DescAnswer>
      </Styled.DescContainer>
    )}
  </>
);

export default DescContainer;
