import { ReportFilters } from '@/components';
import BreadCrumbContainer from '@/components/ReportFilters/components/BreadCrumbContainer/BreadCrumbContainer';
import SearchBar from '@/components/SearchBar/SearchBar';
import { FilterContext } from '@/Providers/FilterProvider';
import React, { FC, useContext } from 'react';
import * as Styled from './style';


const StickyMenu: FC = () => {
  const { setSearch } = useContext(FilterContext);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const placeholder = 'Search for DMA';

  return (
    <Styled.RowWrapper>
      <Styled.Rows>
        <ReportFilters />
        <Styled.SearchBarContainer>
          <SearchBar placeholder={placeholder} handleSearch={handleSearch} />
        </Styled.SearchBarContainer>
      </Styled.Rows>
      <Styled.Rows>
        <BreadCrumbContainer />
      </Styled.Rows>
    </Styled.RowWrapper>
  );
};

export default StickyMenu;
