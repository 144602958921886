import mapbox from 'mapbox-gl';
import { MAP_STYLE } from './config';

const createMap = (mapContainer: string | React.RefObject<HTMLDivElement> | null, lng: number, lat: number, zoom: number) => {
  const container = typeof mapContainer === 'string' ? mapContainer : mapContainer?.current;
  if (container) {
    return new mapbox.Map({
      container,
      style: MAP_STYLE,
      center: [lng, lat],
      zoom,
    });
  }
  return null;
};

export default createMap;

