/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable guard-for-in */

import Button, { ButtonVariant } from '@/components/Button/Button';
import Loader, { LoaderType } from '@/components/Loader/Loader';
import { manageWidget } from '@/containers/ReportBuilder/service';
import useModalStatus from '@/hooks/useModalStatus';
import { WidgetLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useGetDataSourcesQuery,
  useUpdateReportMutation
} from '@/redux/reports/reports.api';
import {
  ReportDataSourceColumn,
  ReportFilterSettings,
  ReportLayout,
  ReportSettingsValue,
  ReportWidget,
  WidgetAction
} from '@/types/report.type';
import { Field, Formik } from 'formik';
import React, { FC, useMemo } from 'react';
import useDragMove from '../useDragMove';
import FilterSettings from './FilterSettings';
import {
  getFilterComponent,
  getFilterData,
  getInitalFiltersValue,
  InitialFilterValues,
  parseSettingsForInitialValues,
  prepareSubmit,
  validateFilterConfig
} from './service';
import * as Styled from './style';

type Props = {
  data: ReportWidget<ReportFilterSettings>;
  id: string | number;
  readonly: boolean;
  structure: ReportLayout;
  reportName: string | undefined;
  reportId: number | undefined;
  onRemoveWidget: (widget: ReportWidget<ReportFilterSettings>, layoutId: string | number) => void;
  onUpdateWidget: (widget: ReportWidget<ReportFilterSettings>, layoutId: string | number) => void;
  handleUpdateRunningQuery?: (query: {
    page: number;
    perPage: number;
    orderBy: string | undefined;
    order: string | undefined;
    type: string;
  }) => void;
};

const initialValues: InitialFilterValues = {
  source: null,
  filters: [],
};

const Filters: FC<Props> = ({
  data,
  id,
  readonly,
  structure,
  reportName,
  reportId,
  onRemoveWidget,
  onUpdateWidget,
  handleUpdateRunningQuery,
}) => {
  const [onUpdateReport] = useUpdateReportMutation();
  const { ref } = useDragMove(data, id, Boolean(readonly));
  const { data: sources } = useGetDataSourcesQuery();
  const filterColumns = useMemo(
    () => sources?.find((source) => source.id === data.settings?.datasource)?.columns,
    [sources, data.settings?.datasource]
  );
  const modal = useModalStatus();
  const { t, prefixedT } = useCustomTranslation('WIDGET_LAYOUT');
  const onOpenSettings = () => {
    modal.show();
  };

  const items = useMemo(
    () => [
      {
        title: prefixedT('SETTINGS'),
        handler: onOpenSettings,
        icon: Styled.GearIcon,
      },
    ],
    []
  );

  return (
    <WidgetLayout
      dropRef={ref}
      name={prefixedT('FILTERS')}
      readonly={readonly}
      items={items}
      onDelete={() => onRemoveWidget(data, id)}
    >
      <Formik
        initialValues={parseSettingsForInitialValues(data.settings) || initialValues}
        onSubmit={(values) => {
          const settings = prepareSubmit(values);
          const _widget = { ...data, settings };
          onUpdateWidget(_widget, id);
          modal.hide();
        }}
        validationSchema={validateFilterConfig}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        <FilterSettings
          open={modal.isOpen}
          sources={sources}
          onClose={modal.hide}
          onUpdateWidget={onUpdateWidget}
          widget={data}
          layoutId={id}
        />
      </Formik>

      {data.settings && (
        <Formik
          onSubmit={async (values) => {
            const query = new URLSearchParams(values as Record<string, string>).toString();
            const newOptions = [];
            for (const key in values) {
              newOptions.push(values[key]);
            }
            const _settings = {
              ...data.settings,
              filter_options: newOptions as (ReportSettingsValue | ReportSettingsValue[])[],
            };
            const newDefaultValues =
              parseSettingsForInitialValues(_settings as ReportFilterSettings) || initialValues;
            const settings = prepareSubmit(newDefaultValues);
            const widget = { ...data, settings };
            const updatedLayout = JSON.parse(JSON.stringify(structure));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            manageWidget(updatedLayout, Number(id), widget, WidgetAction.UPDATE);

            await onUpdateReport({
              id: reportId!,
              name: reportName!,
              structure: updatedLayout,
              layoutId: Number(id),
            }).unwrap();
            // type is used only for re-rendering in case of filters change; it's a workaround for now
            handleUpdateRunningQuery?.({
              page: 1,
              perPage: 10,
              orderBy: undefined,
              order: undefined,
              type: query,
            });
          }}
          initialValues={getInitalFiltersValue(
            data.settings,
            filterColumns as ReportDataSourceColumn[]
          )}
          enableReinitialize
        >
          {({ dirty, setFieldValue }) => (
            <Styled.Form>
              {filterColumns && data.settings ? (
                <>
                  {data.settings.control_field.map((_, index) => {
                    const filterData = getFilterData(
                      data.settings as ReportFilterSettings,
                      filterColumns ,
                      index
                    );
                    const dropDownConfig =
                      filterData.typeId === 1
                        ? {
                          options: filterData.options,
                          multiple: true,
                          onChange: (value: (string | number)[]) =>
                            setFieldValue(`${filterData.column}`, value),
                        }
                        : filterData.typeId === 4
                          ? {
                            onChange: (value: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(`${filterData.column}`, value.target.value),
                          }
                          : {
                            onChange: (value: (string | number)[]) =>
                              setFieldValue(`${filterData.column}`, value),
                          };
                    return (
                      <Field
                        key={index}
                        as={getFilterComponent(filterData.typeId)}
                        name={filterData.column}
                        label={filterData.column}
                        {...dropDownConfig}
                      />
                    );
                  })}
                  <Styled.ButtonWrapper>
                    <Button
                      disabled={!dirty}
                      width="130px"
                      height="42px"
                      variant={ButtonVariant.outline}
                      type="submit"
                    >
                      {t('REPORT_BUILDER.APPLY')}
                    </Button>
                  </Styled.ButtonWrapper>
                </>
              ) : (
                <Loader type={LoaderType.dark} />
              )}
            </Styled.Form>
          )}
        </Formik>
      )}
    </WidgetLayout>
  );
};

export default Filters;
