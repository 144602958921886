import GraphToggle from '@/components/GraphToggle/GraphToggle';
import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useGetCorrelationInformationQuery,
  useGetDropdownQuery
} from '@/redux/reports/reports.api';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WaypointHeadings from '../Waypoint/Components/WaypointHeadings/WaypointHeadings';
import * as Styled from './style';

const CorrelationContainer: FC = () => {
  const { prefixedT } = useCustomTranslation('CORRELATION_INFO_PAGE');
  const location = useLocation();
  const [waypointID, setWaypointID] = useState<string>('');
  const [correlationID, setCorrelationID] = useState<string>('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedCorrelationID = queryParams.get('correlation') as string;
    const waypoint = queryParams.get('waypoint') as string;
    setCorrelationID(updatedCorrelationID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: dropDownData } = useGetDropdownQuery(waypointID);
  const { data: correlationData } = useGetCorrelationInformationQuery(correlationID);

  const totalWidthFactor =
    correlationData?.pipe_segments.reduce(
      (total, segment) => total + segment.len_p1,
      0
    );
  return (
    <div style={{ padding: '40px' }}>
      {dropDownData && (
        <WaypointHeadings
          correlationIds={dropDownData.correlation_ids}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessionIds={dropDownData.session_ids}
          active='Correlation'
        />
      )}

      {correlationData && (
        <>
          <Styled.ListContainer>
            <Styled.ListTitle>{prefixedT('CORRELATION_INFO.TITLE')}</Styled.ListTitle>
            <Styled.List>
              {correlationData?.leak_distance && (
                <li>
                  {prefixedT('CORRELATION_INFO.LEAK_DISTANCE')}: <Styled.ListObject>{correlationData?.leak_distance}</Styled.ListObject>
                </li>
              )}
              {correlationData?.location_point_a && (
                <li>
                  {prefixedT('CORRELATION_INFO.POINT_A')}: <Styled.ListObject>{correlationData?.location_point_a}</Styled.ListObject>
                </li>
              )}
              {correlationData?.location_point_b && (
                <li>
                  {prefixedT('CORRELATION_INFO.POINT_B')}: <Styled.ListObject>{correlationData?.location_point_b}</Styled.ListObject>
                </li>
              )}
              {correlationData?.speed_of_sound && (
                <li>
                  {prefixedT('CORRELATION_INFO.SPEED_OF_SOUND')}: <Styled.ListObject>{correlationData?.speed_of_sound}</Styled.ListObject>
                </li>
              )}
              {correlationData?.timestamp && (
                <li>
                  {prefixedT('CORRELATION_INFO.TIMESTAMP')}: <Styled.ListObject>{correlationData?.timestamp}</Styled.ListObject>
                </li>
              )}
              {correlationData?.total_length && (
                <li>
                  {prefixedT('CORRELATION_INFO.TOTAL_LENGTH')}: <Styled.ListObject>{correlationData?.total_length}</Styled.ListObject>
                </li>
              )}
            </Styled.List>
            <Styled.ListTitleContainer>
              <Styled.ListTitle>{prefixedT('ENGINEER_INFO.TITLE')}</Styled.ListTitle>
              <Styled.List>
                {correlationData?.company && (
                  <li>
                    {prefixedT('ENGINEER_INFO.COMPANY')}: <Styled.ListObject>{correlationData?.company}</Styled.ListObject>
                  </li>
                )}
                {correlationData?.engineer && (
                  <li>
                    {prefixedT('ENGINEER_INFO.ENGINEER')} <Styled.ListObject>{correlationData?.engineer}</Styled.ListObject>
                  </li>
                )}
              </Styled.List>
            </Styled.ListTitleContainer>
          </Styled.ListContainer>

          <Styled.ListTitle>{prefixedT('MATERIAL_INFO.TITLE')}</Styled.ListTitle>
          <Styled.PipeSegments>
            {totalWidthFactor && correlationData.pipe_segments.map((segment, key) => {
              const percentage = ((segment.dia_p1 * segment.len_p1) / totalWidthFactor) * 100;

              return (
                <div key={key} style={{ width: `${percentage}%` }}>
                  <Styled.PipeSegment>
                    <p>{segment.mat_p1}</p>
                  </Styled.PipeSegment>
                  <Styled.PipeSegmentText>
                    <p>{prefixedT('MATERIAL_INFO.DIAMETER')}: {segment.dia_p1}{prefixedT('MATERIAL_INFO.MILLIMETERS')}</p>
                    <p>{prefixedT('MATERIAL_INFO.LENGTH')}: {segment.len_p1}m</p>
                  </Styled.PipeSegmentText>
                </div>
              );
            })}
          </Styled.PipeSegments>

          <GraphToggle location="ble-logger-files" ID={correlationData.session_ids} filename="0" />

          <div>
            <GenerateMap data={correlationData.map_info.data} readonly={true} />
          </div>
        </>
      )}
    </div>
  );
};

export default CorrelationContainer;
