import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const ExceptionTab = styled.div`
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.artyClickRed};
  text-transform: uppercase;
  font-weight: 600;
`;

export const LogoContainer = styled.div`
  border-bottom: 1px solid #ececec;
  padding: 10px 0;
  margin-bottom: 30px;
`;

export const ProgressTab = styled.div`
  margin: 20px;
`;

export const CompanyImage = styled.img`
  max-height: 100px;
  max-width: 200px;
`;

export const RowContainer = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  width: 49%;
  padding-bottom: 20px;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

export const Subheading = styled.p`
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ProjectTitleContainer = styled.div`
  margin: 20px 0 0 20px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.celestialBlue};
`;

export const ProjectTimeline = styled.h5`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
`;

export const RowTwo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RowOne = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ReportContainer = styled.div`
  display: flex;
`;

export const WaterCostContainer = styled.div`
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  border-radius: 10px;
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  width: 100px;
  padding: 25px 10px;
`;

export const WaterCostSessionsContainer = styled.div`
  margin-top: 10px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  padding: 5px 20px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  font-size: 14px;
  text-align: center;
`;

export const WaterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
`;

export const WaterCostTitle1 = styled.p`
  font-size: 10px;
`;

export const WaterCostTitle2 = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const WaterCostTitle3 = styled.p`
  font-size: 10px;
  font-weight: 600;
`;

export const ProjectsBtn = styled.button`
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};           
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  border: 0px;

  :hover {
    background-position: right center; /* change the direction of the change here */
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
    text-decoration: none;
  }
`;

export const ProjectsBtnContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 20px;
`;

export const LeakageValue = styled.p`
  margin-top: 5px;
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lochmara};
  font-weight: bold;
`;

export const LeakageUnit = styled.p`
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lochmara};
  font-weight: bold;
`;
