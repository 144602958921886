import { Button, DatePicker, DropDown, Table, TextField } from '@/components';
import { ButtonVariant } from '@/components/Button/Button';
import { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetProfileQuery } from '@/redux/user/user.api';
import theme from '@/theme';
import { IBranch } from '@/types/branch.type';
import { ICompany } from '@/types/company.type';
import { ILogFilters, LogAction, LogStatus } from '@/types/log.type';
import { UserRoles } from '@/types/user.type';
import { Field, Form, Formik } from 'formik';
import React, { FC, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { ValidationSchema } from './schema';
import * as Styled from './style';

interface IProps {
  onSubmit: (values: IFilterValues) => void;
  filters: ILogFilters;
  companies: ICompany[];
  branches: IBranch[];
}

export interface IFilterValues {
  email: string;
  companyId: number | string;
  action: string;
  status: string;
  branchId: number | string;
  date: string;
}

const statuses = Object.values(LogStatus);
const listOfActions = Object.values(LogAction);

const initialValues: IFilterValues = {
  email: '',
  companyId: '',
  action: '',
  status: '',
  branchId: '',
  date: '',
};

const getFilterControls = (
  dirty: boolean,
  resetForm: () => void,
  values: IFilterValues,
  setValues: (values: IFilterValues) => void,
  submitForm: () => void,
  prefixedT: (prefix: string) => string
) => {
  const isChangedFilters = !shallowEqual(initialValues, values);
  const onRestoreFilters = () => {
    setValues(initialValues);
    submitForm();
  };

  return (
    <Styled.Controls>
      {(dirty || isChangedFilters) && (
        <Button
          onClick={!isChangedFilters ? resetForm : onRestoreFilters}
          variant={ButtonVariant.outline}
          width="120px"
          height="34px"
          color={theme.colors.coral}
          testId="change-log-discard"
        >
          {prefixedT(`FILTERS.${!dirty ? 'ERASE' : 'CLEAR'}`)}
        </Button>
      )}
      <Button
        disabled={!dirty}
        type="submit"
        variant={ButtonVariant.outline}
        width="120px"
        height="34px"
        testId="change-log-filter"
      >
        {prefixedT('FILTERS.SUBMIT')}
      </Button>
    </Styled.Controls>
  );
};

const Filters: FC<IProps> = ({ onSubmit, filters, companies, branches }) => {
  const { data: profile } = useGetProfileQuery();

  const { prefixedT, t } = useCustomTranslation('CHANGE_LOG');

  const isSuperAdmin = useMemo(() => profile?.role?.code === UserRoles.SUPER_ADMIN, [profile]);

  return (
    <Styled.Container>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
        initialValues={{ ...initialValues, ...filters }}
        enableReinitialize
      >
        {({ errors, setFieldValue, values, dirty, resetForm, setValues, submitForm }) => {
          let branchesOptions = [];
          if (isSuperAdmin) {
            const selectedCompany = companies.find(
              (el: ICompany) => Number(el.id) === Number(values.companyId)
            );
            branchesOptions = selectedCompany?.branches || [];
          } else {
            branchesOptions = branches;
          }
          return (
            <Form>
              <Table.Header
                title={prefixedT('FILTERS.TITLE')}
                controls={getFilterControls(
                  dirty,
                  resetForm,
                  values,
                  setValues,
                  submitForm,
                  prefixedT
                )}
              />
              <Styled.Card>
                <Styled.CardWrapper>
                  <Field
                    as={TextField}
                    name="email"
                    label={prefixedT('FILTERS.EMAIL.LABEL')}
                    placeholder={prefixedT('FILTERS.EMAIL.PLACEHOLDER')}
                    error={errors.email && t(errors.email)}
                    styles={{ label: Styled.Label }}
                    testId="change-log-email"
                  />
                  <Field
                    name="action"
                    as={DropDown}
                    label={prefixedT('FILTERS.ACTION.LABEL')}
                    options={listOfActions.map((el: LogAction) => ({
                      value: el,
                      label: prefixedT(`ACTIONS.${el}`),
                    }))}
                    placeholder={prefixedT('FILTERS.ACTION.PLACEHOLDER')}
                    onChange={(option: DropDownOption) => setFieldValue('action', option.value)}
                    styles={{ label: Styled.Label }}
                    testId="change-log-action"
                  />
                  <Field
                    name="status"
                    as={DropDown}
                    label={prefixedT('FILTERS.STATUS.LABEL')}
                    options={statuses.map((el: LogStatus) => ({
                      value: el,
                      label: prefixedT(`STATUSES.${el}`),
                    }))}
                    placeholder={prefixedT('FILTERS.STATUS.PLACEHOLDER')}
                    onChange={(option: DropDownOption) => setFieldValue('status', option.value)}
                    styles={{ label: Styled.Label }}
                    testId="change-log-status"
                  />
                  {profile?.role?.code === UserRoles.SUPER_ADMIN && (
                    <Field
                      name="companyId"
                      as={DropDown}
                      label={prefixedT('FILTERS.COMPANY.LABEL')}
                      options={companies.map((el: ICompany) => ({ value: el.id, label: el.name }))}
                      placeholder={prefixedT('FILTERS.COMPANY.PLACEHOLDER')}
                      onChange={(option: DropDownOption) => setFieldValue('companyId', option.value)}
                      styles={{ label: Styled.Label }}
                      testId="change-log-company"
                    />
                  )}
                  <Field
                    name="branchId"
                    as={DropDown}
                    label={prefixedT('FILTERS.BRANCH.LABEL')}
                    disabled={isSuperAdmin && !values.companyId}
                    options={branchesOptions.map((el: IBranch) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    placeholder={prefixedT('FILTERS.BRANCH.PLACEHOLDER')}
                    onChange={(option: DropDownOption) => setFieldValue('branchId', option.value)}
                    styles={{ label: Styled.Label }}
                    testId="change-log-branch"
                  />
                  <Field
                    as={DatePicker}
                    name="date"
                    label={prefixedT('FILTERS.DATE.LABEL')}
                    placeholder={prefixedT('FILTERS.DATE.PLACEHOLDER')}
                    onChange={(date: Date) => setFieldValue('date', date)}
                    disableFuture
                    styles={{ label: Styled.Label }}
                    testId="change-log-date"
                  />
                </Styled.CardWrapper>
              </Styled.Card>
            </Form>
          );
        }}
      </Formik>
    </Styled.Container>
  );
};

export default Filters;
