import FunnelChartComponent from '@/components/FunnelChart/FunnelChart';
import PercentageProgressbar from '@/components/PercentageProgressbar/PercentageProgressbar';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import ProportionalBar from '@/components/ProportionalBar/ProportionalBar';
import { BuildFunnelChartData } from '@/containers/Dashboard/Components/utils';
import WaterCost from '@/containers/Dashboard/Components/WaterCost/WaterCost';
import { DmaItem } from '@/types/report.type';
import React, { FC } from 'react';
import * as Styled from './style';

interface SummaryProps {
  summary: DmaItem;
}

const Summary: FC<SummaryProps> = ({ summary }) => {
  const funnelData = BuildFunnelChartData(
    summary?.info?.leaks_identified?.value,
    summary?.info?.number_scheduled?.value,
    summary?.info?.number_marked?.value,
    summary?.info?.number_repaired?.value
  );

  return (
    <Styled.Wrapper>
      <div>
        <Styled.DMAHeading>
            DMA: {summary.id}
        </Styled.DMAHeading>
      </div>
      <Styled.RowWrapper>
        <div>
          <Styled.ProgressBarWrapper>
            <ProgressBar
              startDate={summary?.info?.start_date?.value}
              endDate={summary?.info?.end_date?.value}
              progress={summary?.info?.percentage_complete?.value || 0}
            />
          </Styled.ProgressBarWrapper>
          <ProportionalBar
            numbers={[
              summary?.info?.small_leaks?.value,
              summary?.info?.medium_leaks?.value,
              summary?.info?.large_leaks?.value,
            ]}
          />
        </div>
        <div>
          <FunnelChartComponent dimensions={200} data={funnelData} />
        </div>
        <div>
          <PercentageProgressbar
            dimensions={180}
            progress={summary?.info?.total_leakage?.value}
            label={'ML/DAY'}
          />
        </div>
        <div>
          <WaterCost
            cost={summary?.info?.cost_year?.value}
            sessions={summary?.info?.sessions_analised?.value}
          />
        </div>
      </Styled.RowWrapper>
    </Styled.Wrapper>
  );
};

export default Summary;
