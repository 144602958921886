/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Modal } from '@/components/Modal';
import PopupMenu from '@/components/PopupMenu/PopupMenu';
import useModalStatus from '@/hooks/useModalStatus';
import React, { FC, useContext, useMemo, useState } from 'react';
import { TableContext } from '../context';
import { ITableHeader } from '../types';
import ColumnsSettings, { ColumnsSettingsValues } from './ColumnsSettings';
import * as Styled from './style';

const Menu: FC = () => {
  const settingsModal = useModalStatus();
  const table = useContext(TableContext);
  const [open, setOpen] = useState<boolean>(false);

  const onCloseMenu = () => setOpen(false);
  const onOpenMenu = () => setOpen(true);

  const handleColumns = (values: ColumnsSettingsValues) => {
    table.onHeadersChange(
      table.headers.map((header: ITableHeader) => ({
        ...header,
        hidden: !values.display.includes(header.id),
      }))
    );
    settingsModal.hide();
  };

  const items = useMemo(
    () => [
      {
        title: 'Columns settings',
        handler: () => {
          setOpen(false);
          settingsModal.show();
        },
      },
    ],
    [settingsModal]
  );

  const getSettingControls = () => (
    <Styled.SettingButton onClick={onOpenMenu}>
      <Styled.SettingIcon />
    </Styled.SettingButton>
  );

  return (
    <Styled.Container>
      <PopupMenu
        open={open}
        onClose={onCloseMenu}
        control={getSettingControls()}
        items={items}
      />

      <Modal styles={{maxWidth: '300px'}} isOpen={settingsModal.isOpen} onClose={settingsModal.hide} title="Columns Settings">
        <ColumnsSettings onSave={handleColumns} headers={table.headers} />
      </Modal>
    </Styled.Container>
  );
};

export default Menu;
