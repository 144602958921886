import { Theme } from '@/theme';
import styled, { FlattenSimpleInterpolation, ThemeProps } from 'styled-components';

const HORIZONTAL_PADDING = '12px';
const BORDER_WIDTH = '1px';

export const Container = styled.div<{
  error?: string;
}>`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;

  input[type='text'] {
    width: calc(100% - ${HORIZONTAL_PADDING} * 2 - ${BORDER_WIDTH} * 2);
    font-weight: 400;
    font-size: 14px;
    padding: 12px;
    outline: none;
    border: 1px solid ${({ theme, error }: ThemeProps<Theme> & { error?: string }) => theme.colors[error ? 'coral' : 'lightGray']};
    border-radius: 8px;
    cursor: pointer;

    ::placeholder {
      color: ${({ theme }: ThemeProps<Theme>) => theme.colors.gray};
    }
  }

  .react-datepicker__header {
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightBlue};
  }
  .react-datepicker__current-month {
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }
  .react-datepicker__day-name {
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }
  .react-datepicker-popper.datepicker-dialog .react-datepicker__triangle {
    top: 2px;
  }
  .react-datepicker-popper.datepicker-dialog .react-datepicker__triangle::after,
  .react-datepicker-popper.datepicker-dialog .react-datepicker__triangle::before {
    display: none;
  }
  .picker {
    border: none;
  }
`;

export const Label = styled.span<{
  labelStyle?: FlattenSimpleInterpolation;
  error?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme, error }: ThemeProps<Theme> & { error?: boolean }) => theme.colors[error ? 'coral' : 'deepDark']};
  margin-bottom: 8px;
  ${({labelStyle}) => labelStyle || null};
`;

export const Error = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  bottom: 3px;
  left: 0;
`;
