
import { ContentLoader } from '@/components';
import { ITablePaginate } from '@/components/Table/types';
import useModalStatus from '@/hooks/useModalStatus';
import {
  useCreateBranchMutation,
  useDeleteBranchMutation,
  useUpdateBranchMutation
} from '@/redux/branches/branches.api';
import {
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useGetCompanyQuery,
  useGetEmployeesQuery
} from '@/redux/company/company.api';
import { useDeleteUserMutation, useGetProfileQuery } from '@/redux/user/user.api';
import { IBranch } from '@/types/branch.type';
import { EditCompanyValues, ICompany } from '@/types/company.type';
import { IPaginationResponse } from '@/types/rtk.type';
import { IUser } from '@/types/user.type';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddBranchForm } from './AddBranch/AddBranch';
import { CreateUserForm } from './CreateUser/CreateUser';
import EditCompany from './EditCompany';
import EditCompanyCrumb from './EditCompanyCrumb';
import { useCreateEmployee } from './hooks/useCreateEmployee';

interface CompanyQuery {
  data?: ICompany;
  isFetching: boolean;
  refetch: () => void;
  isLoading: boolean;
}

const EditCompanyContainer = () => {
  const navigate = useNavigate();

  const { id: companyId = '' } = useParams();
  const employeeModal = useModalStatus();
  const [employeePage, setEmployeePage] = useState(1);
  const [employeePerPage] = useState(10);
  const { roles, createEmployee, employeeCreateLoading, isRolesLoading } = useCreateEmployee();
  const { data: profile } = useGetProfileQuery();
  const [selectedBranch, setSelectedBranch] = useState<IBranch | undefined>();
  const [selectedEmployee, setSelectedEmployee] = useState<IUser | undefined>();
  const [createBranch, { isLoading: createBranchLoading }] = useCreateBranchMutation();
  const [updateBranch, { isLoading: updateBranchLoading }] = useUpdateBranchMutation();
  const [deleteBranch, { isLoading: deleteBranchLoading }] = useDeleteBranchMutation();
  const addBranchModal = useModalStatus();
  const editBranchModal = useModalStatus();
  const deleteBranchModal = useModalStatus();
  const deleteCompanyModal = useModalStatus();
  const deleteEmployeeModal = useModalStatus();

  const companyQuery: CompanyQuery = useGetCompanyQuery(companyId);
  const [onDeleteCompany, { isLoading: deleteCompanyLoading }] = useDeleteCompanyMutation();
  const [onEmployeeDelete, { isLoading: deleteEmployeeLoading }] = useDeleteUserMutation();
  const employeesQuery = useGetEmployeesQuery({
    companyId,
    page: employeePage,
    perPage: employeePerPage,
  });

  const employees = employeesQuery.data as IPaginationResponse<IUser>;

  const [onCompanyEdit, { isLoading: editCompanyLoading }] = useEditCompanyMutation();

  const handleEditCompany = async (data: EditCompanyValues, callback?: () => void) => {
    await onCompanyEdit(data).unwrap();
    if (callback) {
      callback();
    }
  };

  const onEmployeePageChange = (p: ITablePaginate) => {
    setEmployeePage(p.selected + 1);
  };

  const handleCreateEmployee = async (data: CreateUserForm) => {
    if (employeeCreateLoading) {
      return;
    }

    const user = new FormData();
    user.append('firstName', String(data.firstName || ''));
    user.append('lastName', String(data.lastName || ''));
    user.append('email', String(data.email || ''));
    user.append('companyId', String(data.companyId || ''));
    user.append('branchId', String(data.branchId || ''));
    user.append('roleId', String(data.roleId || ''));
    user.append('redirectUrl', `${window.location.origin}/auth/recovery`);
    await createEmployee(user).unwrap();
    employeesQuery.refetch();
    employeeModal.hide();
  };

  const handleAddBranch = async (data: AddBranchForm) => {
    if (createBranchLoading) {
      return;
    }
    await createBranch({
      name: data.name,
      companyId: String(companyQuery.data?.id || ''),
    }).unwrap();
    companyQuery.refetch();
    addBranchModal.hide();
  };

  const handleEditBranch = async (data: AddBranchForm) => {
    if (!selectedBranch || updateBranchLoading) {
      return;
    }
    editBranchModal.hide();
    await updateBranch({
      id: selectedBranch.id,
      companyId: companyQuery.data?.id || '',
      name: data.name,
    }).unwrap();
    companyQuery.refetch();
  };

  const handleDeleteBranch = async () => {
    if (!selectedBranch || deleteBranchLoading || !companyQuery.data) {
      return;
    }
    deleteBranchModal.hide();
    await deleteBranch({
      id: selectedBranch.id,
      companyId: companyQuery.data.id,
    }).unwrap();
    companyQuery.refetch();
  };

  const handleDeleteCompany = async () => {
    if (deleteCompanyLoading || !companyQuery.data) {
      return;
    }
    await onDeleteCompany(companyQuery.data.id).unwrap();
    navigate('/app/companies');
  };

  const handleDeleteEmployee = async () => {
    if (!selectedEmployee || deleteEmployeeLoading) {
      return;
    }
    deleteEmployeeModal.hide();
    await onEmployeeDelete(selectedEmployee.id).unwrap();
    employeesQuery.refetch();
  };

  const handleShowEditBranch = (branch: IBranch) => {
    editBranchModal.show();
    setSelectedBranch(branch);
  };

  const handleShowDeleteBranch = (branch: IBranch) => {
    deleteBranchModal.show();
    setSelectedBranch(branch);
  };

  const handleShowDeleteEmployee = (employee: IUser) => {
    deleteEmployeeModal.show();
    setSelectedEmployee(employee);
  };

  return (
    <>
      <ContentLoader
        active={
          (employeesQuery.data && employeesQuery.isFetching) ||
          (companyQuery.data && companyQuery.isFetching) ||
          createBranchLoading ||
          updateBranchLoading ||
          editCompanyLoading ||
          employeeCreateLoading
        }
      />
      <EditCompany
        company={companyQuery.data}
        selectedBranch={selectedBranch}
        roles={roles}
        showEditBranch={handleShowEditBranch}
        showDeleteBranch={handleShowDeleteBranch}
        showDeleteEmployee={handleShowDeleteEmployee}
        onEmployeeCreate={handleCreateEmployee}
        onCompanyEdit={handleEditCompany}
        employeeModal={employeeModal}
        employeePage={employeePage}
        onEmployeePageChange={onEmployeePageChange}
        employees={employees?.data || []}
        employeesCount={employees?.count || 0}
        pages={Math.ceil((employees?.count || 1) / employeePerPage)}
        branchAddModal={addBranchModal}
        branchEditModal={editBranchModal}
        branchDeleteModal={deleteBranchModal}
        deleteCompanyModal={deleteCompanyModal}
        deleteEmployeeModal={deleteEmployeeModal}
        onCompanyDelete={handleDeleteCompany}
        onEmployeeDelete={handleDeleteEmployee}
        onBranchAdd={handleAddBranch}
        onBranchEdit={handleEditBranch}
        onBranchDelete={handleDeleteBranch}
        onEmployeeEdit={(user: IUser) => navigate(`/app/users/${user.id}`)}
        profile={profile as IUser}
        loading={companyQuery.isLoading || employeesQuery.isLoading || isRolesLoading}
      />
    </>
  );
};

EditCompanyContainer.Crumb = EditCompanyCrumb;

export default EditCompanyContainer;
