import { Leaks } from '@/types/sweep.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const sweepApi = createApi({
  reducerPath: 'sweepQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Leaks'],
  endpoints: (builder) => ({
    getLeaks: builder.query<Leaks, void>({
      query: () => '/sweep/leaks',
      providesTags: ['Leaks'],
    }),
  }),
});

export const { useGetLeaksQuery } = sweepApi;
