import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const RowOne = styled.div`
  padding: 20px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  padding: 10px 20px;
  align-items: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

export const DropdownWrapper = styled.div`
  width: 200px;
`;

export const DescContainer = styled.div`
padding: 20px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

export const DescWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const DescTitle = styled.p``;
export const DescAnswer = styled.p``;

export const Title = styled.h2`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lochmara};
`;

export const CompanyImage = styled.img`
`;

export const Wrapper = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;
