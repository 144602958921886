import { FilterBreadcrumb } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import * as Styled from './style';

const BreadCrumbContainer: FC = () => {
  const { prefixedT } = useCustomTranslation('BREADCRUMB.FILTERS');
  const {
    dateFilters,
    projectActiveValue,
    setProjectActiveValue,
    dmaActiveValue,
    setDmaActiveValue,
    engineerActiveValue,
    setEngineerActiveValue,
    dayDropdownActiveValue,
  } = useContext(DeliveryPerformanceContext);

  const removeBreadCrumb = (value: string) => {
    if (value === 'project') {
      setProjectActiveValue({
        name: '',
        value: 0,
      });
    }
    if (value === 'dma') {
      setDmaActiveValue({
        name: '',
        value: 0,
      });
    }
    if (value === 'engineer') {
      setEngineerActiveValue({
        name: '',
        value: 0,
      });
    }
  };

  return (
    <Styled.Container>
      <FilterBreadcrumb
        title={prefixedT('DATE_FROM') + ': '}
        value={moment(dateFilters.dateFrom).format('YYYY-MM-DD')}
      />
      <FilterBreadcrumb
        title={prefixedT('DATE_TO') + ': '}
        value={moment(dateFilters.dateTo).format('YYYY-MM-DD')}
      />
      <FilterBreadcrumb
        title={prefixedT('DAY') + ': '}
        value={dayDropdownActiveValue}
      />
      {projectActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={prefixedT('PROJECT') + ': '}
          icon={true}
          value={projectActiveValue.name}
          onClick={() => removeBreadCrumb('project')}
        />
      )}
      {dmaActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={'DMA' + ': '}
          icon={true}
          value={dmaActiveValue.name.toString()}
          onClick={() => removeBreadCrumb('dma')}
        />
      )}
      {engineerActiveValue.value !== 0 && (
        <FilterBreadcrumb
          title={prefixedT('ENGINEER') + ': '}
          icon={true}
          value={engineerActiveValue.name.toString()}
          onClick={() => removeBreadCrumb('engineer')}
        />
      )}
    </Styled.Container>
  );
};

export default BreadCrumbContainer;
