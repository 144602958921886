import { Styles } from '@/types/styles.type';
import { AnimatePresence } from 'framer-motion';
import React, { FC, PropsWithChildren, RefObject } from 'react';
import { createPortal } from 'react-dom';
import * as Styled from './style';

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  styles?: Styles;
  modalRef?: RefObject<HTMLDivElement>;
}

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  isOpen,
  title,
  onClose,
  styles,
  modalRef = null,
}) => createPortal(
  <AnimatePresence>
    {isOpen ? (
      <Styled.ModalContainer ref={modalRef} data-testid="modal">
        <Styled.ModalBackground {...animationProps} onClick={onClose} />
        <Styled.Content
          {...animationProps}
          onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
          styles={styles}
        >
          <Styled.ModalHeader hasTitle={!!title}>
            {Boolean(title) && <Styled.ModalTitle>{title}</Styled.ModalTitle>}
            {onClose && <Styled.CloseButton onClick={onClose} data-testid="close-button" />}
          </Styled.ModalHeader>
          <Styled.ModalBody>{children}</Styled.ModalBody>
        </Styled.Content>
      </Styled.ModalContainer>
    ) : null}
  </AnimatePresence>,
  document.body
);

export default Modal;
