import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  background-color: #f7f7f7;
  padding: 20px;
`;

export const Title = styled.h3`
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  padding: 10px;
`;
