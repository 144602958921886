import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const InfoWrapper = styled.div`
    position: relative;
    border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.celestialBlue};
    border-radius: 5px;
    padding: 20px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
    flex: 1;
    height: fit-content;
`;

export const List = styled.ul`
    list-style-type: none;
    font-weight: bold;
    line-height: 35px;
`;

export const TickBoxAndHistoryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 30px;
`;

export const ListObject = styled.span`
    font-weight: normal;
`;

export const InfoAndMapWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 20px;
`;

export const MapWrapper = styled.div`
    flex: 1;
`;

export const InfoWrapperTitle = styled.div`
    margin-bottom: 20px;
`;
