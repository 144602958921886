import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { ReportDataSource } from '@/types/report.type';
import { Field, useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';
import {
  Filter,
  getFilterComponent,
  getFilterValueBasedOnValue,
  InitialFilterValues
} from './service';
import * as Styled from './style';

type Props = {
  filter: Filter;
  source: ReportDataSource | undefined;
  index: number;
  blockRef: React.RefObject<HTMLDivElement>;
};

const optionsMapper = (el: { name: string; id: number }) => ({ label: el.name, value: el.id });

const FilterItem: FC<Props> = ({ source, filter, index, blockRef }) => {
  const { setFieldValue, values, errors } = useFormikContext<InitialFilterValues>();
  const { t } = useCustomTranslation('');

  const onChange = (name: string, value: string | number) => {
    if (['columnId'].includes(name)) {
      setFieldValue(`filters[${index}].typeId`, null);
    }
    if (['columnId', 'typeId'].includes(name)) {
      setFieldValue(`filters[${index}].value`, null);
    }
    setFieldValue(`filters[${index}].${name}`, value);
  };

  const onDelete = (id: string) => {
    const _filters = values.filters.filter((item) => item.id !== id);
    setFieldValue('filters', _filters, true);
  };

  const column = useMemo(() => {
    const columnData = source?.columns?.find((el) => el.id === filter.columnId);
    if (columnData) {
      return columnData;
    }
    return null;
  }, [source, filter]);

  const targetComponent = useMemo(() => {
    const type = filter?.typeId;
    const props = {
      name: 'value',
      label: 'Value',
      placeholder: 'Select value',
    };
    if (type) {
      const component = getFilterComponent(type);
      const noPortalTypes = [3, 4];
      const conditionPortal = !noPortalTypes.find((num) => num === type);
      const config = conditionPortal ? { blockRef } : {};
      return {
        as: component,
        onChange: (v: React.ChangeEvent<HTMLInputElement> | string | number) =>
          onChange('value', type === 4 ? (v as React.ChangeEvent<HTMLInputElement>).target.value  : v as string | number),
        multiple: true,
        options: column?.filters.map((el) => ({ label: el.option, value: el.id })) || [],
        value: getFilterValueBasedOnValue(type, filter.value),
        ...config,
        ...props,
      };
    }
    return null;
  }, [filter, column]);

  return (
    <Styled.FilterItemContainer error={Boolean(errors.filters && errors.filters[index])}>
      <Styled.FilterItem>
        <Field
          as={DropDown}
          options={source?.columns?.map(optionsMapper) || []}
          label={t('FILTERS.FORM.COLUMN.LABEL')}
          placeholder={t('FILTERS.FORM.COLUMN.PLACEHOLDER')}
          onChange={(option: DropDownOption) => onChange('columnId', option.value)}
          value={filter.columnId}
          countOptions={4}
          blockRef={blockRef}
        />
        <Field
          as={DropDown}
          options={column?.types.map(optionsMapper) || []}
          label={t('FILTERS.FORM.TYPE.LABEL')}
          placeholder={t('FILTERS.FORM.TYPE.PLACEHOLDER')}
          onChange={(option: DropDownOption) => onChange('typeId', option.value)}
          value={filter.typeId}
          countOptions={4}
          blockRef={blockRef}
        />
        {targetComponent && <Field {...targetComponent} />}
      </Styled.FilterItem>
      <Styled.FilterDeleteContainer onClick={() => onDelete(filter.id)}>
        <Styled.FilterDelete>
          <Styled.DeleteLight />
        </Styled.FilterDelete>
      </Styled.FilterDeleteContainer>
    </Styled.FilterItemContainer>
  );
};

export default FilterItem;
