import { Map } from 'mapbox-gl';
import { MapElement } from '../../utils/types';
import { handleClickWithTooltipCheck, polygonLayers, setCursorPointer } from './utils';

const drawPolygon = (
  map: Map,
  item: MapElement,
  onClickModalOpen: (id: number) => void,
  modal?: boolean | undefined
): void => {
  if (map.getSource(item.id)) {
    return;
  }

  polygonLayers(map, item);
  setCursorPointer(map, item);
  handleClickWithTooltipCheck(map, item, onClickModalOpen, modal);
};

export default drawPolygon;
