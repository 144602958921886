import { parseDate } from '@/containers/Dashboard/Components/TimeRangeSelect/TimeRangeSelect';
import { sub } from 'date-fns';
import React, { createContext, useState, useContext } from 'react';

export enum TimeRangeOptions {
  Today = 'Today',
  LastWeek = 'Last 7 Days',
  LastMonth = 'Last 30 Days',
  LastYear = 'Last Year',
}

export interface TimeRange {
  startDate: string;
  endDate: string;
  defaultTimeRange: TimeRangeOptions;
}

interface DashboardContextType {
  timeRange: TimeRange;
  setTimeRange: React.Dispatch<React.SetStateAction<TimeRange>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

interface DashboardProviderProps {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
  const currentDate = new Date();
  const [timeRange, setTimeRange] = useState<TimeRange>({
    startDate: parseDate(currentDate),
    endDate: parseDate(sub(currentDate, { months: 1 })),
    defaultTimeRange: TimeRangeOptions.LastMonth,
  });
  const [search, setSearch] = useState<string>('');

  return (
    <DashboardContext.Provider value={{ timeRange, setTimeRange, search, setSearch }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }
  return context;
};
