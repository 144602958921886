
import { ContentLoader } from '@/components';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import useDebounce from '@/hooks/useDebounce';
import useModalStatus from '@/hooks/useModalStatus';
import { useDeleteUserMutation, useGetUsersQuery } from '@/redux/user/user.api';
import { ISort } from '@/types/rtk.type';
import { IUser } from '@/types/user.type';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateUserForm } from './CreateUser/CreateUser';
import { useCreateUserData } from './hooks/useCreateUserData';
import Users from './Users';
import UsersCrumb from './UsersCrumb';

const UsersContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const pageParam = searchParams.get('page');
  const perPageParam = searchParams.get('perPage');
  const navigate = useNavigate();

  const deleteUserModal = useModalStatus();
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [isAddUserModalOpen, setAddUserModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(Number(pageParam) || 1);
  const [perPage] = useState<number>(Number(perPageParam) || 10);
  const [sort, setSort] = useState<ISort>({ sortedBy: 'id', sortedOrder: TableSortOrders.asc });

  const {
    data: users,
    isLoading,
    isFetching,
  } = useGetUsersQuery(
    { page, perPage, sortedBy: sort.sortedBy, sortedOrder: sort.sortedOrder },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation();
  const { fetchCreateUserData, createUser, companies, roles, userCreateLoading, isCompanyLoading } =
    useCreateUserData();

  useEffect(() => {
    setSearchParams({ page: String(page) });
  }, [page]);

  const handleAddUserModalShow = async () => {
    await fetchCreateUserData();
    setAddUserModalOpen(true);
  };

  const handleAddUserModalHide = () => {
    setAddUserModalOpen(false);
  };

  const onUserEdit = (user: IUser) => {
    navigate(`/app/users/${user.id}`);
  };

  const deleteConfirmation = (user: IUser) => {
    setSelectedUser(user);
    deleteUserModal.show();
  };

  const handleDeleteUser = useDebounce<void, void>(async () => {
    if (deleteLoading || !selectedUser) {
      return;
    }
    deleteUserModal.hide();
    await deleteUser(selectedUser.id).unwrap();
  }, 300);

  const handleCreateUser = async (data: CreateUserForm) => {
    const user = new FormData();
    user.append('companyId', String(data.companyId || ''));
    user.append('branchId', String(data.branchId || ''));
    user.append('roleId', String(data.roleId || ''));
    user.append('redirectUrl', `${window.location.origin}/auth/recovery`);

    if (data.isBulk) {
      user.append('file', data.bulk as Blob);
    } else {
      user.append('firstName', String(data.firstName || ''));
      user.append('lastName', String(data.lastName || ''));
      user.append('email', String(data.email || ''));
    }
    await createUser(user).unwrap();
    setAddUserModalOpen(false);
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const setSorting = (orderBy: string, order: string) => {
    setSort({ sortedBy: orderBy, sortedOrder: order as TableSortOrders });
  };

  return (
    <>
      <ContentLoader active={(users && isFetching) || isCompanyLoading} />
      <Users
        isAddUserModalOpen={isAddUserModalOpen}
        onAddUserModalHide={handleAddUserModalHide}
        onAddUserModalShow={handleAddUserModalShow}
        onCreateUser={handleCreateUser}
        deleteConfirmation={deleteConfirmation}
        deleteUserModal={deleteUserModal}
        onDeleteUser={handleDeleteUser}
        userCreateLoading={userCreateLoading}
        companies={companies}
        roles={roles}
        users={users?.data || []}
        totalCount={users?.count || 0}
        pages={Math.ceil((users?.count || 1) / perPage)}
        onPageChange={onPageChange}
        page={page}
        onUserEdit={onUserEdit}
        loading={isLoading}
        setSorting={setSorting}
        order={sort.sortedOrder as TableSortOrders}
        orderBy={sort.sortedBy}
      />
    </>
  );
};

UsersContainer.Crumb = UsersCrumb;

export default UsersContainer;
