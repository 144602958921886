import { Button, Table } from '@/components';
import {
  ITableHeader,
  ITablePaginate,
  TableActionTypes,
  TableCellAlignment,
  TableSortOrders
} from '@/components/Table/types';
import { ModalStatus } from '@/hooks/useModalStatus';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { ICompany } from '@/types/company.type';
import { getRowIndex } from '@/utils/table';
import React, { FC } from 'react';
import * as Styled from '../style';

interface IProps {
  companies: ICompany[];
  page: number;
  pages: number;
  onPageChange: (p: ITablePaginate) => void;
  onEdit: (company: ICompany) => void;
  onDelete: (company: ICompany) => void;
  totalCount: number;
  addCompanyModal: ModalStatus;
  setSorting: (orderBy: string, order: string) => void;
  order: TableSortOrders;
  orderBy: string;
}

const headers: ITableHeader[] = [
  { id: 'index', title: 'INDEX', sortable: false, align: TableCellAlignment.left },
  { id: 'company_name', title: 'NAME', sortable: true, align: TableCellAlignment.left },
  {
    id: 'address',
    title: 'ADDRESS',
    sortable: true,
    align: TableCellAlignment.center,
  },
  {
    id: 'branches',
    title: 'BRANCHES',
    sortable: false,
    align: TableCellAlignment.center,
  },
  {
    id: 'actions',
    title: 'ACTIONS',
    sortable: false,
    align: TableCellAlignment.right,
  },
];

const CompanyTable: FC<IProps> = ({
  companies,
  onPageChange,
  onEdit,
  onDelete,
  page,
  pages,
  totalCount,
  addCompanyModal,
  setSorting,
  order,
  orderBy,
}) => {
  const { t, prefixedT } = useCustomTranslation('COMPANIES_PAGE.TABLE');

  const getRowStructure = (row: ICompany, index: number) => (
    <Table.Row key={row.id}>
      <Table.Cell align={TableCellAlignment.left} headerId="index">
        {getRowIndex(page, index)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.left} headerId="name">
        <Styled.Avatar />
        <Styled.CellText>{row.name}</Styled.CellText>
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="address">
        {row.address}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="branches">
        {row.branches.length}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.right} headerId="actions">
        <Table.Action onClick={() => onEdit(row)} type={TableActionTypes.edit} />
        <Table.Action onClick={() => onDelete(row)} type={TableActionTypes.delete} />
      </Table.Cell>
    </Table.Row>
  );

  const getHeaderStructure = (header: ITableHeader) => prefixedT(header.title);

  const headerControl = (
    <Button width="100%" height="100%" testId="add-company-button" onClick={addCompanyModal.show}>
      <Styled.TableHeaderButton>
        <Styled.PlusIcon />
        {t('COMPANIES_PAGE.ADD_COMPANY.BUTTON')}
      </Styled.TableHeaderButton>
    </Button>
  );

  return (
    <>
      <Table.Header
        title={
          !totalCount
            ? t('COMPANIES_PAGE.TITLE.NO_COMPANIES')
            : t('COMPANIES_PAGE.TITLE.TOTAL_COUNT', { count: totalCount })
        }
        subtitle={t('COMPANIES_PAGE.SUBTITLE')}
        controls={headerControl}
      />
      {Boolean(totalCount) && (
        <>
          <Table
            data={companies}
            order={order}
            orderBy={orderBy as keyof ICompany}
            onSort={setSorting}
            headers={headers}
          >
            <Table.Head getHeaderStructure={getHeaderStructure} />
            <Table.Body getRowStructure={getRowStructure} striped />
          </Table>
          <Table.Pagination pages={pages} onChange={onPageChange} initialPage={page} />
        </>
      )}
    </>
  );
};

export default CompanyTable;
