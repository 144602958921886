export enum ShapeType {
  LINE = 'line',
  POLYGON = 'polygon',
  POINT = 'point',
}

export type PointTooltipType = {
  fft_graph: string;
  Address: string;
  Engineer: string;
  Waypoint_name: string | null;
  Waypoint_id: string | null;
};

export type PolygonTooltipType = {
  DMA_ID: number;
  DMA_Name: string;
};

export type LineTooltipType = {
  pipe_id: number;
  material: string;
};

export type MapElement = {
  id: string;
  type: ShapeType;
  geometry: number[][];
  tooltip: object;
  color?: string;
};
