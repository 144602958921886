import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const ImageContainer = styled.div`
`;

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Image = styled.img`
  width: 400px;
  max-height: 300px;
`;

export const btnContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 20px;
`;

export const btnContainerTitle = styled.h3`
color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px 10px 10px 10px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const Button = styled.button`
  cursor: pointer;
  border: 1px solid rgb(0, 124, 187);
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  padding: 10px;
  min-width: 150px;

  :hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;
