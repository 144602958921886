import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const WaterCostContainer = styled.div`
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  border-radius: 10px;
  text-align: center;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  width: 100px;
  padding: 25px 10px;
`;

export const WaterCostSessionsContainer = styled.div`
  margin-top: 10px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  padding: 5px 20px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  font-size: 14px;
  text-align: center;
`;

export const WaterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
`;

export const WaterCostTitle1 = styled.p`
  font-size: 10px;
`;

export const WaterCostTitle2 = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const WaterCostTitle3 = styled.p`
  font-size: 10px;
  font-weight: 600;
`;
