/* eslint-disable @typescript-eslint/no-shadow */

import { ContentLoader } from '@/components';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import useDebounce from '@/hooks/useDebounce';
import useModalStatus from '@/hooks/useModalStatus';
import {
  useAddCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery
} from '@/redux/company/company.api';
import { AddCompanyValues, ICompany } from '@/types/company.type';
import { IPaginationResponse } from '@/types/rtk.type';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Companies from './Companies';
import CompaniesCrumb from './CompaniesCrumb';

const CompaniesContainer = () => {
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const [searchParams, setSearchParams] = useSearchParams({});
  const pageParam = searchParams.get('page');
  const perPageParam = searchParams.get('perPage');

  const [page, setPage] = useState<number>(Number(pageParam) || 1);
  const [perPage] = useState<number>(Number(perPageParam) || 10);
  const addCompanyModal = useModalStatus();
  const deleteCompanyModal = useModalStatus();
  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.asc);
  const [orderBy, setOrderBy] = useState<string>('id');

  const companiesQuery = useGetCompaniesQuery(
    { page, perPage, sortedBy: orderBy, sortedOrder: order },
    { refetchOnMountOrArgChange: true }
  );
  const companies = companiesQuery.data as IPaginationResponse<ICompany>;
  const [onAddCompany, { isLoading: addLoading }] = useAddCompanyMutation();
  const [onDeleteCompany, { isLoading: deleteLoading }] = useDeleteCompanyMutation();

  useEffect(() => {
    setSearchParams({ page: String(page) });
  }, [page]);

  const addCompany = useDebounce(async (data: AddCompanyValues) => {
    if (!addLoading) {
      await onAddCompany(data).unwrap();
      addCompanyModal.hide();
    }
  }, 300);

  const deleteCompany = useDebounce<void, void>(async () => {
    if (deleteLoading || !selectedCompany) {
      return;
    }
    deleteCompanyModal.hide();
    await onDeleteCompany(selectedCompany.id).unwrap();
  }, 300);

  const deleteConfirmation = (company: ICompany) => {
    setSelectedCompany(company);
    deleteCompanyModal.show();
  };

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const onCompanyEdit = (company: ICompany) => {
    navigate(`/app/companies/${company.id}`);
  };

  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  return (
    <div style={{ padding: '40px' }}>
      <ContentLoader active={companiesQuery.data && companiesQuery.isFetching} />
      <Companies
        addCompany={addCompany}
        deleteCompany={deleteCompany}
        deleteConfirmation={deleteConfirmation}
        companies={companies?.data || []}
        addCompanyModal={addCompanyModal}
        deleteCompanyModal={deleteCompanyModal}
        pages={Math.ceil((companies?.count || 1) / perPage)}
        onCompanyEdit={onCompanyEdit}
        onPageChange={onPageChange}
        page={page}
        totalCount={companies?.count || 0}
        isAddCompanyLoading={addLoading}
        loading={companiesQuery.isLoading}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </div>
  );
};

CompaniesContainer.Crumb = CompaniesCrumb;

export default CompaniesContainer;
