import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import * as Styled from './style';

interface ProgressBarProps {
  startDate: string;
  endDate: string;
  progress: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ startDate, endDate, progress }) => {
  const {t} = useCustomTranslation('');
  return (
    <>
      <Styled.LabelWrapper>
        <p>{t('COMPONENTS.PROGRESS_BAR.START_DATE')} {startDate}</p>
        <p>{t('COMPONENTS.PROGRESS_BAR.END_DATE')} {endDate}</p>
      </Styled.LabelWrapper>
      <Styled.Container>
        <Styled.DotContainer>
          <Styled.Dot completion={progress} />
        </Styled.DotContainer>
      </Styled.Container>
    </>
  );
};

export default ProgressBar;
