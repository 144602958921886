import FunnelChartComponent from '@/components/FunnelChart/FunnelChart';
import ProportionalBar from '@/components/ProportionalBar/ProportionalBar';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IUser } from '@/types/user.type';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IDashboardInfo } from '../../DashboardContainer';
import Exceptions from '../Exceptions/Exceptions';
import { BuildFunnelChartData } from '../utils';
import WaterCost from '../WaterCost/WaterCost';
import * as Styled from './style';

type UserInfoProps = {
  user: IUser;
};

type DashboardInfoProps = {
  dashboard: IDashboardInfo;
};

type DashboardProps = Partial<UserInfoProps> & Partial<DashboardInfoProps>;

const PartnerDashboard: FC<DashboardProps> = ({ dashboard }) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE');
  const funnelData = BuildFunnelChartData(
    dashboard?.summary?.leaks_identified?.value,
    dashboard?.summary?.number_scheduled?.value,
    dashboard?.summary?.number_marked?.value,
    dashboard?.summary?.number_repaired?.value
  );

  return (
    <>
      {dashboard && (
        <Styled.RowContainer>
          <Styled.RowOne>
            <Styled.ProjectTitleContainer>
              <Styled.LogoContainer>
                <Link to={`/app/dashboard?company=${dashboard?.id}`}>
                  <Styled.CompanyImage
                    alt={`${dashboard?.name} Logo`}
                    src={`https://fidodevstorage.blob.core.windows.net/fidologos/${dashboard?.id}.png`}
                  />
                </Link>
              </Styled.LogoContainer>
              <p>{dashboard?.name}</p>
            </Styled.ProjectTitleContainer>
            <div>
              <Styled.ProgressTab>
                <ProportionalBar
                  numbers={[
                    dashboard?.summary?.small_leaks?.value || 0,
                    dashboard?.summary?.medium_leaks?.value || 0,
                    dashboard?.summary?.large_leaks?.value || 0,
                  ]}
                />
              </Styled.ProgressTab>
            </div>
          </Styled.RowOne>
          <Styled.RowTwo>
            <div>
              <div>
                <Styled.Subheading>
                  {prefixedT('SUMMARY.FIDO_FUNNEL')}
                </Styled.Subheading>
                <FunnelChartComponent dimensions={150} data={funnelData} />
              </div>
            </div>
            <WaterCost cost={dashboard?.summary?.cost_year?.value || 0} sessions={dashboard?.summary?.sessions_analised?.value || 0} />
            <div>
              <Styled.Subheading>{prefixedT('VOLUMETRICS.SUBTITLE')}</Styled.Subheading>
              <Styled.LeakageValue>{dashboard?.summary?.total_leakage?.value}</Styled.LeakageValue>
              <Styled.LeakageUnit>ML/DAY</Styled.LeakageUnit>
            </div>
            <div>
              <Styled.ReportContainer>
                <div>
                  <Exceptions value={dashboard?.summary?.issues?.value} />
                </div>
              </Styled.ReportContainer>
            </div>
          </Styled.RowTwo>
          <Styled.ProjectsBtnContainer>
            <Link to={`/app/dashboard?company=${dashboard?.id}`}>
              <Styled.ProjectsBtn>{prefixedT('VIEW_ALL_BTN')}</Styled.ProjectsBtn>
            </Link>
          </Styled.ProjectsBtnContainer>
        </Styled.RowContainer>
      )}
    </>
  );
};

export default withPageLoader(PartnerDashboard);
