import mapboxgl from 'mapbox-gl';
import getBoundingBox from './getBoundingBox';
import { MapElement } from './types';

const adjustMapViewportToData = (map: React.RefObject<mapboxgl.Map>, sourceData: unknown) => {
  if ((sourceData as MapElement[])?.length > 0) {
    const boundingBox = new mapboxgl.LngLatBounds(getBoundingBox(sourceData as MapElement[]));
    if (map.current) {
      map.current.fitBounds(boundingBox, { padding: 20 });
    }
  }
};

export default adjustMapViewportToData;
