import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetListQuery } from '@/redux/portalCompanies/portalCompanies.api';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Styled from './style';

const CompanySelect: FC = () => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.COMPANY_SELECT');
  const navigate = useNavigate();
  const { data, isError } = useGetListQuery();

  if (isError) {
    return (
      <Styled.ChooseCompanyTitle>{prefixedT('ERROR')}</Styled.ChooseCompanyTitle>
    );
  }

  const partnerCompanyPairs = data?.reduce((acc, partner) => {
    if (partner?.branches?.length) {
      partner.branches.forEach(branch => {
        if (branch.name && branch.id) {
          acc.push({ partner: partner.name || '', company: branch.name, companyId: branch.id });
        }
      });
    }
    return acc;
  }, [] as { partner: string; company: string; companyId: number }[]);

  const changeBranch = (id: number) => {
    navigate(`/app/project-status?project=${id}`);
  };

  if (partnerCompanyPairs) {
    partnerCompanyPairs.sort((a, b) => (a.company || '').localeCompare(b.company || ''));
  }

  return (
    <>
      {partnerCompanyPairs ? (
        <Styled.ChooseCompanyContainer>
          <Styled.ChooseCompanyTitle>{prefixedT('CHOOSE_COMPANY')}</Styled.ChooseCompanyTitle>
          <Styled.TableWrapper>
            <Styled.TableTitleWrapper>
              <Styled.TableTitleContainer><Styled.TableTitle>{prefixedT('PARTNER')}</Styled.TableTitle></Styled.TableTitleContainer>
              <Styled.TableTitleContainer><Styled.TableTitle>{prefixedT('COMPANY')}</Styled.TableTitle></Styled.TableTitleContainer>
            </Styled.TableTitleWrapper>
            {partnerCompanyPairs?.map((pair, key) => (
              <Styled.PairContainer key={key} onClick={() => changeBranch(pair.companyId)}>
                <Styled.ListItemContainer>{pair.partner}</Styled.ListItemContainer>
                <Styled.ListItemContainer onClick={() => changeBranch(pair.companyId)}>{pair.company}</Styled.ListItemContainer>
              </Styled.PairContainer>
            ))}
          </Styled.TableWrapper>
        </Styled.ChooseCompanyContainer>
      ) : (
        <Styled.ChooseCompanyTitle>{prefixedT('NO_DATA')}</Styled.ChooseCompanyTitle>
      )}
    </>
  );
};

export default CompanySelect;
