import styled from 'styled-components';

export const DescContainer = styled.div`
  padding: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

export const DescWrapper = styled.div`
  padding: 20px 40px 40px;
`;

export const DeliveryPerformanceContainer = styled.div`
  background: #fff;
  margin: 15px 0;
  padding: 40px 0;
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 30px;
`;

export const OptionValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

export const NameContainer = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  color: #01699d;
  font-weight: bold;
`;

export const NameWrapper = styled.div`
  padding: 15px;
  font-size: 18px;
`;

export const Wrapper = styled.div``;

export const OptionContainers = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

export const MainContainer = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
