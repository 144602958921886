import { IInitialUserState } from '@/types/user.type';
import { createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const initialState: IInitialUserState = {
  isAuthorized: !!cookies.get('fido-payload'),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthorized = true;
    },
    logout: (state) => {
      state.isAuthorized = false;
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
