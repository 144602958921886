import { DateFilter } from '@/Providers/DeliveryPerformanceProvider';
import { getDeliveryPerformanceQuery } from '@/redux/reports/reports.api';
import moment from 'moment';

type Engineer = {
  id: string;
  name: string;
};

type Dma = {
  id: string;
  name: string;
};

type Project = {
  project_id: number;
  project_name: string;
  engineers: Engineer[];
  dmas: Dma[];
};

type DeliveryPerformanceDropdownType = Project[];

export const extractEngineerIds = (data: DeliveryPerformanceDropdownType | undefined): string[] => {
  const engineerIdsSet = new Set<string>();

  if (data) {
    data.forEach((project) => {
      project.engineers.forEach((engineer) => {
        engineerIdsSet.add(engineer.id);
      });
    });
  }

  const uniqueEngineerIds = Array.from(engineerIdsSet);
  return uniqueEngineerIds;
};

export const extractData = (data: DeliveryPerformanceDropdownType | undefined) => {
  if (!data) {
    return {
      dropdownProjects: [],
      dropdownEngineers: [],
      dropdownDmas: [],
    };
  }
  const projects: { id: number; name: string }[] = [];
  const engineersSet = new Set<string>();
  const dmasSet = new Set<string>();

  data.forEach((project) => {
    projects.push({
      id: project.project_id,
      name: project.project_name,
    });

    project.engineers.forEach((engineer) => {
      const engineerKey = JSON.stringify({ id: engineer.id, name: engineer.name });
      engineersSet.add(engineerKey);
    });

    project.dmas.forEach((dma) => {
      const dmaKey = JSON.stringify({ id: dma.id, name: dma.name });
      dmasSet.add(dmaKey);
    });
  });

  const engineers = Array.from(engineersSet).map((item: string) => JSON.parse(item) as Engineer);
  const dmas = Array.from(dmasSet).map((item: string) => JSON.parse(item) as Dma);

  return {
    dropdownProjects: projects,
    dropdownEngineers: engineers,
    dropdownDmas: dmas,
  };
};

export const toPercentage = (number: number | null) => {
  if (number === null) {
    return null;
  }
  const percentage = number * 100;
  return percentage % 1 === 0 ? percentage + '%' : percentage.toFixed(2) + '%';
};

export const QueryParams = (
  projectActiveValue: number | null,
  dmaActiveValue: number| null,
  dateFilters: DateFilter
) => {
  const queryParams: getDeliveryPerformanceQuery = {};

  if (projectActiveValue) {
    queryParams.branch_id = Number(projectActiveValue);
  }

  if (dmaActiveValue) {
    queryParams.dma_id = Number(dmaActiveValue);
  }

  if (dateFilters.dateFrom) {
    queryParams.start_date = moment(dateFilters.dateFrom).format('YYYY-MM-DD');
  }

  if (dateFilters.dateTo) {
    queryParams.end_date = moment(dateFilters.dateTo).format('YYYY-MM-DD');
  }

  return queryParams;
};
