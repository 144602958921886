import Modal from '@/components/Modal/Modal';
import config from '@/config';
import { FilterContext, MapFilters } from '@/Providers/FilterProvider';
import mapbox from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import FilterMap from './components/FilterMap/FilterMap';
import FilterMapView from './components/FilterMapView/FilterMapView';
import SessionInformation from './components/SessionInformation/sessionInformation';
import * as Styled from './style';
import adjustMapViewportToData from './utils/adjustMapViewport';
import { INITIAL_LATITUDE, INITIAL_LONGITUDE, INITIAL_ZOOM } from './utils/config';
import createMap from './utils/createMap';
import onMapLoad from './utils/onMapLoad';
import { MapElement } from './utils/types';

mapbox.accessToken = String(config.MAPBOX_KEY);

type GenerateMapProps = {
  data: MapElement[] | undefined;
  readonly: boolean;
  modal?: boolean;
  showMapFilters?: boolean;
};

const GenerateMap: FC<GenerateMapProps> = ({ data, readonly, modal, showMapFilters }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [mapContainerWidth, setMapContainerWidth] = useState(0);
  const [modalId, setModalId] = useState(0);
  const [lng, setLng] = useState(INITIAL_LONGITUDE);
  const [lat, setLat] = useState(INITIAL_LATITUDE);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [open, setOpen] = useState(false);
  const { setMapFilters } = useContext(FilterContext);

  useEffect(() => {
    if (map.current) {
      return;
    }
    map.current = createMap(mapContainer, lng, lat, zoom);
  }, [lat, lng, map, mapContainer, zoom]);

  useEffect(() => {
    if (!map.current) {
      return;
    }
    map.current?.on('move', () => {
      if (map.current) {
        setLng(parseFloat(map.current.getCenter().lng.toFixed(4)));
        setLat(parseFloat(map.current.getCenter().lat.toFixed(4)));
        setZoom(parseFloat(map.current.getZoom().toFixed(2)));
      }
    });

    map.current.on('load', () => {
      if (readonly) {
        onMapLoad(data, map, onClickModalOpen, modal);
      }
    });

    map.current.resize();

    adjustMapViewportToData(map, data);
  }, [map, data, readonly, modal, isMapExpanded]);

  const onClickModalOpen = (id: number) => {
    setModalId(id);
    setOpen(true);
  };

  const onModalClose = () => {
    setOpen(false);
  };

  const toggleFilters = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowFilters(!showFilters);
  };

  const onSubmit = (values: MapFilters) => {
    setMapFilters(values);
    setShowFilters(!showFilters);
  };

  const handleMapSizeToggle = () => {
    setIsMapExpanded(!isMapExpanded);
  };

  useEffect(() => {
    if (mapContainer.current) {
      setMapContainerWidth(mapContainer.current.clientWidth);
    }
  }, []);

  return (
    <Styled.Wrapper isMapExpanded={isMapExpanded}>
      <Styled.Container isMapExpanded={isMapExpanded} ref={mapContainer} />
      {showMapFilters && (
        <>
          <FilterMap
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            mapContainerWidth={mapContainerWidth}
            onSubmit={onSubmit}
            toggleFilters={toggleFilters}
          />
          <FilterMapView map={map} onMapSizeToggle={handleMapSizeToggle} />
        </>
      )}
      <Modal isOpen={open} onClose={onModalClose} styles={{ maxWidth: '800px' }}>
        <SessionInformation id={modalId} />
      </Modal>
    </Styled.Wrapper>
  );
};

export default React.memo(GenerateMap);
