import mapbox, { Map, MapMouseEvent } from 'mapbox-gl';
import React from 'react';
import { renderToString } from 'react-dom/server';
import GeneralToolTip from '../../tooltips/GeneralTooltip';
import { MapElement } from '../../utils/types';

export const setCursorPointer = (map: Map, item: MapElement) => {
  map.on('mouseenter', `${item.id}-fill`, () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  map.on('mouseleave', `${item.id}-fill`, () => {
    map.getCanvas().style.cursor = '';
  });
};

export const handleClickWithTooltipCheck = (
  map: Map,
  item: MapElement,
  onClickModalOpen: (id: number) => void,
  modal?: boolean
) => {
  const handleTooltipClick = (e: MapMouseEvent) => {
    if (checkToolTipsAreEmpty(map, e)) {
      if (modal) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-assignment
        const tooltip = item.tooltip as any;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        onClickModalOpen(tooltip.Dma_id as number);
      } else {
        new mapbox.Popup()
          .setLngLat(e.lngLat)
          .setHTML(renderToString(<GeneralToolTip tooltip={item.tooltip} />))
          .addTo(map);
      }
    }
  };

  map.on('click', `${item.id}-fill`, handleTooltipClick);
};

export const checkToolTipsAreEmpty = (map: Map, e: MapMouseEvent) => {
  const layers = ['clusters', 'unclustered-point', 'lines-layer'];

  return layers.every((layer) => {
    const layerExists = map.getLayer(layer);
    const features = layerExists ? map.queryRenderedFeatures(e.point, { layers: [layer] }) : [];
    return features.length < 1;
  });
};

export const polygonLayers = (map: Map, item: MapElement): void => {
  // if (!map.getSource(item.id)) {
  map.addSource(item.id, {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {
        tooltip: item.tooltip,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [item.geometry],
      },
    },
  });
  // }

  map.addLayer({
    id: `${item.id}-outline`,
    type: 'line',
    source: item.id,
    layout: {},
    paint: {
      'line-color': item.color,
      'line-width': 3,
    },
  });

  map.addLayer({
    id: `${item.id}-fill`,
    type: 'fill',
    source: item.id,
    layout: {},
    paint: {
      'fill-color': item.color,
      'fill-opacity': 0.5,
    },
  });
};
