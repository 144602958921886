
import { Styles } from '@/types/styles.type';
import React, { FC } from 'react';
import Picker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Styled from './style';

interface IProps {
  label?: string;
  placeholder?: string;
  value: Date;
  onChange: (value: Date) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  error?: string;
  styles?: Styles;
  testId?: string;
}

const DatePicker: FC<IProps> = ({
  onChange,
  value,
  label,
  disableFuture,
  disablePast,
  placeholder,
  error,
  styles,
  testId,
}) => (
  <Styled.Container data-testid={testId || 'datepicker'} error={error}>
    {label && (
      <Styled.Label labelStyle={styles?.label} data-testid="datepicker-label" error={!!error}>
        {label}
      </Styled.Label>
    )}
    <Picker
      placeholderText={placeholder}
      selected={value}
      onChange={onChange}
      maxDate={disableFuture ? new Date() : null}
      minDate={disablePast ? new Date() : null}
      popperClassName="datepicker-dialog"
      calendarClassName="picker"
    />
    {error && <Styled.Error data-testid="datepicker-error">{error}</Styled.Error>}
  </Styled.Container>
);

export default DatePicker;
