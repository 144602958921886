import { AddBranchValues, DeleteBranchValues, EditBranchValues, IBranch } from '@/types/branch.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const branchApi = createApi({
  reducerPath: 'branchQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Branch'],
  endpoints: (builder) => ({
    getBranchesByCompany: builder.query<IBranch[], number | string>({
      query: (companyId: number | string) => `/branches/company/${companyId}`,
      providesTags: ['Branch'],
    }),
    updateBranch: builder.mutation({
      query: ({ id, ...body }: EditBranchValues) => ({
        url: `/branches/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Branch'],
    }),
    createBranch: builder.mutation({
      query: (body: AddBranchValues) => ({
        url: '/branches',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Branch'],
    }),
    deleteBranch: builder.mutation({
      query: ({ id, companyId }: DeleteBranchValues) => ({
        url: `/branches/${id}`,
        method: 'DELETE',
        body: { companyId },
      }),
      invalidatesTags: ['Branch'],
    }),
  }),
});

export const {
  useGetBranchesByCompanyQuery,
  useLazyGetBranchesByCompanyQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
} = branchApi;
