import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

type ValueType = number | undefined | string;

const checkNameIsUnique = (context: Yup.TestContext, value: ValueType) => {
  const { branches } = context.options.context as { branches: string[] };

  return !branches.includes(String(value));
};

const checkRequiredRule: Yup.TestFunction<ValueType, AnyObject> = (
  value,
  context: Yup.TestContext
) => checkNameIsUnique(context, value);

export const ValidationSchema = Yup.object({
  name: Yup.string()
    .required('VALIDATION.REQUIRED')
    .test('branchId', 'VALIDATION.BRANCHES.UNIQUE', checkRequiredRule),
});
