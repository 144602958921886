
import { useCallback, useMemo } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDebounce from './useDebounce';

interface INotification {
  type: string;
  message: string;
}

export const useNotification = (delay = 3000) => {
  const options: ToastOptions = useMemo(
    () => ({
      position: 'top-right',
      autoClose: delay,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    }),
    [delay]
  );

  const createNotification = useCallback(
    ({ type, message }: INotification) => {
      switch (type) {
        case 'info':
          toast.info(message, options);
          break;
        case 'success':
          toast.success(message, options);
          break;
        case 'warning':
          toast.warning(message, options);
          break;
        case 'error':
          toast.error(message, options);
          break;
        default:
          toast.info(message, options);
      }
    },
    [toast, options]
  );

  const showNotification = useDebounce(createNotification, 300);
  return { showNotification };
};

export default useNotification;
