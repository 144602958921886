import { Button } from '@/components';
import { ButtonVariant } from '@/components/Button/Button';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Styled from './style';

const RunningReportOutlet = () => {
  const { id: reportId = '' } = useParams();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { prefixedT } = useCustomTranslation('REPORT_BUILDER');
  const navigate = useNavigate();

  const exportToPdf = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow?.print();
    }
  };

  return (
    <>
      <Styled.HeaderControls>
        <Button
          width="120px"
          height="34px"
          color={theme.colors.black}
          variant={ButtonVariant.outline}
          onClick={() => navigate(`/app/reports/${reportId}`)}
        >
          <Styled.ButtonContainer>
            <Styled.BackIcon /> {prefixedT('BACK')}
          </Styled.ButtonContainer>
        </Button>
        <Button width="134px" height="34px" color={theme.colors.coral} onClick={exportToPdf}>
          <Styled.ButtonContainer>
            <Styled.ExportIcon /> {prefixedT('EXPORT_TO_PDF')}
          </Styled.ButtonContainer>
        </Button>
      </Styled.HeaderControls>
      <Styled.IFrame
        ref={iframeRef}
        title={`report-${reportId}`}
        src={`/reports/${reportId}/running`}
      />
    </>
  );
};

export default RunningReportOutlet;
