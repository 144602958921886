import { LngLatLike } from 'mapbox-gl';
import { MapElement } from './types';

export const getBoundingBox = (data: MapElement[]) => {
  const lngs = [];
  const lats = [];

  for (const value of data) {
    lngs.push(value.geometry[0][0]);
    lats.push(value.geometry[0][1]);
  }

  const minLng = Math.min.apply(null, lngs);
  const maxLng = Math.max.apply(null, lngs);

  const minLat = Math.min.apply(null, lats);
  const maxLat = Math.max.apply(null, lats);

  const boundingBox = [
    [minLng, minLat],
    [maxLng, maxLat],
  ];

  return boundingBox as [LngLatLike, LngLatLike];
};

export default getBoundingBox;
