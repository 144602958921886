import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const ExceptionTab = styled.div<{
  value?: number;
}>`
  text-align: center;
  color: ${({ value }) => value ? ({ theme }: ThemeProps<Theme>) => theme.colors.artyClickRed : '#34B639'};
  text-transform: uppercase;
  font-weight: 600;
  min-width: 150px;
`;
