import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  font-weight: bold;
  line-height: 35px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  font-size: 12px;
`;

export const ListObject = styled.span`
    font-weight: normal;
`;

export const ListContainer = styled.div`
  margin-bottom: 20px;
`;

export const MapWrapper = styled.div``;

export const ListTitle = styled.h3`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
`;

export const ListTitleContainer = styled.div`
  margin-top: 20px;
`;
