import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.celestialBlue}
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;

export const List = styled.ul`
  list-style-type: none;
  font-weight: bold;
  line-height: 35px;
`;

export const ListContainer = styled.div`
  margin-bottom: 10px;
`;

export const ListObject = styled.span`
  font-weight: normal;
`;

export const HistoryLogWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
`;
