import useCustomTranslation from '@/localization/useCustomTranslation';
import React from 'react';
import * as Styled from './style';

const ForgotPasswordSuccess = () => {
  const { prefixedT } = useCustomTranslation('FORGOT_PASSWORD_PAGE');
  return <Styled.SubTitle>{prefixedT('RECOVERY_SUCCESS')}</Styled.SubTitle>;
};

export default ForgotPasswordSuccess;
