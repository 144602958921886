import { Theme } from '@/theme';
import { getGridItemWidth } from '@/utils/style';
import styled, { ThemeProps } from 'styled-components';

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: ${getGridItemWidth(2, 20)};
  column-gap: 20px;
  width: 100%;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.l}) {
    grid-template-columns: ${getGridItemWidth(1, 20)};
  }
`;

export const Card = styled.div`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;
