import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import { MapElement } from '@/components/ReportLayout/widgets/MapView/utils/types';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetDropdownQuery, useGetWaypointInformationQuery } from '@/redux/reports/reports.api';
import React, { FC } from 'react';
import HistoryContainer from './Components/HistoryContainer/HistoryContainer';
import ImageContainer from './Components/ImageContainer/ImageContainer';
import WaypointHeadings from './Components/WaypointHeadings/WaypointHeadings';
import WaypointTickboxContainer from './Components/WaypointTickboxContainer/WaypointTickboxContainer';
import * as Styled from './style';

const WaypointContainer: FC = () => {
  const { prefixedT } = useCustomTranslation('WAYPOINT_PAGE.WAYPOINT_INFO');
  const queryParams = new URLSearchParams(location.search);
  const waypointID = queryParams.get('waypoint') as string;

  const { data } = useGetWaypointInformationQuery(waypointID);
  const { data: dropDownData } = useGetDropdownQuery(waypointID);

  const mapData: MapElement[] = [];

  if (data?.map_info.area_of_interest.data) {
    mapData.push(...data?.map_info.area_of_interest.data);
  }
  if (data?.map_info.leak_location.data) {
    mapData.push(...data?.map_info.leak_location.data);
  }

  return (
    <div style={{ padding: '40px' }}>
      {dropDownData && (
        <WaypointHeadings
          correlationIds={dropDownData.correlation_ids}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessionIds={dropDownData.session_ids}
        />
      )}

      {data && (
        <>
          <Styled.InfoAndMapWrapper>
            <Styled.InfoWrapper>
              <Styled.InfoWrapperTitle>
                <h3>{prefixedT('TITLE')}</h3>
              </Styled.InfoWrapperTitle>
              <Styled.List>
                {data?.current_status && (
                  <li>
                    {prefixedT('CURRENT_STATUS')}:{' '}
                    <Styled.ListObject>{data?.current_status}</Styled.ListObject>
                  </li>
                )}
                {data?.waypoint_name && (
                  <li>
                    {prefixedT('WAYPOINT_NAME')}:{' '}
                    <Styled.ListObject>{data?.waypoint_name}</Styled.ListObject>
                  </li>
                )}
                {data?.surface_type && (
                  <li>
                    {prefixedT('SURFACE_TYPE')}:{' '}
                    <Styled.ListObject>{data?.surface_type}</Styled.ListObject>
                  </li>
                )}
                {data?.pipe_material && (
                  <li>
                    {prefixedT('PIPE_MATERIAL')}:{' '}
                    <Styled.ListObject>{data?.pipe_material}</Styled.ListObject>
                  </li>
                )}
                {data?.AI_size && (
                  <li>
                    {prefixedT('PREDICTED_SIZE')}:{' '}
                    <Styled.ListObject>{data?.AI_size}</Styled.ListObject>
                  </li>
                )}
                {data?.address && (
                  <li>
                    {prefixedT('ADDRESS')}: <Styled.ListObject>{data?.address}</Styled.ListObject>
                  </li>
                )}
                {data?.what3words && (
                  <li>
                    {prefixedT('WHAT3WORDS')}:{' '}
                    <Styled.ListObject>{data?.what3words}</Styled.ListObject>
                  </li>
                )}
                {data?.estimated_size && (
                  <li>
                    {prefixedT('ESTIMATED_SIZE')}:{' '}
                    <Styled.ListObject>{data?.estimated_size}</Styled.ListObject>
                  </li>
                )}
                {data?.leak_type && (
                  <li>
                    {prefixedT('LEAK_TYPE')}:
                    <Styled.ListObject>{data?.leak_type}</Styled.ListObject>
                  </li>
                )}
                {data?.CNS && (
                  <li>
                    {prefixedT('CNS')}: <Styled.ListObject>{data?.CNS}</Styled.ListObject>
                  </li>
                )}
              </Styled.List>
            </Styled.InfoWrapper>

            <Styled.MapWrapper>
              <GenerateMap data={mapData} readonly={true} />
            </Styled.MapWrapper>
          </Styled.InfoAndMapWrapper>

          <Styled.TickBoxAndHistoryWrapper>
            <WaypointTickboxContainer
              overnight={data.overnight}
              topSound={data.top_sound}
              correlation={data.correlation}
              scheduled={data.scheduled}
              markedUp={data.marked_up}
              repairScheduled={data.repair_scheduled}
              repaired={data.repaired}
            />

            {data?.history_log && <HistoryContainer historyLog={data?.history_log} />}
          </Styled.TickBoxAndHistoryWrapper>

          <ImageContainer linkToAssets={data.link_to_assets} />
        </>
      )}
    </div>
  );
};

export default withPageLoader(WaypointContainer);
