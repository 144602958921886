import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const CompanyTitle = styled.h3`
  margin-top: 20px;
`;

export const ChooseCompanyTitle = styled.h2`
  padding: 20px;
  margin-bottom: 10px;
  font-weight: normal;
  color: rgb(184, 184, 184);
  font-size: 12px;
`;

export const ListItemContainer = styled.div`
  margin-bottom: 5px;
  padding: 15px;
`;

export const TableTitleContainer = styled.div`
  padding: 5px 5px 10px 5px;  
  flex: 1;
`;

export const TableTitle = styled.h4`
  font-weight: normal;
  color: #b8b8b8;
  font-size: 14px;
`;

export const ChooseCompanyContainer = styled.div`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: 1px solid #F9F9F9;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
`;

export const PairContainer = styled.div`
  display: grid;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  border-bottom: 2px solid #F9F9F9;
  font-size: 12px;

  :hover {
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.lochmara};
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }
`;

export const TableTitleWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #F9F9F9;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
`;
