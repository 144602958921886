import { userAuthorizedSelector } from '@/redux/user/user.selector';
import { GuardComponent } from '@/types/common.type';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface AuthGuardProps {
  redirect?: string;
  fallbackRedirect?: string;
  children?: any;
}

// eslint-disable-next-line react/prop-types
const AuthGuard: GuardComponent<AuthGuardProps> = ({ children, redirect, fallbackRedirect }) => {
  const isAuthorized = useSelector(userAuthorizedSelector);

  if (fallbackRedirect && !isAuthorized) {
    return <Navigate to={fallbackRedirect} />;
  }

  if (redirect && isAuthorized) {
    return <Navigate to={redirect} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
