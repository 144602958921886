import { Button, FacebookLoader, TextField } from '@/components';
import { AuthLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IErrorData } from '@/types/rtk.type';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { Field, Formik } from 'formik';
import React, { FC, useMemo } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

export interface RecoveryValues {
  password: string;
  repeat: string;
  email: string;
}
export enum LinkTypes {
  forgot = 'forgot',
  confirm = 'confirm',
}

export interface IDecodedData {
  email: string;
  type: LinkTypes;
}

interface RecoveryProps {
  onSubmit: (data: RecoveryValues) => void;
  error: IErrorData | null;
  initialValues: RecoveryValues;
  status: QueryStatus;
  decodedData: IDecodedData;
}

const Recovery: FC<RecoveryProps> = ({ onSubmit, initialValues, decodedData }) => {
  const optionalTranslate: string = useMemo(() => {
    switch (decodedData?.type) {
      case LinkTypes.forgot:
        return 'RECOVERY_PASSWORD_PAGE';
      case LinkTypes.confirm:
        return 'CONFIRM_ACCOUNT_PAGE';
      default:
        return 'CONFIRM_ACCOUNT_PAGE';
    }
  }, [decodedData]);

  const { t, prefixedT } = useCustomTranslation(optionalTranslate);

  const renderForm = () => (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {({ handleSubmit, errors }) => (
        <Styled.Form onSubmit={handleSubmit}>
          <Styled.Fields>
            <Styled.FieldWrapper>
              <FacebookLoader active={!decodedData}>
                <Field
                  type="email"
                  name="email"
                  as={TextField}
                  label={prefixedT('FORM.EMAIL.LABEL')}
                  placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
                  testId="email"
                  disabled
                />
              </FacebookLoader>
            </Styled.FieldWrapper>

            <Styled.FieldWrapper>
              <FacebookLoader active={!decodedData}>
                <Field
                  type="password"
                  name="password"
                  as={TextField}
                  label={prefixedT('FORM.PASSWORD.LABEL')}
                  placeholder={prefixedT('FORM.PASSWORD.PLACEHOLDER')}
                  error={errors.repeat && t(errors.repeat)}
                  testId="password"
                />
              </FacebookLoader>
            </Styled.FieldWrapper>

            <Styled.FieldWrapper>
              <FacebookLoader active={!decodedData}>
                <Field
                  type="password"
                  name="repeat"
                  as={TextField}
                  label={prefixedT('FORM.REPEAT_PASSWORD.LABEL')}
                  placeholder={prefixedT('FORM.REPEAT_PASSWORD.PLACEHOLDER')}
                  error={errors.repeat && t(errors.repeat)}
                  testId="repeat"
                />
              </FacebookLoader>
            </Styled.FieldWrapper>
          </Styled.Fields>
          <Styled.ButtonContainer>
            <Styled.ButtonWrapper>
              <FacebookLoader active={!decodedData}>
                <Button width="100%" height="50px" type="submit" testId="submit">
                  {prefixedT('FORM.SUBMIT')}
                </Button>
              </FacebookLoader>
            </Styled.ButtonWrapper>
          </Styled.ButtonContainer>
        </Styled.Form>
      )}
    </Formik>
  );

  return (
    <AuthLayout>
      <Styled.Wrapper data-testid="recovery-form">
        <Styled.LogoContainer />
        <Styled.TitleWrapper>
          <FacebookLoader active={!decodedData}>
            <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
          </FacebookLoader>
        </Styled.TitleWrapper>
        <Styled.SubtitleWrapper>
          <FacebookLoader active={!decodedData}>
            <Styled.SubTitle>{prefixedT('SUBTITLE')}</Styled.SubTitle>
          </FacebookLoader>
        </Styled.SubtitleWrapper>
        {renderForm()}
      </Styled.Wrapper>
    </AuthLayout>
  );
};

export default Recovery;
