/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactComponent as DataGrid } from '@/assets/icons/data-grid.svg';
import { ReactComponent as Filters } from '@/assets/icons/filter.svg';
import { ReactComponent as Map } from '@/assets/icons/map.svg';
import { ReactComponent as Summary } from '@/assets/icons/summary.svg';

const widgetIcons = {
  summary: Summary,
  map: Map,
  results: DataGrid,
  filters: Filters,
};

export default widgetIcons;
