import styled from 'styled-components';
import { TableCellAlignment, TableSortOrders } from '../types';

const setAlign = (alignType: TableCellAlignment) => {
  switch (alignType) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default:
      return 'flex-start';
  }
};

export const Wrapper = styled.div<{
  sortable?: boolean;
  isSorted?: boolean;
  order?: TableSortOrders;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : 'auto')};
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  position: relative;

  ${({ isSorted, order }) => ( isSorted ? `&:after {
    content: "▲";
    position: absolute;
    left: calc(100% + 5px);
    top: 0;
    transform: scaleY(${order === TableSortOrders.asc ? 1 : -1});
  }` : null)}
`;

export const Container = styled.td``;

export const Content = styled.div<{
  align: TableCellAlignment;
}>`
  display: flex;
  align-items: center;

  justify-content: ${({ align }) => setAlign(align)};

  margin: 0 20px;
  white-space: nowrap;
`;
