import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const CompaniesCrumb: FC = () => {
  const { prefixedT } = useCustomTranslation('COMPANIES_PAGE');

  return (
    <Link to="/app/companies">{prefixedT('CRUMB')}</Link>
  );
};

export default CompaniesCrumb;
