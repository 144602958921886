
import { ContentLoader } from '@/components';
import { useEditUserMutation } from '@/redux/user/user.api';
import { EditUserValues, IUser } from '@/types/user.type';
import React, { useMemo } from 'react';
import useCheckPermissions from './hooks/useCheckPermissions';
import useFetchUserDetails from './hooks/useFetchUserDetails';
import UserDetails from './UserDetails';
import UserDetailsCrumb from './UserDetailsCrumb';

const UserDetailsContainer = () => {
  const { companies, user, profile, roles, companiesResult, rolesResult, userResult } =
    useFetchUserDetails();
  useCheckPermissions(user as IUser, profile as IUser);

  const [onEditUser, editUserResult] = useEditUserMutation();

  const initialUserValue: EditUserValues = useMemo(
    () => ({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      companyId: user?.companyId || '',
      branchId: user?.branchId || '',
      roleId: user?.roleId || '',
      id: user?.id || '',
    }),
    [user]
  );

  return (
    <>
      <ContentLoader active={editUserResult.isLoading || (user && userResult.isFetching)} />
      <UserDetails
        initialValues={initialUserValue}
        profile={profile as IUser}
        companies={companies}
        roles={roles}
        onEditUser={onEditUser}
        user={user as IUser}
        loading={companiesResult.isLoading || rolesResult.isLoading || userResult.isLoading}
      />
    </>
  );
};

UserDetailsContainer.Crumb = UserDetailsCrumb;

export default UserDetailsContainer;
