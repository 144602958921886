/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import mapbox, { Map, MapMouseEvent } from 'mapbox-gl';
import renderPointTooltip from '../../tooltips/PointTooltip';
import { MapElement, PointTooltipType } from '../../utils/types';

export const INITIAL_LONGITUDE = -2.244644;
export const INITIAL_LATITUDE = 53.483959;
export const INITIAL_ZOOM = 5;
export const MAP_STYLE = 'mapbox://styles/mapbox/streets-v9';


const drawClusters = (map: Map, items: MapElement[]): void => {
  const combinedFeatures = items.map((item) => ({
    type: 'Feature',
    properties: {
      tooltip: item.tooltip,
      id: item.id,
    },
    geometry: {
      type: 'Point',
      coordinates: item.geometry[0],
    },
  })) as Feature<Geometry, GeoJsonProperties>[];

  const clusterSourceId = 'clusters';

  if (!map.getSource(clusterSourceId)) {
    map.addSource('clusters', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: combinedFeatures,
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });
  }

  const clusterLayerID = 'clusters';
  if (!map.getLayer(clusterLayerID)) {
    map.addLayer({
      id: clusterLayerID,
      type: 'circle',
      source: 'clusters',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      },
    });
  }

  const clusterCountID = 'cluster-count';
  if (!map.getLayer(clusterCountID)) {
    map.addLayer({
      id: clusterCountID,
      type: 'symbol',
      source: 'clusters',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });
  }

  const unclusteredPointID = 'unclustered-point';
  if (!map.getLayer(unclusteredPointID)) {
    map.addLayer({
      id: unclusteredPointID,
      type: 'circle',
      source: 'clusters',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
      },
    });
  }

  map.on('click', 'clusters', (e) => {
    const features: Feature<Geometry, GeoJsonProperties>[] = map.queryRenderedFeatures(e.point, {
      layers: ['clusters'],
    });
    if (features.length > 0) {
      const clusterId = features[0].properties?.cluster_id;
      // @ts-ignore
      map.getSource('clusters').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        map.easeTo({
          // @ts-ignore
          center: features[0].geometry.coordinates,
          zoom,
        });
      });
    }
  });

  map.on('click', 'unclustered-point', (e: MapMouseEvent) => {
    const features = map.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] });
    const clickedFeature = features[0];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const tooltip: PointTooltipType = clickedFeature.properties?.tooltip && JSON.parse(clickedFeature.properties?.tooltip);

    window.location.href = `/app/waypoint?waypoint=${tooltip.Waypoint_id}`;
  });

  const currentPopup = new mapbox.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  map.on('mouseenter', 'unclustered-point', (e) => {
    map.getCanvas().style.cursor = 'pointer';

    const features = map.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] });
    const clickedFeature = features[0];

    if (currentPopup) {
      currentPopup.remove();
    }

    if (clickedFeature) {
      const tooltip = clickedFeature.properties?.tooltip;
      if (tooltip) {
        const container = document.createElement('div');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        renderPointTooltip(tooltip, container);
        currentPopup.setLngLat(e.lngLat).setDOMContent(container).addTo(map);
      }
    }
  });

  map.on('mouseleave', 'unclustered-point', () => {
    currentPopup.remove();
  });
};

export default drawClusters;
