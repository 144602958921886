import theme from '@/theme';
import React, { FC } from 'react';
import Dialog, { DialogProps } from './Dialog';

const DangerDialog: FC<DialogProps> = (props) => (
  <Dialog
    {...props}
    style={{
      ...props.style,
      color: {
        confirm: props.style?.color?.confirm || theme.colors.coral,
        cancel: props.style?.color?.cancel || theme.colors.blue,
      },
    }}
  />
);

export default DangerDialog;
