import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  text-align: center;
  margin-bottom: 20px;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  cursor: pointer;
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
  border-radius: 5px;
  padding: 11px 0;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;
