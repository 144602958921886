import { ReactComponent as CloseButtonIcon } from '@/assets/icons/cross.svg';
import { Theme } from '@/theme';
import { Styles } from '@/types/styles.type';
import { motion } from 'framer-motion';
import styled, { ThemeProps } from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBackground = styled(motion.div)`
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  filter: opacity(0.25);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Content = styled(motion.div)<{
  styles?: Styles;
}>`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  max-width: ${({ styles }) => styles?.maxWidth || '560px'};
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 27px;
  margin: 0 20px;
  max-height: calc(100% - 100px);
  overflow-y: auto;
  height: auto;
`;

export const ModalHeader = styled.div<{
  hasTitle?: boolean;
}>`
  display: flex;
  justify-content: ${({ hasTitle }) => (hasTitle ? 'space-between' : 'flex-end')};
  align-items: baseline;
`;

export const ModalTitle = styled.h2`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepBlue};
  font-size: 16px;
`;
export const CloseButton = styled(CloseButtonIcon)`
  cursor: pointer;
`;
export const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding: 13px 0;
  padding-bottom: 0px;
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    margin-top: 16px;
  }
  @media (max-height: 500px) {
    margin-top: 16px;
  }
`;
export const ModalFormButton = styled.div`
  height: 34px;
  width: 140px;
  align-self: flex-end;
  display: flex;
  align-items: baseline;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    width: 100%;
    height: 42px;
  }
`;
