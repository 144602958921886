import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import * as Styled from './style';

type WaterCostProps = {
  cost: number;
  sessions: number;
};

const WaterCost: FC<WaterCostProps> = ({ cost, sessions }) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE');
  return (
    <Styled.WaterContainer>
      <Styled.WaterCostContainer>
        <Styled.WaterCostTitle1>{prefixedT('WATER.COST')}</Styled.WaterCostTitle1>
        <Styled.WaterCostTitle2>
          ${cost}
        </Styled.WaterCostTitle2>
        <Styled.WaterCostTitle3>{prefixedT('WATER.COST_PER_YEAR')}</Styled.WaterCostTitle3>
      </Styled.WaterCostContainer>
      <Styled.WaterCostSessionsContainer>
        <p>{prefixedT('WATER.ANALYSED_BY')}</p> <p>{prefixedT('WATER.FIDO_AI')} {sessions}</p>
      </Styled.WaterCostSessionsContainer>
    </Styled.WaterContainer>
  );
};

export default WaterCost;
