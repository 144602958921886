import { useEditUserMutation, useGetProfileQuery } from '@/redux/user/user.api';
import { EditUserValues } from '@/types/user.type';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import React, { useState } from 'react';
import Profile from './Profile';

const ProfileContainer = () => {
  const { data: user } = useGetProfileQuery();
  const [onEditUser, { status }] = useEditUserMutation();
  const [isEditable, setEditable] = useState(false);

  const initialValue: EditUserValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    companyId: user?.companyId || '',
    branchId: user?.branchId || '',
    roleId: user?.roleId || '',
    id: user?.id || '',
  };

  const handleEdit = async (values: EditUserValues) => {
    if (status === QueryStatus.pending) {
      return;
    }

    const data = {
      ...values,
      companyId: values.companyId || null,
      branchId: values.branchId || null,
    };
    await onEditUser(data).unwrap();
    setEditable(false);
  };

  return (
    <Profile
      profile={user}
      initialValues={initialValue}
      onEditActive={() => setEditable(true)}
      onDiscard={() => setEditable(false)}
      isEditable={isEditable}
      onEditUser={handleEdit}
    />
  );
};

export default ProfileContainer;
