
import { useNotification } from '@/hooks/useNotification';
import { requestErrorSelector, requestSuccessSelector } from '@/redux/request/request.selector';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const Notification: FC = () => {
  const { showNotification } = useNotification();
  const error = useSelector(requestErrorSelector);
  const success = useSelector(requestSuccessSelector);

  useEffect(() => {
    if (!error) {
      return;
    }
    showNotification({ type: 'error', message: error.message });
  }, [error]);

  useEffect(() => {
    if (!success) {
      return;
    }
    showNotification({ type: 'success', message: success.message });
  }, [success]);

  return <ToastContainer />;
};

export default Notification;
