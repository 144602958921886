/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext } from 'react';
import { TableContext } from '../context';
import { ITableHeader } from '../types';
import * as Styled from './style';

export interface IBodyProps<T> {
  getRowStructure: (row: T, index: number, array: T[], headers: ITableHeader[]) => React.ReactNode;
  striped?: boolean;
}

function Body<T>({ getRowStructure, striped }: IBodyProps<T>) {
  const { data, headers } = useContext(TableContext);

  return (
    <Styled.Container data-testid="table-body" striped={striped}>
      {(data as []).map((row, i, arr) => getRowStructure(row, i, arr, headers))}
    </Styled.Container>
  );
}

export default Body;
