import { ReactComponent as TrashIconComponent } from '@/assets/icons/delete.svg';
import { Theme } from '@/theme';
import { getGridItemWidth } from '@/utils/style';
import { Form } from 'formik';
import styled, { ThemeProps, css } from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  padding-bottom: 30px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  border-radius: 8px;
  width: calc(100% - 40px);
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${getGridItemWidth(3, 32)};
  column-gap: 32px;
  width: 100%;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.l}) {
    grid-template-columns: ${getGridItemWidth(2, 32)};
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.s}) {
    grid-template-columns: ${getGridItemWidth(1, 32)};
  }
`;

export const EditForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const EditHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 19px;
  margin-bottom: 12px;
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

export const EditButtons = styled.div`
  display: flex;
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    margin-top: 4px;
    margin-bottom: 20px;
  }
`;

export const EditButtonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover svg path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }
`;

export const TrashIcon = styled(TrashIconComponent)`
  margin-right: 5px;
  transition: fill 0.3s ease;
`;

export const Title = styled.h1`
  font-size: 20px;
  margin: 0;
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    order: 1;
    font-size: 18px;
  }
`;
export const Label = css`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.blue};
`;
