import { Tab } from '@/types/profile.type';
import React, { FC, useMemo } from 'react';
import * as Styled from './style';

interface TabsProps {
  tabs: Tab[];
  selected: number;
  onChange: (id: number) => void;
  disabled?: boolean;
}

export const Tabs: FC<TabsProps> = ({ tabs, selected, onChange }) => {
  const component = useMemo(() => {
    const Element = tabs[selected - 1].component;
    if (!Element) {
      return null;
    }
    return <Element {...(tabs[selected - 1].props || {})} />;
  }, [tabs, selected]);
  return (
    <Styled.Container>
      <Styled.Tabs>
        {tabs &&
          tabs.map((tab, index) => (
            <Styled.Tab
              selected={index + 1 === selected}
              disabled={tab.disabled}
              onClick={() => onChange(tab.id)}
              count={tabs.length}
              key={tab.id}
            >
              {tab.title}
            </Styled.Tab>
          ))}
      </Styled.Tabs>
      {component}
    </Styled.Container>
  );
};

export default Tabs;
