import { Table } from '@/components';
import Button, { ButtonVariant } from '@/components/Button/Button';
import {
  ITableHeader,
  ITablePaginate,
  TableActionTypes,
  TableCellAlignment,
  TableSortOrders
} from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import theme from '@/theme';
import { IUser } from '@/types/user.type';
import { getRowIndex } from '@/utils/table';
import { UserRole } from '@/utils/user';
import React, { FC } from 'react';
import * as Styled from '../style';
interface IProps {
  employees: IUser[];
  totalCount: number;
  page: number;
  pages: number;
  onEmployeeEdit: (user: IUser) => void;
  onEmployeeDelete: (user: IUser) => void;
  onEmployeeAdd: () => void;
  onEmployeePageChange: (p: ITablePaginate) => void;
}

const headers: ITableHeader[] = [
  { id: 'index', title: 'INDEX', sortable: false, align: TableCellAlignment.left },
  { id: 'name', title: 'NAME', sortable: true, align: TableCellAlignment.left },
  { id: 'email', title: 'EMAIL', sortable: true, align: TableCellAlignment.left },
  { id: 'role', title: 'ROLE', sortable: true, align: TableCellAlignment.left },
  { id: 'branch', title: 'BRANCH', sortable: true, align: TableCellAlignment.left },
  { id: 'status', title: 'STATUS', sortable: true, align: TableCellAlignment.left },
  {
    id: 'actions',
    title: 'ACTIONS',
    sortable: false,
    align: TableCellAlignment.right,
  },
];

const EmployeesTable: FC<IProps> = ({
  employees,
  totalCount,
  pages,
  page,
  onEmployeePageChange,
  onEmployeeAdd,
  onEmployeeDelete,
  onEmployeeEdit,
}) => {
  const { prefixedT, t } = useCustomTranslation('EDIT_COMPANY_PAGE.EMPLOYEES_TABLE');

  const titleCondition = totalCount
    ? prefixedT('EMPLOYEES', { count: totalCount })
    : prefixedT('NO_EMPLOYEES');

  const headerControls = (
    <Button
      width="120px"
      height="34px"
      variant={ButtonVariant.outline}
      color={theme.colors.lightBlue}
      onClick={onEmployeeAdd}
    >
      <Styled.EditButtonContent>
        <Styled.PlusIcon />
        {prefixedT('ADD')}
      </Styled.EditButtonContent>
    </Button>
  );

  const getRowStructure = (row: IUser, index: number) => (
    <Table.Row key={row.id}>
      <Table.Cell headerId="index">{getRowIndex(page, index)}</Table.Cell>
      <Table.Cell headerId="name">{`${row.firstName} ${row.lastName}`}</Table.Cell>
      <Table.Cell headerId="email">{row.email}</Table.Cell>
      <Table.Cell headerId="role">{t(UserRole[row.role?.code as keyof typeof UserRole])}</Table.Cell>
      <Table.Cell headerId="branch">{row.branch?.name || ''}</Table.Cell>
      <Table.Cell headerId="status">{row.status || ''}</Table.Cell>
      <Table.Cell headerId="actions" align={TableCellAlignment.right}>
        <Table.Action onClick={() => onEmployeeEdit(row)} type={TableActionTypes.edit} />
        <Table.Action onClick={() => onEmployeeDelete(row)} type={TableActionTypes.delete} />
      </Table.Cell>
    </Table.Row>
  );

  const getHeaderStructure = (header: ITableHeader) => <div>{prefixedT(header.title)}</div>;

  return (
    <>
      <Table.Header title={titleCondition} controls={headerControls} />
      {Boolean(employees.length) && (
        <>
          <Table data={employees} order={TableSortOrders.asc} orderBy="id" headers={headers}>
            <Table.Head getHeaderStructure={getHeaderStructure} />
            <Table.Body getRowStructure={getRowStructure} />
          </Table>
          <Table.Pagination pages={pages} onChange={onEmployeePageChange} />
        </>
      )}
    </>
  );
};

export default EmployeesTable;
