import { IReactChildren } from '@/types/common.type';
import React, { FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

interface IProps {
  children: IReactChildren;
}

export const LocalizationProvider: FC<IProps> = ({ children }) => <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
