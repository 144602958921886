import { Button, TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IBranch } from '@/types/branch.type';
import { validateFormWithProps } from '@/utils/form';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

export interface AddBranchForm {
  name: string;
}

interface AddBranchProps {
  onSubmit: (data: AddBranchForm) => void;
  branches: IBranch[];
}

const initialValues: AddBranchForm = {
  name: '',
};

const AddBranch: FC<AddBranchProps> = ({ onSubmit, branches }) => {
  const { t, prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE.ADD_BRANCH');

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values) =>
        validateFormWithProps(values, ValidationSchema, {
          branches: branches.map((el: IBranch) => el.name),
        })
      }
    >
      {({ errors, dirty }) => (
        <Styled.CompanyForm>
          <Field
            name="name"
            as={TextField}
            label={prefixedT('FORM.NAME.LABEL')}
            placeholder={prefixedT('FORM.NAME.PLACEHOLDER')}
            error={errors.name && t(errors.name)}
            testId="add-name-branch"
          />
          <Styled.FormButton>
            <Button
              testId="add-branch-submit"
              disabled={!dirty}
              width="100%"
              height="100%"
              type="submit"
            >
              <Styled.ButtonContent>
                <Styled.PlusIcon />
                {prefixedT('FORM.SUBMIT')}
              </Styled.ButtonContent>
            </Button>
          </Styled.FormButton>
        </Styled.CompanyForm>
      )}
    </Formik>
  );
};

export default AddBranch;
