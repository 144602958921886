import { AccountSwitcher } from '@/components';
import SearchBar from '@/components/SearchBar/SearchBar';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useDashboardContext } from '@/Providers/DashboardProvider';
import { RootState } from '@/redux';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import TimeRangeSelect from '../TimeRangeSelect/TimeRangeSelect';
import * as Styled from './style';

const StickyMenu: FC = () => {
  const { setSearch, setTimeRange, timeRange } = useDashboardContext();
  const { prefixedT } = useCustomTranslation('COMPONENTS.SEARCH_BAR');
  const activeAccount = useSelector((state: RootState) => state.activeAccount.currentAccount);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const placeholder = activeAccount?.type === 'company' ? prefixedT('SEARCH_PROJECTS') : prefixedT('SEARCH_COMPANIES');

  return (
    <Styled.RowWrapper>
      <Styled.DropdownContainer>
        <TimeRangeSelect onTimeRangeChange={setTimeRange} timeRange={timeRange} />
      </Styled.DropdownContainer>
      <div>
        <AccountSwitcher />
      </div>
      <Styled.SearchBarContainer>
        <SearchBar placeholder={placeholder} handleSearch={handleSearch} />
      </Styled.SearchBarContainer>
    </Styled.RowWrapper>
  );
};

export default StickyMenu;
