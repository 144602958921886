
import { useLazyGetBranchesByCompanyQuery } from '@/redux/branches/branches.api';
import { useLazyGetCompaniesQuery } from '@/redux/company/company.api';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { ICompany } from '@/types/company.type';
import { UserRoles } from '@/types/user.type';
import { useEffect } from 'react';

const useFetchFilterOptions = () => {
  const [fetchCompanies, companiesResult] = useLazyGetCompaniesQuery();
  const [fetchBranches, branchesResult] = useLazyGetBranchesByCompanyQuery();

  const companies = (companiesResult.data || []) as ICompany[];
  const branches = (branchesResult.data || []);

  const { data: profile } = useGetProfileQuery();

  const fetchFilterOption = async () => {
    const isSuperAdmin: boolean = profile?.role?.code === UserRoles.SUPER_ADMIN;
    const isCompanyAdmin: boolean = profile?.role?.code === UserRoles.COMPANY_ADMIN;

    const requests = [
      isSuperAdmin && fetchCompanies(),
      isCompanyAdmin && fetchBranches(profile?.companyId || ''),
    ];
    return await Promise.all(requests);
  };

  useEffect(() => {
    if (profile) {
      fetchFilterOption();
    }
  }, [profile]);

  return { companies, branches };
};
export default useFetchFilterOptions;
