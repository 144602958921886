
import { ReportLayout } from '@/components';
import { TableSortOrders } from '@/components/Table/types';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { PaginatedReport, Report } from '@/types/report.type';

import React, { Dispatch, FC, SetStateAction } from 'react';
import * as Styled from './style';

type Props = {
  report: PaginatedReport | Report;
  pageNumber: number;
  order: TableSortOrders;
  orderBy: string;
  setQuery: Dispatch<SetStateAction<{ page: number; perPage: number }>>;
  setSorting: (orderBy: string | undefined, order: string | undefined) => void;
};

const RunningReport: FC<Props> = ({ report, pageNumber, order, orderBy, setQuery, setSorting }) => {
  const handleUpdateRunningQuery = (query: {
    page: number;
    perPage: number;
    orderBy: string | undefined;
    order: string | undefined;
    type: string;
  }) => {
    setQuery(query);
    setSorting(query.orderBy, query.order);
  };
  const { t } = useCustomTranslation('');

  return (
    <Styled.Container>
      {report ? (
        <>
          <Styled.Header>
            <Styled.HeaderTitle>
              {report && 'data' in report ? report?.data.name : report.name}
            </Styled.HeaderTitle>
          </Styled.Header><Styled.ReportWrapper id={`report-${'data' in report ? report?.data.id : report.id}`}>
            <ReportLayout
              structure={report && 'data' in report ? report?.data.structure || [] : report.structure || []}
              reportName={report && 'data' in report ? report?.data.name : report.name}
              reportId={Number(report && 'data' in report ? report.data.id : report.id)}
              tableResultsCount={report && 'data' in report ? report?.count : undefined}
              pageNumber={pageNumber}
              readonly
              sortable={false}
              order={order}
              orderBy={orderBy}
              handleUpdateRunningQuery={handleUpdateRunningQuery} />
          </Styled.ReportWrapper>
        </>
      ) : (
        <div>
          <p>{t('ERROR.REQUEST')}</p>
        </div>
      )}
    </Styled.Container>
  );
};

export default withPageLoader(RunningReport);
