import { ReactComponent as DefaultAvatar } from '@/assets/icons/default_avatar.svg';
import { ReactComponent as PlusButtonIcon } from '@/assets/icons/plus.svg';
import { ModalFormButton } from '@/components/Modal/style';
import { Form } from 'formik';
import styled from 'styled-components';

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TableHeaderButton = styled.div`
  width: 160px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
`;

export const CompanyForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormButton = styled(ModalFormButton)``;

export const PlusIcon = styled(PlusButtonIcon)`
  margin-right: 9px;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
`;

export const Avatar = styled(DefaultAvatar)`
  min-width: 36px;
  min-height: 36px;
  margin-right: 10px;
`;

export const CellText = styled.p``;
