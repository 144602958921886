import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CompanySelect from './Components/CompanySelect/CompanySelect';
import StickyMenu from './Components/StickyMenu/StickyMenu';
import ProjectStatus from './ProjectStatus';
import * as Styled from './style';

const ProjectStatusContainer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [projectID, setProjectID] = useState(queryParams.get('project'));
  const { t } = useCustomTranslation('');

  useEffect(() => {
    const newProjectID = queryParams.get('project');
    if (newProjectID !== projectID) {
      setProjectID(newProjectID);
    }
  }, [location.search, projectID, queryParams]);

  return (
    <>
      {projectID && (
        <StickyMenu />
      )}
      <div style={{padding: '40px'}}>
        <Styled.TitleContainer>
          <Styled.H2Heading>{t('PROJECT_STATUS.TITLE')}</Styled.H2Heading>
        </Styled.TitleContainer>
        {projectID ? (
          <ProjectStatus projectID={projectID}/>
        ) : (
          <CompanySelect />
        )}
      </div>
    </>
  );
};

export default ProjectStatusContainer;
