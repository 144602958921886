import React, { FC } from 'react';
import { useMatches } from 'react-router-dom';
import * as Styled from './style';
interface Handle {
  crumb: () => JSX.Element;
}

const visibility = (index: number, arrLength: number): string => index === arrLength ? 'visible' : 'semi-visible';

const Breadcrumbs: FC = () => {
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => Boolean((match.handle as Handle)?.crumb))
    .map((match) => (match.handle as Handle).crumb());

  return (
    <Styled.CrumbList data-testid="crumb-list">
      {crumbs.map((crumb, index) => (
        <Styled.CrumbItem key={index} data-testid="crumb-item">
          {index ? <Styled.ArrowIcon data-testid="crumb-arrow" visibility={visibility(index, crumbs.length)} /> : null}
          {crumb}
        </Styled.CrumbItem>
      ))}
    </Styled.CrumbList>
  );
};

export default Breadcrumbs;
