import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import * as Styled from './style';

interface ProportionalBarProps {
  numbers: number[];
}

const ProportionalBar: FC<ProportionalBarProps> = ({ numbers }) => {
  const total = numbers.reduce((a, b) => a + b, 0);

  let percentages = [];
  if (total !== 0) {
    percentages = numbers.map(number => (number / total) * 100);
  } else {
    percentages = numbers.map(() => 0);
  }

  const { prefixedT } = useCustomTranslation('COMPONENTS.PROPORTIONAL_BAR');

  return (
    <>

      {total === 0 ? (
        <div>
          <Styled.errorMsg>{prefixedT('NO_LEAKS')}</Styled.errorMsg>
        </div>
      ) : (
        <>
          <Styled.KeyWrapper>
            <Styled.keyTitle>{prefixedT('LEAK_RESULTS')}</Styled.keyTitle>
            <Styled.keyContainer>
              <Styled.key><Styled.Green />{prefixedT('LEAK_SIZE.SMALL')}</Styled.key>
              <Styled.key><Styled.Yellow />{prefixedT('LEAK_SIZE.MEDIUM')}</Styled.key>
              <Styled.key><Styled.Red />{prefixedT('LEAK_SIZE.LARGE')}</Styled.key>
            </Styled.keyContainer>
          </Styled.KeyWrapper>
          <Styled.Container>
            {percentages.map((percentage, index) => (
              <Styled.Numbers percentage={percentage} colorKey={index} key={index}>
                {Math.round(percentage)}%
              </Styled.Numbers>
            ))}
          </Styled.Container>
        </>
      )}
    </>
  );
};

export default ProportionalBar;
