import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableComponent = styled.table`
  min-width: 100%;
`;

export const Wrapper = styled.div``;
