import theme from '@/theme';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { LoaderType } from './Loader';

const getBaseColor = (type: LoaderType) => {
  switch (type) {
    case LoaderType.dark:
      return theme.colors.mediumBlue;
    case LoaderType.light:
      return theme.colors.white;
    default:
      return theme.colors.mediumBlue;
  }
};

const rotateRight = keyframes`
 0% { transform: rotate(0) }
 100% { transform: rotate(1turn) }
`;

const rotateLeft = keyframes`
 0% { transform: rotate(0) }
 100% { transform: rotate(-2turn) }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div<{
  type: LoaderType;
}>`
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid ${({ type }) => getBaseColor(type)};
  border-radius: 100%;
  animation: ${rotateRight} 800ms linear infinite;

  &:before,
  &:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid
      ${({ type }) => getBaseColor(type)};
    border-radius: 100%;
    animation: ${rotateLeft} 800ms linear infinite;
  }

  &:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid ${({ type }) => getBaseColor(type)};
    animation: none;
  }
`;

export const ContentLoaderWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

export const SplashScreenWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: ${theme.colors.blue};
`;
