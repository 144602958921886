import DateRange from '@/components/DateRange/DateRange';
import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import { extractData } from '@/containers/DeliveryPerformanceLE/utils/utils';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DayKey, DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import { useGetDeliveryPerformanceDropdownQuery } from '@/redux/reports/reports.api';
import { Field, Form, Formik } from 'formik';
import React, { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import BreadCrumbContainer from './Components/BreadcrumbContainer/BreadcrumbContainer';
import * as Styled from './style';


const DeliveryPerformanceFilters: FC = () => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.DELIVERY_PERFORMANCE_FITLERS');
  const location = useLocation();
  const {
    dayDropdownActiveValue,
    setDayDropdownActiveValue,
    projectActiveValue,
    setProjectActiveValue,
    engineerActiveValue,
    setEngineerActiveValue,
    dmaActiveValue,
    setDmaActiveValue,
    dateFilters,
    setDateFilters,
  } = useContext(DeliveryPerformanceContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get('project') as string;
    const projectName = queryParams.get('project-name') as string;
    setProjectActiveValue({value: Number(projectId), name: projectName});
  }, [location.search]);

  const { data: dropdown } = useGetDeliveryPerformanceDropdownQuery();

  const { dropdownProjects, dropdownEngineers, dropdownDmas } = extractData(dropdown);

  const handleLink = (option: DropDownOption, keyValue: string): void => {
    if (keyValue === 'EngineerWorkingDays') {
      setDayDropdownActiveValue(option.label as DayKey);
    }
    if (keyValue === 'projects') {
      setProjectActiveValue({
        value: Number(option.value),
        name: option.label,
      });
    }

    if (keyValue === 'engineers') {
      setEngineerActiveValue({
        value: Number(option.value),
        name: option.label,
      });
    }

    if (keyValue === 'DMAs') {
      setDmaActiveValue({
        value: Number(option.value),
        name: option.label,
      });
    }
  };

  const dropdownEngineerWorkingDays = [];

  for (let i = 1; i <= 7; i++) {
    const dayKey = `day${i}` as DayKey;
    dropdownEngineerWorkingDays.push({ name: dayKey });
  }
  dropdownEngineerWorkingDays.push({ name: 'total' });

  const ValidationSchema = Yup.object().shape({
    dateFrom: Yup.date().required('Date is required'),
    dateTo: Yup.date().required('Date is required'),
  });

  return (
    <Styled.Wrapper>
      <Styled.RowOne>
        <Styled.FilterContainer>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={ValidationSchema}
            onSubmit={() => {}}
            initialValues={dateFilters}
            enableReinitialize
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <Field
                    as={DateRange}
                    name="dateRange"
                    dateRangeBtn
                    onChange={(dateRange: Date[]) => {
                      const dateFrom =
                        new Date(dateRange[0]) < new Date(dateRange[1]) ? dateRange[0] : dateRange[1];
                      const dateTo =
                        new Date(dateRange[0]) > new Date(dateRange[1]) ? dateRange[0] : dateRange[1];

                      setFieldValue('dateFrom', dateFrom);
                      setFieldValue('dateTo', dateTo);

                      if (dateFrom !== dateTo) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        setDateFilters({ dateFrom, dateTo });
                      }
                    }}
                    value={[values.dateFrom, values.dateTo]}
                    disableFuture
                  />
                </div>
              </Form>
            )}
          </Formik>

          <Styled.DropdownWrapper>
            <DropDown
              value={projectActiveValue.value.toString()}
              options={dropdownProjects.map((item) => ({
                value: item.id.toString(),
                label: item.name,
              }))}
              onChange={(value) => handleLink(value as DropDownOption, 'projects')}
              placeholder={prefixedT('PLACEHOLDERS.PROJECTS')}
            />
          </Styled.DropdownWrapper>

          <Styled.DropdownWrapper>
            <DropDown
              value={engineerActiveValue.toString()}
              options={dropdownEngineers.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => handleLink(value as DropDownOption, 'engineers')}
              placeholder={prefixedT('PLACEHOLDERS.ENGINEERS')}
            />
          </Styled.DropdownWrapper>

          <Styled.DropdownWrapper>
            <DropDown
              value={dmaActiveValue.toString()}
              options={dropdownDmas.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => handleLink(value as DropDownOption, 'DMAs')}
              placeholder={'DMAs'}
            />
          </Styled.DropdownWrapper>
          <Styled.DropdownWrapper>
            <DropDown
              value={dayDropdownActiveValue}
              options={dropdownEngineerWorkingDays.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
              onChange={(value) =>
                handleLink(value as DropDownOption, 'EngineerWorkingDays')
              }
              placeholder={dayDropdownActiveValue}
            />
          </Styled.DropdownWrapper>
        </Styled.FilterContainer>
      </Styled.RowOne>
      <BreadCrumbContainer />
    </Styled.Wrapper>
  );
};

export default DeliveryPerformanceFilters;
