import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import useCustomTranslation from '@/localization/useCustomTranslation';
import {
  useGetConsumptionProfileInformationQuery,
  useGetDropdownQuery
} from '@/redux/reports/reports.api';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import WaypointHeadings from '../Waypoint/Components/WaypointHeadings/WaypointHeadings';
import * as Styled from './style';

const ConsumptionProfileContainer: FC = () => {
  const location = useLocation();
  const { prefixedT } = useCustomTranslation('CONSUMPTION_PROFILE_PAGE');
  const [waypointID, setWaypointID] = useState<string>('');
  const [consumptionProfileID, setConsumptionProfileID] = useState<string>('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedConsumptionProfileID = queryParams.get('consumptionProfile') as string;
    const waypoint = queryParams.get('waypoint') as string;
    setConsumptionProfileID(updatedConsumptionProfileID);
    setWaypointID(waypoint);
  }, [location.search]);

  const { data: dropDownData } = useGetDropdownQuery(waypointID);
  const { data: consumptionProfileData } =
    useGetConsumptionProfileInformationQuery(consumptionProfileID);

  return (
    <div style={{ padding: '40px' }}>
      {dropDownData && (
        <WaypointHeadings
          correlationIds={dropDownData.correlation_ids}
          consumptionProfileIds={dropDownData.consumption_profile_ids}
          topSoundIds={dropDownData.topsound_ids}
          waypointID={waypointID}
          sessionIds={dropDownData.session_ids}
          active='Consumption Profile'
        />
      )}

      {consumptionProfileData && (
        <>
          <Styled.ListContainer>
            <Styled.ListTitle>{prefixedT('CONSUMPTION_INFO.TITLE')}</Styled.ListTitle>
            <Styled.List>
              {consumptionProfileData?.id && (
                <li>
                  {prefixedT('CONSUMPTION_INFO.CONSUMPTION_PROFILING')}:{' '}
                  <Styled.ListObject>{consumptionProfileData?.id}</Styled.ListObject>
                </li>
              )}
              {consumptionProfileData?.start_time && (
                <li>
                  {prefixedT('CONSUMPTION_INFO.START_TIME')}:{' '}
                  <Styled.ListObject>{consumptionProfileData?.start_time}</Styled.ListObject>
                </li>
              )}
              {consumptionProfileData?.end_time && (
                <li>
                  {prefixedT('CONSUMPTION_INFO.END_TIME')}:{' '}
                  <Styled.ListObject>{consumptionProfileData?.end_time}</Styled.ListObject>
                </li>
              )}
              {consumptionProfileData?.expected_samples && (
                <li>
                  {prefixedT('CONSUMPTION_INFO.EXPECTED_SAMPLES')}:{' '}
                  <Styled.ListObject>{consumptionProfileData?.expected_samples}</Styled.ListObject>
                </li>
              )}
              {consumptionProfileData?.uploaded_samples && (
                <li>
                  {prefixedT('CONSUMPTION_INFO.UPLOADED_SAMPLES')}:{' '}
                  <Styled.ListObject>{consumptionProfileData?.uploaded_samples}</Styled.ListObject>
                </li>
              )}
            </Styled.List>
            <Styled.ListTitleContainer>
              <Styled.ListTitle>{prefixedT('LOCATION_INFO.TITLE')}</Styled.ListTitle>
              <Styled.List>
                {consumptionProfileData?.collected_engineer && (
                  <li>
                    {prefixedT('LOCATION_INFO.COLLECTED_ENGINEER')}:{' '}
                    <Styled.ListObject>
                      {consumptionProfileData?.collected_engineer}
                    </Styled.ListObject>
                  </li>
                )}
                {consumptionProfileData?.deployed_engineer && (
                  <li>
                    {prefixedT('LOCATION_INFO.DEPLOYED_ENGINEER')}:{' '}
                    <Styled.ListObject>
                      {consumptionProfileData?.deployed_engineer}
                    </Styled.ListObject>
                  </li>
                )}
                {consumptionProfileData?.company && (
                  <li>
                    {prefixedT('LOCATION_INFO.COMPANY')}:{' '}
                    <Styled.ListObject>{consumptionProfileData?.company}</Styled.ListObject>
                  </li>
                )}
                {consumptionProfileData?.location && (
                  <li>
                    {prefixedT('LOCATION_INFO.LOCATION')}:{' '}
                    <Styled.ListObject>{consumptionProfileData?.location}</Styled.ListObject>
                  </li>
                )}
                {consumptionProfileData?.what3words && (
                  <li>
                    {prefixedT('LOCATION_INFO.WHAT3WORDS')}:{' '}
                    <Styled.ListObject>{consumptionProfileData?.what3words}</Styled.ListObject>
                  </li>
                )}
                {consumptionProfileData?.postcode && (
                  <li>
                    {prefixedT('LOCATION_INFO.POSTCODE')}:{' '}
                    <Styled.ListObject>{consumptionProfileData?.postcode}</Styled.ListObject>
                  </li>
                )}
              </Styled.List>
            </Styled.ListTitleContainer>
          </Styled.ListContainer>

          <Styled.MapWrapper>
            <GenerateMap data={consumptionProfileData?.map_info.data} readonly={true} />
          </Styled.MapWrapper>
        </>
      )}
    </div>
  );
};

export default ConsumptionProfileContainer;
