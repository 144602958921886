/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import * as Styled from '../style';
import { PointTooltipType } from '../utils/types';

type Props = {
  tooltip: string;
};

export const PointTooltip: FC<Props> = (props) => {
  const tooltip: PointTooltipType = JSON.parse(props.tooltip);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<string>('');

  const tooltipRef = useRef<HTMLDivElement>(null);
  const screenWidth = useRef(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      screenWidth.current = window.innerWidth;
      if (tooltipRef.current && screenWidth.current) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const tooltipLeft = tooltipRect.left;

        if (tooltipLeft <= screenWidth.current / 2) {
          setTooltipPosition('left');
        } else {
          setTooltipPosition('right');
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const viewModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Styled.ToolTipContainer ref={tooltipRef}>
      <>
        {tooltip.Address && (
          <Styled.Title>
            <span>Address: </span>
            {tooltip.Address}
          </Styled.Title>
        )}
        {tooltip.Engineer && (
          <Styled.Title>
            <span>Engineer: </span>
            {tooltip.Engineer}
          </Styled.Title>
        )}
        {tooltip.Waypoint_id && (
          <Styled.Title>
            <span>Waypoint Id: </span>
            {tooltip.Waypoint_id}
          </Styled.Title>
        )}
        {tooltip.Waypoint_name && (
          <Styled.Title>
            <span>Waypoint Name: </span>
            {tooltip.Waypoint_name}
          </Styled.Title>
        )}
        {tooltip.fft_graph && (
          <Styled.Button type="submit" onClick={viewModal}>
            {isModalOpen ? 'Close Graph' : 'Open Graph'}
          </Styled.Button>
        )}
      </>

      {isModalOpen && (
        <>
          {tooltip.fft_graph && (
            <Styled.ImageContainer position={tooltipPosition}>
              <img data-testid="graphImage" width="300px" height="300px" alt="graph" src={tooltip.fft_graph} />
            </Styled.ImageContainer>
          )}
        </>
      )}
    </Styled.ToolTipContainer>
  );
};

const renderPointTooltip = (tooltip: string, container: HTMLElement) => {
  ReactDOM.render(<PointTooltip tooltip={tooltip} />, container);
};

export default renderPointTooltip;
