/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useDecodeTokenMutation } from '@/redux/user/user.api';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IDecodedData } from './Recovery';

export const useDecode = (): IDecodedData => {
  const { token = '' } = useParams();
  const navigate = useNavigate();
  const [onDecode, { status, data }] = useDecodeTokenMutation();

  useEffect(() => {
    if (token) {
      onDecode({ token });
    }
  }, [token]);

  useEffect(() => {
    if (status === QueryStatus.rejected) {
      navigate('/auth/recovery/expired');
    }
  }, [status]);

  return data;
};
