import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Wrapper = styled.div`
    width: 350px;
    display: flex;
    align-items: center;
    border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.celestialBlue};
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.frenchBlue};
`;

export const List = styled.ul`
    list-style-type: none;
    line-height: 40px;
    width: 100%;
`;

export const ListItem = styled.li`
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
`;
