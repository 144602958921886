

import React from 'react';

const EnFlag: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15" height="22px">
    <rect width="25" height="15" fill="#FFF"/>
    <g fill="#CE1124">
      <rect width="3" height="15" x="11"/>
      <rect width="25" height="3" y="6"/>
    </g>
  </svg>
);

export default EnFlag;
