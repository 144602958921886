import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Menu = styled.div`
  display: flex;
  gap: 20px;
`;

export const FilterContainer = styled.div`
  position: absolute;
  width: max-content;
  min-width: 275px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border-radius: 5px;
  z-index: 1;
  margin-top: 5px;
  border: 1px solid #d7d7d7;
`;

export const FilterWrapper = styled.div`
position: relative;
`;
