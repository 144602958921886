import { t } from 'i18next';

export const getTitle = (dialogState: string) => {
  if (dialogState === 'change') {
    return t('MAP_VIEW.DIALOG.TITLE.CHANGE');
  }
  return t('MAP_VIEW.DIALOG.TITLE.CLOSE');
};

export const getSubTitle = (dialogState: string) => {
  if (dialogState === 'change') {
    return t('MAP_VIEW.DIALOG.SUBTITLE.CHANGE');
  }
  return t('MAP_VIEW.DIALOG.SUBTITLE.CLOSE');
};
