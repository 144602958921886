import { useGetProfileQuery } from '@/redux/user/user.api';
import { GuardComponent, IReactChildren } from '@/types/common.type';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface PermissionGuardProps {
  roles?: string[];
  children: IReactChildren;
}

// eslint-disable-next-line react/prop-types
const PermissionGuard: GuardComponent<PermissionGuardProps> = ({ children, roles = [] }) => {
  const { data: profile, isLoading } = useGetProfileQuery();

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (!roles.includes(`${profile?.role?.code}`)) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default PermissionGuard;
