import { AuthLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import React from 'react';
import * as Styled from './style';

const RecoveryExpired = () => {
  const { prefixedT } = useCustomTranslation('RECOVERY_EXPIRED_PAGE');

  return (
    <AuthLayout>
      <Styled.Wrapper data-testid="recoveryExpired">
        <Styled.LogoContainer />
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
        <Styled.SubTitle>{prefixedT('SUBTITLE')}</Styled.SubTitle>
      </Styled.Wrapper>
    </AuthLayout>
  );
};

export default RecoveryExpired;
