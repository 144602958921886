import { Loader } from '@/components';
import DateView from '@/components/DateView/DateView';
import DeliveryPerformanceFilters from '@/components/DeliveryPerformanceFilters/DeliveryPerformanceFilters';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { DeliveryPerformanceContext } from '@/Providers/DeliveryPerformanceProvider';
import {
  useGetDeliveryPerformanceBugDeploymentEngineerMutation,
  useGetDeliveryPerformanceDropdownQuery
} from '@/redux/reports/reports.api';
import { BugDeploymentDayInfo } from '@/types/report.type';
import React, { FC, useContext, useEffect, useState } from 'react';
import DescContainer from '../DeliveryPerformanceLE/Components/DescContainer/DescContainer';
import { QueryParams, extractEngineerIds } from '../DeliveryPerformanceLE/utils/utils';
import * as Styled from './style';

const DeliveryPerformanceBDEContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation('DELIVERY_PERFORMANCE_PAGE');
  const { data: dropdown } = useGetDeliveryPerformanceDropdownQuery();
  const {
    dayDropdownActiveValue,
    projectActiveValue,
    dmaActiveValue,
    engineerActiveValue,
    dateFilters,
  } = useContext(DeliveryPerformanceContext);

  const [dropdownEngineers, setDropdownEngineers] = useState<(string | number)[]>([]);

  const [mutate, { data: bugDeploymentData, isLoading, isError }] =
    useGetDeliveryPerformanceBugDeploymentEngineerMutation();

  useEffect(() => {
    setDropdownEngineers(extractEngineerIds(dropdown));
  }, [dropdown]);

  useEffect(() => {
    mutate({
      body: engineerActiveValue.value ? [engineerActiveValue.value] : dropdownEngineers,
      query: QueryParams(
        projectActiveValue.value,
        dmaActiveValue.value,
        dateFilters
      ),
    });
  }, [mutate, projectActiveValue, dmaActiveValue, engineerActiveValue, dateFilters, dropdownEngineers]);

  const data: (BugDeploymentDayInfo | null)[] =
    bugDeploymentData?.map((item) => item.info[dayDropdownActiveValue] || null) || [];

  return (
    <Styled.Wrapper>
      <DeliveryPerformanceFilters />
      {isLoading && (
        <Styled.MainContainer data-testid="Loader">
          <Loader />
        </Styled.MainContainer>
      )}
      {isError && (
        <Styled.MainContainer>
          <p>{t('ERROR.REQUEST')}</p>
        </Styled.MainContainer>
      )}
      {!data || data.length < 1 && !isLoading && !isError && (
        <Styled.MainContainer>
          <p>{t('ERROR.NO_DATA')}</p>
        </Styled.MainContainer>
      )}
      {data && data.length > 0 && (
        <Styled.DescWrapper>
          {bugDeploymentData &&
            data.map((item, index) => (
              <>
                {item && (
                  <Styled.DeliveryPerformanceContainer>
                    <Styled.NameContainer key={index}>
                      <p>{prefixedT('NAME')}</p>
                      <Styled.NameWrapper>
                        <p>{bugDeploymentData[index].name}</p>
                      </Styled.NameWrapper>
                    </Styled.NameContainer>
                    <Styled.OptionValueContainer key={index}>
                      <Styled.DateContainer>
                        {item.start_time && <DateView date={item.start_time} startDate={true} />}
                        {item.start_time && <DateView date={item.end_time} startDate={false} />}
                      </Styled.DateContainer>
                      <Styled.OptionContainers>
                        <DescContainer
                          title={prefixedT('KM_COVERED')}
                          description={item.KM_covered}
                        />
                        <DescContainer
                          title={prefixedT('COLLECTIONS')}
                          description={item.collections}
                        />
                        <DescContainer
                          title={prefixedT('DEPLOYMENT_ACCURACY')}
                          description={item.deployment_accuracy}
                        />
                        <DescContainer
                          title={prefixedT('DEPLOYMENTS')}
                          description={item.deployments}
                        />
                        {item.hours_worked && (
                          <DescContainer
                            title={prefixedT('HOURS_WORKED')}
                            description={Math.round(item.hours_worked)}
                          />
                        )}
                      </Styled.OptionContainers>
                    </Styled.OptionValueContainer>
                  </Styled.DeliveryPerformanceContainer>
                )}
              </>
            ))}
        </Styled.DescWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default DeliveryPerformanceBDEContainer;
