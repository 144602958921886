import { Button, TextField } from '@/components';
import { AuthLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IErrorData } from '@/types/rtk.type';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import { ValidationSchema } from './schema';
import * as Styled from './style';

export interface ForgotPasswordValues {
  email: string;
}

interface ForgotPasswordProps {
  onSubmit: (data: ForgotPasswordValues) => void;
  error: IErrorData | null;
  initialValues: ForgotPasswordValues;
  status: QueryStatus;
}

const ForgotPassword: FC<ForgotPasswordProps> = ({ onSubmit, initialValues, status }) => {
  const { t, prefixedT } = useCustomTranslation('FORGOT_PASSWORD_PAGE');

  const renderForm = () => (
    <>
      <Styled.SubTitle>{prefixedT('SUBTITLE')}</Styled.SubTitle>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors }) => (
          <Styled.Form>
            <Styled.Fields>
              <Field
                type="email"
                name="email"
                as={TextField}
                label={prefixedT('FORM.EMAIL.LABEL')}
                placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
                error={errors.email && t(errors.email)}
                testId="email"
              />
            </Styled.Fields>
            <Styled.ButtonContainer>
              <Button width="100%" height="50px" type="submit" testId="submit">
                {prefixedT('FORM.SUBMIT')}
              </Button>
            </Styled.ButtonContainer>
          </Styled.Form>
        )}
      </Formik>
    </>
  );

  return (
    <AuthLayout>
      <Styled.Wrapper data-testid="forgot-pass-form">
        <Styled.LogoContainer />
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
        {status !== QueryStatus.fulfilled ? renderForm() : <ForgotPasswordSuccess />}
      </Styled.Wrapper>
    </AuthLayout>
  );
};

export default ForgotPassword;
