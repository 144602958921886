
import { useLazyGetCompaniesQuery } from '@/redux/company/company.api';
import {
  useGetProfileQuery,
  useGetRolesQuery,
  useGetUserQuery
} from '@/redux/user/user.api';
import { ICompany } from '@/types/company.type';
import { UserRoles } from '@/types/user.type';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useFetchUserDetails = () => {
  const { id: userId = '' } = useParams();
  const { data: userDetails, isLoading, isFetching } = useGetUserQuery(userId);
  const { data: profile } = useGetProfileQuery();

  const [fetchCompanies, companiesResult] = useLazyGetCompaniesQuery();
  const rolesResult = useGetRolesQuery();

  const companies = (companiesResult.data || []) as ICompany[];
  const roles = (rolesResult.data || []);
  const isOwnProfile = useMemo(() => Number(userId) === Number(profile?.id), [profile, userId]);
  const isSuperAdmin = useMemo(() => profile?.role?.code === UserRoles.SUPER_ADMIN, [profile]);

  const fetchExtraDetails = async () => {
    const requests = [isSuperAdmin && fetchCompanies()];
    return await Promise.all(requests);
  };

  useEffect(() => {
    fetchExtraDetails();
  }, [isSuperAdmin]);

  return {
    companies: isSuperAdmin ? companies : [(userDetails?.company as ICompany) || {}],
    roles,
    user: userDetails,
    isOwnProfile,
    profile,
    companiesResult,
    rolesResult,
    userResult: { isLoading, isFetching },
  };
};

export default useFetchUserDetails;
