import withPageLoader from '@/HOCs/withPageLoader';
import { useDashboardContext } from '@/Providers/DashboardProvider';
import { RootState } from '@/redux';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import CompanyDashboard from './Components/CompanyDashboard/CompanyDashboard';
import PartnerDashboard from './Components/PartnerDashboard/PartnerDashboard';
import { IDashboardInfo } from './DashboardContainer';
import * as Styled from './style';


type DashboardInfoProps = {
  dashboardInfo: IDashboardInfo[];
};

type DashboardProps =
Partial<DashboardInfoProps>;

const Dashboard: FC<DashboardProps> = ({
  dashboardInfo,
}) => {
  const { search } = useDashboardContext();

  const activeAccountState = useSelector((state: RootState) => state.activeAccount.currentAccount);

  const filteredDashboardInfo = dashboardInfo?.filter(dashboard =>
    dashboard.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Styled.ColumnWrapper type={activeAccountState?.type} data-testid="dashboardPage">
      {filteredDashboardInfo?.map((dashboard, key) => (
        <React.Fragment key={key}>
          {activeAccountState?.type === 'company' ? (
            <CompanyDashboard dashboard={dashboard} />
          ) : (
            <PartnerDashboard dashboard={dashboard} />
          )}
        </ React.Fragment>
      ))}
    </Styled.ColumnWrapper>
  );
};

export default withPageLoader(Dashboard);
