/* eslint-disable @typescript-eslint/no-shadow */

import { ContentLoader } from '@/components';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import { useGetLogsQuery } from '@/redux/log/log.api';
import { ILog, ILogFilters } from '@/types/log.type';
import { IPaginationResponse } from '@/types/rtk.type';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ChangeLog from './ChangeLog';
import { IFilterValues } from './Filters/Filters';
import useFetchFilterOptions from './useFetchFilterOptions';

const ChangeLogContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const pageParam = searchParams.get('page');
  const perPageParam = searchParams.get('perPage');

  const [page, setPage] = useState<number>(Number(pageParam) || 1);
  const [perPage] = useState<number>(Number(perPageParam) || 10);
  const [filters, setFilter] = useState<ILogFilters>({});

  const { companies, branches } = useFetchFilterOptions();
  const [order, setOrder] = useState<TableSortOrders>(TableSortOrders.asc);
  const [orderBy, setOrderBy] = useState<string>('time');
  const logsQuery = useGetLogsQuery(
    {
      page,
      perPage,
      ...filters,
      date: filters.date ? format(Date.parse(filters.date), 'P') : '',
      sortedBy: orderBy,
      sortedOrder: order,
    },
    { refetchOnMountOrArgChange: true }
  );
  const logs = logsQuery.data as IPaginationResponse<ILog>;

  useEffect(() => {
    setSearchParams({ page: String(page) });
  }, [page]);

  const onPageChange = (p: ITablePaginate) => {
    setPage(p.selected + 1);
  };

  const onFilter = (values: IFilterValues) => {
    setFilter(values as ILogFilters);
  };
  const setSorting = (orderBy: string, order: string) => {
    setOrder(order as TableSortOrders);
    setOrderBy(orderBy);
  };

  return (
    <>
      <ContentLoader active={logsQuery.data && logsQuery.isFetching} />
      <ChangeLog
        pages={Math.ceil((logs?.count || 1) / perPage)}
        onPageChange={onPageChange}
        page={page}
        totalCount={logs?.count || 0}
        logs={logs?.data || []}
        onFilter={onFilter}
        filters={filters}
        loading={logsQuery.isLoading}
        companies={companies}
        branches={branches}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </>
  );
};

export default ChangeLogContainer;
