import Checkbox from '@/components/Checkbox/Checkbox';
import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { animated, useSpring } from 'react-spring';
import * as Styled from './style';

type FilterGroupProps = {
  title: string;
  filterKey: string;
  filterOptions: Option[];
};

type Option = {
  label: string;
  formName: string;
};

const FilterGroup: React.FC<FilterGroupProps> = ({ title, filterKey, filterOptions }) => {
  const [toggleFilter, setToggleFilter] = useState(false);

  const { transform } = useSpring({
    transform: `rotate(${toggleFilter ? 90 : 0}deg)`,
    config: { tension: 210, friction: 20 },
  });

  const handleToggle = () => setToggleFilter(!toggleFilter);

  return (
    <>
      <Styled.Title onClick={handleToggle}>
        <animated.span style={{ display: 'inline-block', transform, marginRight: '10px' }}>
          <FaChevronRight size={11} />
        </animated.span>
        {title}
      </Styled.Title>
      {toggleFilter && (
        <Styled.Container>
          {filterOptions.map((option: Option, index: number) => (
            <Styled.CheckboxContainer key={index}>
              <Checkbox formName={`${filterKey}.${option.formName}`} label={option.label} />
            </Styled.CheckboxContainer>
          ))}
        </Styled.Container>
      )}
    </>
  );
};

export default FilterGroup;
