import useCustomTranslation from '@/localization/useCustomTranslation';
import { HistoryLog } from '@/types/report.type';
import React, { FC } from 'react';
import * as Styled from './style';

interface HistoryContainerProps {
  historyLog: HistoryLog[];
}

const HistoryContainer: FC<HistoryContainerProps> = ({ historyLog }) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.HISTORY_CONTAINER');
  return (
    <Styled.Wrapper>
      <h3>{prefixedT('TITLE')}</h3>
      <Styled.HistoryLogWrapper>
        {historyLog.map((history: HistoryLog, key: number) => (
          <Styled.ListContainer key={key}>
            <Styled.List>
              {history.id != null && (
                <li>
                  {prefixedT('ID')}: <Styled.ListObject>{history.id}</Styled.ListObject>
                </li>
              )}
              {history.changed_to_status_name && (
                <li>
                  {prefixedT('STATUS_NAME')}:{' '}
                  <Styled.ListObject>{history.changed_to_status_name}</Styled.ListObject>
                </li>
              )}
              {history.datetime && (
                <li>
                  {prefixedT('DATETIME')}: <Styled.ListObject>{history.datetime}</Styled.ListObject>
                </li>
              )}
              {history.notes && (
                <li>
                  {prefixedT('NOTES')}: <Styled.ListObject>{history.notes}</Styled.ListObject>
                </li>
              )}
              {history.reference_waypoint && (
                <li>
                  {prefixedT('REFERENCE_WAYPOINT')}:{' '}
                  <Styled.ListObject>{history.reference_waypoint}</Styled.ListObject>
                </li>
              )}
              {history.reference_waypoint_id != null && (
                <li>
                  {prefixedT('REFERENCE_WAYPOINT_ID')}:{' '}
                  <Styled.ListObject>{history.reference_waypoint_id}</Styled.ListObject>
                </li>
              )}
              {history.scheduled_date && (
                <li>
                  {prefixedT('SCHEDULED_DATE')}: <Styled.ListObject>{history.scheduled_date}</Styled.ListObject>
                </li>
              )}
              {history.status_changed_from_id != null && (
                <li>
                  {prefixedT('STATUS_CHANGED_FROM_ID')}:{' '}
                  <Styled.ListObject>{history.status_changed_from_id}</Styled.ListObject>
                </li>
              )}
              {history.user_id != null && (
                <li>
                  {prefixedT('USER_ID')}: <Styled.ListObject>{history.user_id}</Styled.ListObject>
                </li>
              )}
              {history.user_name && (
                <li>
                  {prefixedT('USER_NAME')}: <Styled.ListObject>{history.user_name}</Styled.ListObject>
                </li>
              )}
              {history.waypoint_id != null && (
                <li>
                  {prefixedT('WAYPOINT_ID')}: <Styled.ListObject>{history.waypoint_id}</Styled.ListObject>
                </li>
              )}
              {history.waypoint_name && (
                <li>
                  {prefixedT('WAYPOINT_NAME')}: <Styled.ListObject>{history.waypoint_name}</Styled.ListObject>
                </li>
              )}
            </Styled.List>
          </Styled.ListContainer>
        ))}
      </Styled.HistoryLogWrapper>
    </Styled.Wrapper>
  );
};

export default HistoryContainer;
