import { IBranch } from './branch.type';
import { ICompany } from './company.type';
import { IRole } from './user.type';
export interface ILog {
  id: string | number;
  action: LogAction;
  email: string;
  status: LogStatus;
  name: string;
  role: IRole;
  companyId: string | number;
  company?: ICompany;
  branchId: string | number;
  branch?: IBranch;
  date: string;
  time: string;
}

export interface ILogFilters {
  action?: LogAction;
  email?: string;
  status?: LogStatus;
  companyId?: number | string;
  branchId?: number | string;
  date?: string;
}

export enum LogStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum LogAction {
  CREATE_COMPANY = 'CREATE_COMPANY',
  GET_COMPANY = 'GET_COMPANY',
  EDIT_COMPANY = 'EDIT_COMPANY',
  LOGIN = 'LOGIN',
  TOKEN_DECODE = 'TOKEN_DECODE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  SET_PASSWORD = 'SET_PASSWORD',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
}
