import i18n from '@/localization/i18n';
import { InitialRequestState } from '@/types/request.type';
import { getTranslationFormatNameFromAction } from '@/utils/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialRequestState = {};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | number>) => {
      const tKey = `SERVER_ERRORS.${action.payload}`;
      const message = i18n.exists(tKey) ? i18n.t(tKey) : i18n.t('SERVER_ERRORS.-1');
      state.error = {
        code: action.payload,
        message,
      };
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      const type = action.payload;
      const tKey = `SERVER_SUCCESS.${getTranslationFormatNameFromAction(type)}`;
      if (!i18n.exists(tKey)) {
        return;
      }
      state.success = {
        type: getTranslationFormatNameFromAction(type),
        message: i18n.t(tKey),
      };
    },
  },
});

export const { setError, setSuccess } = requestSlice.actions;
export default requestSlice.reducer;
