import { ReactComponent as ArrowDown } from '@/assets/icons/arrow_down.svg';
import { Theme } from '@/theme';
import { motion } from 'framer-motion';
import { animated } from 'react-spring';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;
export const Wrapper = styled.div`
  .rdrDayNumber {
    position: absolute;
    display: flex;
  }
`;

export const PickerContainer = styled(motion.div)<{
  config: {
    isOverflow: boolean | undefined;
    width: number;
    top: number;
    left: number;
    isPortal: boolean | undefined;
  };
}>`
  ${({ config }) =>
    config.isPortal
      ? `
    top: ${config.top + 16}px;
    left: ${config.left - config.width - 22}px;
  `
      : `
    top: 100%;
    left: 0;
  `}
  position: absolute;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  z-index: 9999;
  border-radius: 0 0 8px 8px;
`;

export const ClickableArea = styled.div<{
  disabled: boolean;
  error: boolean;
  button: boolean;
}>`
  width: ${({ button }) => (button ? '200px' : 'calc(100% - 26px)')};
  height: 40px;
  border: 1px solid
    ${({ theme, error }: ThemeProps<Theme> & { error: boolean }) =>
    theme.colors[error ? 'coral' : 'lightGray']};
  border-radius: 8px;
  background-color: ${({ theme, disabled }: ThemeProps<Theme> & { disabled: boolean }) =>
    theme.colors[disabled ? 'disabled' : 'white']};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  user-select: none;
  position: relative;
`;

export const ArrowIcon = styled(animated.div)<{ isOpen: boolean }>`
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #555 transparent transparent transparent;
  transition: transform 0.3s;
  transform: rotateX(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
`;

export const ClickableAreaIndicator = styled(ArrowDown)<{
  open: boolean;
  disabled?: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
`;

export const ClickableAreaText = styled.p<{
  isPlaceholder: boolean;
  disabled?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 30px);
  font-size: 14px;
  line-height: 17px;
  color: ${({
    theme,
    isPlaceholder,
    disabled,
  }: ThemeProps<Theme> & { isPlaceholder: boolean; disabled?: boolean }) =>
    theme.colors[isPlaceholder || disabled ? 'gray' : 'mediumDark']};
`;

export const Label = styled.span<{
  error?: boolean;
}>`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${({ error, theme }: ThemeProps<Theme> & { error?: boolean }) =>
    theme.colors[error ? 'coral' : 'deepDark']};
  margin-bottom: 8px;
`;

export const Error = styled.p`
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.coral};
  font-size: 10px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
`;
