import React, { FC } from 'react';
import * as Styled from './style';

export enum LoaderType {
  light = 'light',
  dark = 'dark',
}

type Props = {
  type?: LoaderType;
};

const Loader: FC<Props> = ({ type = LoaderType.dark }) => (
  <Styled.Wrapper>
    <Styled.Container type={type} />
  </Styled.Wrapper>
);
export default Loader;
