import React, { FC, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import * as Styled from './style';

interface ReportFiltersProps {
  title: string;
  value: string;
  valueTitle?: string;
  icon?: boolean;
  onClick?: (value: string) => void;
}

const FilterBreadcrumbs: FC<ReportFiltersProps> = ({ value, valueTitle, title, icon, onClick }) => {
  const [hovered, setHovered] = useState(false);

  const springProps = useSpring({
    scale: hovered ? 1.1 : 1,
    config: { tension: 300, friction: 20 },
  });

  return (
    <Styled.Button
      onClick={() => {
        if (onClick) {
          onClick(value);
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Styled.Text>{title + (valueTitle ? valueTitle : value)}</Styled.Text>
      {icon && (
        <animated.svg
          style={{
            transform: springProps.scale.to((scale) => `scale(${scale})`),
            height: '9px',
            width: '9px',
            paddingLeft: '15px',
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon"
        >
          <line x1="18" y1="2" x2="2" y2="18" />
          <line x1="2" y1="2" x2="18" y2="18" />
        </animated.svg>
      )}
    </Styled.Button>
  );
};

export default FilterBreadcrumbs;
