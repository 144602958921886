import { AddCompanyValues, EditCompanyValues, ICompany } from '@/types/company.type';
import { IPaginationProps, IPaginationResponse, ISort } from '@/types/rtk.type';
import { IUser } from '@/types/user.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const companyApi = createApi({
  reducerPath: 'companyQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Company', 'Employee'],
  endpoints: (builder) => ({
    addCompany: builder.mutation({
      query: (body: AddCompanyValues) => ({
        url: '/companies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    editCompany: builder.mutation({
      query: ({ id, ...body }: EditCompanyValues) => ({
        url: `/companies/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    deleteCompany: builder.mutation({
      query: (id: number | string) => ({
        url: `/companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company'],
    }),
    getCompany: builder.query({
      query: (id: number | string) => `/companies/${id}`,
      providesTags: ['Company'],
    }),
    getCompanies: builder.query<
    IPaginationResponse<ICompany> | ICompany[],
    IPaginationProps & ISort | void
    >({
      query: (params) => `/companies?${buildQueryParams(params || {})}`,
      providesTags: (result) => {
        const data = (result as IPaginationResponse<ICompany>)?.data || result;
        return data
          ? [
            ...data.map(({ id }) => ({ type: 'Company' as const, id })),
            { type: 'Company', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'Company', id: 'PARTIAL-LIST' }];
      },
    }),
    getEmployees: builder.query<
    IPaginationResponse<IUser> | IUser[],
    (IPaginationProps & { companyId: string }) | void
    >({
        query: ({ companyId, ...params }: IPaginationProps & { companyId: string }) =>
          `/companies/${companyId}/users?${buildQueryParams(params || {})}`,
        providesTags: (result) => {
          const data = (result as IPaginationResponse<IUser>)?.data || result;
          return data
            ? [
              ...data.map(({ id }) => ({ type: 'Employee' as const, id })),
              { type: 'Employee', id: 'PARTIAL-LIST' },
            ]
            : [{ type: 'Employee', id: 'PARTIAL-LIST' }];
        },
      }),
  }),
});

export const {
  useAddCompanyMutation,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useEditCompanyMutation,
  useGetCompanyQuery,
  useGetEmployeesQuery,
  useDeleteCompanyMutation,
} = companyApi;
