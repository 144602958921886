import useCustomTranslation from '@/localization/useCustomTranslation';
import moment from 'moment';
import React, { FC } from 'react';
import * as Styled from './style';

type Props = {
  date: string;
  startDate: boolean;
};

const DateView: FC<Props> = ({
  date,
  startDate,
}) => {
  const { prefixedT } = useCustomTranslation('COMPONENTS.DATE_VIEWER');
  const parsedDate = moment(date);
  const year = parsedDate.format('YY');
  const formattedMonthDay = parsedDate.format('MMM DD');

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Title positive={startDate}>{startDate ? prefixedT('START_DATE') : prefixedT('END_DATE')}</Styled.Title>
      </Styled.TitleContainer>
      <Styled.DateContainer positive={startDate}>
        <Styled.Year>{year}</Styled.Year>
        <Styled.MonthAndDay>{formattedMonthDay}</Styled.MonthAndDay>
      </Styled.DateContainer>
    </Styled.Container>
  );
};
export default DateView;
