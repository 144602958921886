import useCustomTranslation from '@/localization/useCustomTranslation';
import { RootState } from '@/redux';
import { setActiveAccount } from '@/redux/activeAccount/activeAccount.slice';
import { useGetPortalPartnersQuery } from '@/redux/reports/reports.api';
import { useGetProfileQuery } from '@/redux/user/user.api';
import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './style';
import { HandleAccountSwitch } from './utils/HandleAccountSwitch';

type Company = {
  name: string;
  id: number;
};

type Partner = {
  id: number;
  name: string;
  companies: Company[];
};

export type Partners = Partner[];

type PortalPartners = {
  data: Partners;
};

const AccountSwitcher: FC = () => {
  const { prefixedT } = useCustomTranslation('COMPANIES_PAGE.ACCOUNT_SWITCHER');
  const { data: profile } = useGetProfileQuery();
  const navigate = useNavigate();
  const queryResult = useGetPortalPartnersQuery({
    refetchOnMountOrArgChange: true,
  }) as PortalPartners;

  const data: Partners | undefined = queryResult.data;

  const activeAccount = useSelector((state: RootState) => state.activeAccount.currentAccount);

  const dispatch = useDispatch();

  const location = useLocation();

  React.useEffect(() => {
    HandleAccountSwitch(data, activeAccount, profile, dispatch, navigate);
  }, [location]);

  const [open, setOpen] = useState(false);

  const accountSwitcherRef = useRef(null);

  const changeHandler = (id: number, name: string, type: 'partner' | 'company') => {
    dispatch(setActiveAccount({
      id,
      name,
      type,
    }));
    const newUrl = `${window.location.pathname}?${type}=${id}`;
    navigate(newUrl, { replace: true });
    setOpen(false);
  };

  const onClickHandler = () => setOpen(!open);

  const [hoveredPartner, setHoveredPartner] = useState<Partner | null>(null);

  const listContainerRef = useRef<HTMLDivElement>(null);
  const [hoveredItemTop, setHoveredItemTop] = useState(0);

  const handleMouseEnter = (partner: Partner) => {
    setHoveredPartner(partner);
    const accountItem = listContainerRef.current?.querySelector(`#partnerItem-${partner.id}`);
    if (accountItem) {
      const itemTop = accountItem.getBoundingClientRect().top;
      setHoveredItemTop(itemTop - 137);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    if (!listContainerRef.current?.contains(e.relatedTarget as Node)) {
      setHoveredPartner(null);
      setHoveredItemTop(0);
    }
  };

  return (
    <>
      {data && (
        <Styled.Container>
          <Styled.ActiveAccountWrapper onClick={onClickHandler} ref={accountSwitcherRef}>
            <Styled.ActiveAccount>{activeAccount?.name}</Styled.ActiveAccount>
            <Styled.ArrowDownIcon open={open} />
          </Styled.ActiveAccountWrapper>
          <Styled.AccountList open={open}>
            <div onMouseLeave={handleMouseLeave}>
              <Styled.ListContainer ref={listContainerRef}>
                <Styled.TitleWrapper>
                  <p>{prefixedT('PARTNERS')}</p>
                </Styled.TitleWrapper>
                {data?.map((partner: Partner) => (
                  <div
                    key={partner.id}
                    id={`partnerItem-${partner.id}`}
                    onMouseEnter={() => handleMouseEnter(partner)}
                  >
                    <Styled.AccountItem data-testid="partner-btn" onClick={() => changeHandler(partner.id, partner.name, 'partner')}>
                      <Styled.ItemWrapper>{partner.name}</Styled.ItemWrapper>
                      {partner.companies.length > 0 && <Styled.CheckLanguageIcon />}
                    </Styled.AccountItem>
                  </div>
                ))}
              </Styled.ListContainer>
              {hoveredPartner && hoveredPartner?.companies.length > 0 && (
                <Styled.CompanyListContainer style={{ top: hoveredItemTop }}>
                  <Styled.TitleWrapper>
                    <p>{prefixedT('COMPANIES')}</p>
                  </Styled.TitleWrapper>
                  <div>
                    {hoveredPartner.companies.map((company: Company) => (
                      <Styled.AccountItem
                        onClick={() => changeHandler(company.id, company.name, 'company')}
                        key={company.id}
                      >
                        <Styled.ItemWrapper>
                          <div>{company.name}</div>
                        </Styled.ItemWrapper>
                      </Styled.AccountItem>
                    ))}
                  </div>
                </Styled.CompanyListContainer>
              )}
            </div>
          </Styled.AccountList>

        </Styled.Container>
      )}
    </>
  );
};

export default AccountSwitcher;
