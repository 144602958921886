import { ReactComponent as Avatar } from '@/assets/icons/avatar.svg';
import { ReactComponent as Super } from '@/assets/icons/super.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AvatarSection = styled.div``;

export const AvatarIconWrapper = styled.div<{
  mobile?: boolean;
}>`
  max-width: 32px;
  max-height: 32px;
  position: relative;
  & > :first-child path {
    fill: ${({ theme, mobile }: ThemeProps<Theme> & { mobile?: boolean }) => (!mobile ? theme.colors.blue : theme.colors.white)};
  }
`;

export const AvatarIcon = styled(Avatar)`
  min-width: 32px;
  min-height: 32px;
`;

export const SuperIcon = styled(Super)`
  min-width: 19px;
  min-height: 19px;
  position: absolute;
  top: calc(100% - 17px);
  right: calc(100% - 17px);
`;

export const InfoSection = styled.div<{
  mobile?: boolean;
}>`
  margin-left: ${({ mobile }) => (!mobile ? '20px' : '12px')};
  min-width: 0;
  width: calc(100% - 70px);
`;

export const Name = styled.h3<{
  mobile?: boolean;
}>`
  font-weight: 500;
  font-size: ${({ mobile }) => (!mobile ? '14px' : '16px')};
  color: ${({ theme, mobile }: ThemeProps<Theme> & { mobile?: boolean }) => (!mobile ? theme.colors.deepDark : theme.colors.white)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Role = styled.p<{
  mobile?: boolean;
}>`
  font-weight: 400;
  font-size: ${({ mobile }) => (!mobile ? '10px' : '12px')};
  color: ${({ theme, mobile }: ThemeProps<Theme> & { mobile?: boolean }) => (!mobile ? theme.colors.mediumDark : theme.colors.white)};
  white-space: nowrap;
`;

export const ProfileWrapper = styled.div<{
  isLoader: boolean;
}>`
  ${({ isLoader }) =>
    isLoader
      ? `
    height: auto;
  `
      : `
    height: 10px;
    margin-top: 4px;
  `}
  width: 100%;
`;
