import { DangerDialog } from '@/components';
import { Modal } from '@/components/Modal';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import withPageLoader from '@/HOCs/withPageLoader';
import { ModalStatus } from '@/hooks/useModalStatus';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { AddCompanyValues, ICompany } from '@/types/company.type';
import React, { FC } from 'react';
import { AddCompany } from './AddCompany';
import { CompanyTable } from './CompanyTable';

interface CompaniesProps {
  addCompanyModal: ModalStatus;
  deleteCompanyModal: ModalStatus;
  addCompany: (data: AddCompanyValues) => void;
  companies: ICompany[];
  page: number;
  pages: number;
  onPageChange: (p: ITablePaginate) => void;
  totalCount: number;
  onCompanyEdit: (company: ICompany) => void;
  deleteCompany: () => void;
  deleteConfirmation: (company: ICompany) => void;
  isAddCompanyLoading: boolean;
  setSorting: (orderBy: string, order: string) => void;
  order: TableSortOrders;
  orderBy: string;
}

const Companies: FC<CompaniesProps> = ({
  addCompanyModal,
  addCompany,
  deleteCompany,
  deleteConfirmation,
  companies,
  onCompanyEdit,
  onPageChange,
  page,
  pages,
  totalCount,
  deleteCompanyModal,
  isAddCompanyLoading,
  setSorting,
  order,
  orderBy,
}) => {
  const { prefixedT } = useCustomTranslation('COMPANIES_PAGE');

  return (
    <div data-testid="companiesPage">
      <Modal
        isOpen={addCompanyModal.isOpen}
        title={prefixedT('ADD_COMPANY.TITLE')}
        onClose={addCompanyModal.hide}
      >
        <AddCompany onSubmit={addCompany} isLoading={isAddCompanyLoading} />
      </Modal>
      <DangerDialog
        title={prefixedT('DELETE_COMPANY.TITLE')}
        open={deleteCompanyModal.isOpen}
        onCancel={deleteCompanyModal.hide}
        onConfirm={deleteCompany}
      />
      <CompanyTable
        companies={companies}
        onPageChange={onPageChange}
        onEdit={onCompanyEdit}
        onDelete={deleteConfirmation}
        page={page}
        pages={pages}
        totalCount={totalCount}
        addCompanyModal={addCompanyModal}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </div>
  );
};

export default withPageLoader(Companies);
