import { ReactComponent as Avatar } from '@/assets/icons/avatar.svg';
import { ReactComponent as Camera } from '@/assets/icons/camera_icon.svg';
import { ReactComponent as Super } from '@/assets/icons/super.svg';
import { PageHeaderTitle, PageHeaderWrapper } from '@/layouts/AppLayout/style';
import { Theme } from '@/theme';
import { getGridItemWidth } from '@/utils/style';
import styled, { ThemeProps } from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled(PageHeaderWrapper)``;

export const Title = styled(PageHeaderTitle)``;

export const MobileTabsContainer = styled.div`
  display: none;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: block;
  }
`;

export const MobileTabTitle = styled(PageHeaderTitle)`
  margin-bottom: 12px;
`;

export const MobileOptionsWrapper = styled.div`
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  border-radius: 8px;
  padding: 20px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
`;

export const ProfileWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    'info options options'
    'info options options'
    'details options options';
  gap: 20px;

  grid-template-columns: ${getGridItemWidth(3, 20)};

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.l}) {
      grid-template-columns: ${getGridItemWidth(1, 20)};
      grid-template-areas:
        'info'
        'options'
        'details';
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: flex;
    flex-direction: column;
  }
}
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const Info = styled.div`
  grid-area: info;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;

  height: 365px;
`;

export const Details = styled.div`
  grid-area: details;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  border-radius: 8px;
  padding: 20px;

  height: 200px;
`;

export const Options = styled.div`
  grid-area: options;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  border-radius: 8px;
  padding: 30px;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.l}) {
    padding: 20px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const AvatarIcon = styled(Avatar)`
  min-width: 120px;
  min-height: 120px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CameraIcon = styled(Camera)`
  min-width: 48px;
  min-height: 48px;
  position: absolute;
  top: calc(100% - 58px);
  left: calc(100% - 48px);
  cursor: pointer;
`;

export const Separator = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  margin: 20px 0;
`;

export const ProfileNameContainer = styled.div`
  position: relative;
  text-align: center;
`;

export const ProfileName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;

export const SuperIcon = styled(Super)`
  min-width: 30px;
  min-height: 30px;
  position: absolute;
  top: calc(100% - 36px);
  right: calc(100% - 36px);
`;

export const ProfileRole = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;
