import { Button, DropDown, Switch, TextField, UploadFile } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { ICompany } from '@/types/company.type';
import { IRole, UserRoles } from '@/types/user.type';
import { validateFormWithProps } from '@/utils/form';
import { checkRestictedPermissions, filterRolesAccordingToUserRole } from '@/utils/user';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

export interface CreateUserForm {
  branchId: string | number | null;
  companyId: string | number | null;
  roleId: string | number;
  email: string;
  firstName: string;
  lastName: string;
  isBulk: boolean;
  bulk: null | File;
}

interface CreateUserProps {
  onSubmit: (data: CreateUserForm) => void;
  onRoleSelect?: (roleId: number) => void;
  onCompanySelect?: (companyId: number) => void;
  onBranchSelect?: (branchId: number) => void;
  companies: ICompany[];
  roles: IRole[];
  userCreateLoading: boolean;
}

const initialValues: CreateUserForm = {
  branchId: '',
  companyId: '',
  roleId: '',
  email: '',
  firstName: '',
  lastName: '',
  isBulk: false,
  bulk: null,
};

const companyRestictedPermissions = [UserRoles.SUPER_ADMIN];
const branchRestictedPermissions = [UserRoles.SUPER_ADMIN, UserRoles.COMPANY_ADMIN];

const CreateUser: FC<CreateUserProps> = ({
  onSubmit,
  companies = [],
  roles = [],
  userCreateLoading,
}) => {
  const { data: profile } = useGetProfileQuery();

  const { t, prefixedT } = useCustomTranslation('USERS_PAGE.ADD_USER');
  const isCompaniesDisabled = profile?.role?.code !== UserRoles.SUPER_ADMIN;

  if (isCompaniesDisabled) {
    initialValues.companyId = profile?.companyId || '';
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      validate={(values) => validateFormWithProps(values, ValidationSchema, { roles })}
    >
      {({ errors, values, setFieldValue, dirty, setErrors }) => {
        const selectedCompany = companies.find(({ id }) => Number(id) === Number(values.companyId));
        const branches = selectedCompany?.branches;
        const selectedRole = roles.find((el) => el.id === values.roleId);

        const handleChangeMode = () => {
          setFieldValue('isBulk', !values.isBulk);
          setFieldValue('email', '');
          setFieldValue('firstName', '');
          setFieldValue('lastName', '');
          setErrors({});
        };

        return (
          <Styled.CompanyForm data-testid="add-user-form">
            <Field
              name="roleId"
              as={DropDown}
              label={prefixedT('FORM.ROLE.LABEL')}
              error={errors.roleId && t(errors.roleId)}
              options={filterRolesAccordingToUserRole(roles, profile?.role?.code || '').map(
                (el) => ({ value: el.id, label: el.role })
              )}
              placeholder={prefixedT('FORM.ROLE.PLACEHOLDER')}
              onChange={(option: DropDownOption) => setFieldValue('roleId', option.value)}
              testId="user-roles"
            />
            {selectedRole &&
              !checkRestictedPermissions(companyRestictedPermissions, selectedRole?.code) && (
              <Field
                name="companyId"
                as={DropDown}
                disabled={isCompaniesDisabled}
                label={prefixedT('FORM.COMPANY.LABEL')}
                error={errors.companyId && t(errors.companyId)}
                options={companies.map((el) => ({ value: el.id, label: el.name }))}
                placeholder={prefixedT('FORM.COMPANY.PLACEHOLDER')}
                onChange={(option: DropDownOption) => setFieldValue('companyId', option.value)}
                testId="user-company"
              />
            )}
            {selectedRole &&
              !checkRestictedPermissions(branchRestictedPermissions, selectedRole?.code) && (
              <Field
                name="branchId"
                as={DropDown}
                label={prefixedT('FORM.BRANCH.LABEL')}
                error={errors.branchId && t(errors.branchId)}
                disabled={!branches?.length}
                options={(branches || []).map((el) => ({ value: el.id, label: el.name }))}
                placeholder={prefixedT('FORM.BRANCH.PLACEHOLDER')}
                onChange={(option: DropDownOption) => setFieldValue('branchId', option.value)}
                testId="user-branches"
              />
            )}
            <Styled.BulkContainer>
              <Styled.SwitchWrapper>
                <Field
                  name="isBulk"
                  as={Switch}
                  label="Bulk insert"
                  checked={values.isBulk}
                  onChange={handleChangeMode}
                />
              </Styled.SwitchWrapper>
              {values.isBulk && (
                <Field
                  name="bulk"
                  as={UploadFile}
                  placeholder="upload file .csv"
                  value={values.bulk}
                  onChange={(file: File) => setFieldValue('bulk', file)}
                  error={errors.bulk && t(errors.bulk)}
                  extentions={['.csv']}
                  onClear={() => setFieldValue('bulk', null)}
                />
              )}
            </Styled.BulkContainer>
            {values.isBulk && (
              <Styled.DownloadTemplate href="/csv/users.csv">
                <Styled.DownloadTemplateIcon />
                <Styled.DownloadTemplateText>Download Template</Styled.DownloadTemplateText>
              </Styled.DownloadTemplate>
            )}
            {!values.isBulk && (
              <>
                <Field
                  name="firstName"
                  as={TextField}
                  label={prefixedT('FORM.FIRST_NAME.LABEL')}
                  placeholder={prefixedT('FORM.FIRST_NAME.PLACEHOLDER')}
                  error={errors.firstName && t(errors.firstName)}
                  testId="user-firstName"
                />
                <Field
                  name="lastName"
                  as={TextField}
                  label={prefixedT('FORM.LAST_NAME.LABEL')}
                  placeholder={prefixedT('FORM.LAST_NAME.PLACEHOLDER')}
                  error={errors.lastName && t(errors.lastName)}
                  testId="user-lastName"
                />
                <Field
                  name="email"
                  as={TextField}
                  label={prefixedT('FORM.EMAIL.LABEL')}
                  placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
                  error={errors.email && t(errors.email)}
                  testId="user-email"
                />
              </>
            )}
            <Styled.FormButton>
              <Button
                loading={userCreateLoading}
                disabled={!dirty}
                width="100%"
                height="100%"
                type="submit"
                testId="user-submit"
              >
                <Styled.ButtonContent>
                  <Styled.PlusIcon />
                  {prefixedT('FORM.SUBMIT')}
                </Styled.ButtonContent>
              </Button>
            </Styled.FormButton>
          </Styled.CompanyForm>
        );
      }}
    </Formik>
  );
};

export default CreateUser;
