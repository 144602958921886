import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

interface GetDashboardParams {
  dateFrom: string;
  dateTo: string;
  partnerID?: string | null;
  companyID?: string | null;
}

export const dashboardApi = createApi({
  reducerPath: 'dashboardQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['DashboardInfo'],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (params: GetDashboardParams) => `/dashboard?${buildQueryParams(params || {})}`,
      providesTags: ['DashboardInfo'],
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApi;
