import { Table } from '@/components';
import {
  ITableHeader,
  ITablePaginate,
  TableCellAlignment,
  TableSortOrders
} from '@/components/Table/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { useGetProfileQuery } from '@/redux/user/user.api';
import { ILog } from '@/types/log.type';
import { UserRoles } from '@/types/user.type';
import { getRowIndex } from '@/utils/table';
import { UserRole } from '@/utils/user';
import React, { FC, useMemo } from 'react';

const headers: ITableHeader[] = [
  { id: 'number', title: 'NUMBER', sortable: false, align: TableCellAlignment.left },
  { id: 'name', title: 'NAME', sortable: true, align: TableCellAlignment.center },
  { id: 'email', title: 'EMAIL', sortable: true, align: TableCellAlignment.center },
  {
    id: 'action',
    title: 'ACTION',
    sortable: true,
    align: TableCellAlignment.center,
  },
  { id: 'role_name', title: 'ROLE', sortable: true, align: TableCellAlignment.center },
  {
    id: 'status',
    title: 'STATUS',
    sortable: true,
    align: TableCellAlignment.center,
  },
  {
    id: 'company_name',
    title: 'COMPANY',
    sortable: true,
    align: TableCellAlignment.center,
  },
  {
    id: 'branch_name',
    title: 'BRANCH',
    sortable: true,
    align: TableCellAlignment.center,
  },
  { id: 'date', title: 'DATE', sortable: true, align: TableCellAlignment.center },
  { id: 'time', title: 'TIME', sortable: false, align: TableCellAlignment.center },
];

interface IProps {
  page: number;
  totalCount: number;
  logs: ILog[];
  pages: number;
  order: TableSortOrders;
  orderBy: string;
  onPageChange: (p: ITablePaginate) => void;
  setSorting: (orderBy: string, order: string) => void;
}

const LogTable: FC<IProps> = ({
  page,
  totalCount,
  logs,
  pages,
  order,
  orderBy,
  onPageChange,
  setSorting,
}) => {
  const { t, prefixedT } = useCustomTranslation('CHANGE_LOG');
  const { data: profile } = useGetProfileQuery();
  const isCompanyAdmin = profile?.role?.code === UserRoles.COMPANY_ADMIN;

  const tableHeaders = useMemo(() => {
    if (profile?.role?.code === UserRoles.COMPANY_ADMIN) {
      return headers.filter((el) => el.id !== 'company');
    }
    return headers;
  }, [profile?.role?.code]);

  const getRowStructure = (row: ILog, index: number) => (
    <Table.Row key={row.id}>
      <Table.Cell align={TableCellAlignment.left} headerId="number">
        {getRowIndex(page, index)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="name">
        {row.name}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="email">
        {row.email}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="action">
        {prefixedT(`ACTIONS.${row.action}`)}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="role">
        {t(UserRole[row.role?.code as keyof typeof UserRole])}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="status">
        {prefixedT(`STATUSES.${row.status}`)}
      </Table.Cell>
      {!isCompanyAdmin && (
        <Table.Cell align={TableCellAlignment.center} headerId="company">
          {row?.company?.name || ''}
        </Table.Cell>
      )}
      <Table.Cell align={TableCellAlignment.center} headerId="branch">
        {row?.branch?.name || ''}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="date">
        {row.date}
      </Table.Cell>
      <Table.Cell align={TableCellAlignment.center} headerId="time">
        {row.time}
      </Table.Cell>
    </Table.Row>
  );

  const getHeaderStructure = (header: ITableHeader) => prefixedT(`TABLE.${header.title}`);
  const headerControl = <></>;

  return (
    <>
      <Table.Header
        title={
          !totalCount
            ? prefixedT('TITLE.NO_LOGS')
            : prefixedT('TITLE.TOTAL_COUNT', { count: totalCount })
        }
        controls={headerControl}
      />
      {Boolean(totalCount) && (
        <>
          <Table
            data={logs}
            order={order}
            orderBy={orderBy as keyof ILog}
            headers={tableHeaders}
            onSort={setSorting}
          >
            <Table.Head getHeaderStructure={getHeaderStructure} />
            <Table.Body getRowStructure={getRowStructure} striped />
          </Table>
          <Table.Pagination pages={pages} onChange={onPageChange} initialPage={page} />
        </>
      )}
    </>
  );
};

export default LogTable;
