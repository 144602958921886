import { useTranslation } from 'react-i18next';

const useCustomTranslation = (customPrefix: string) => {
  const { t } = useTranslation();

  const prefixedT = (path: string, options?: any) => t(`${customPrefix}.${path}`, options || {});

  return { t, prefixedT };
};

export default useCustomTranslation;
