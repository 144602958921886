import { GetListResponse } from '@/types/portalCompany.type';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const portalCompaniesApi = createApi({
  reducerPath: 'portalCompaniesQuery',
  baseQuery: apiBaseQuery,
  // eslint-disable-next-line max-len
  tagTypes: [
    'List',
  ],
  endpoints: (builder) => ({
    getList: builder.query<GetListResponse[], void>({
      query: () => '/portal_companies/list',
      providesTags: ['List'],
    }),
  }),
});

export const {
  useGetListQuery,
} = portalCompaniesApi;
