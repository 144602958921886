import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageSelector, Profile } from '../index';
import * as Styled from './style';

interface HeaderProps {
  sidebarControl: () => void;
}

const Header: FC<PropsWithChildren<HeaderProps>> = ({}) => {
  const location = useLocation();
  const { prefixedT } = useCustomTranslation('HEADER');
  let title;
  const path = location.pathname;

  switch (true) {
    case path.includes('delivery-performance-LE'):
      title = prefixedT('DPLE');
      break;
    case path.includes('delivery-performance-BDE'):
      title = prefixedT('DPBDE');
      break;
    case path.includes('dashboard'):
      title = prefixedT('DASHBOARD');
      break;
    case path.includes('project-status'):
      title = prefixedT('PROJECT_STATUS');
      break;
    case path.includes('companies'):
      title = prefixedT('COMPANIES');
      break;
    case path.includes('users'):
      title = prefixedT('USERS');
      break;
    case path.includes('reports'):
      title = prefixedT('REPORTS');
      break;
    case path.includes('logs'):
      title = prefixedT('LOGS');
      break;
    default:
      title = '';
      break;
  }

  return (
    <Styled.Container data-testid="header-container">
      <Styled.MenuSection>
        {/* <Styled.Icon data-testid="header-icon" onClick={sidebarControl} /> */}
        <div>
          <Styled.headerHeading>{title}</Styled.headerHeading>
        </div>
        <Styled.MobileSeparator />
      </Styled.MenuSection>
      <Styled.MainSection>
        <Styled.Separator />
        <LanguageSelector />
        <Styled.Separator />
        <Styled.ProfileContainer>
          <Profile />
        </Styled.ProfileContainer>
      </Styled.MainSection>
    </Styled.Container>
  );
};

export default Header;
