import React from 'react';
import { Loader } from '../components';

type WithLoadingProps = {
  loading?: boolean;
};

const withPageLoader = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> => {
  const WrappedComponent: React.FC<P & WithLoadingProps> = ({ loading, ...props }) =>
    loading ? <Loader /> : <Component {...(props as P)} />;

  WrappedComponent.displayName = `withPageLoader(${Component.displayName || Component.name})`;

  return WrappedComponent;
};

export default withPageLoader;
