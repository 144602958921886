/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import GenerateMap from '@/components/ReportLayout/widgets/MapView/GenerateMap';
import { MapElement } from '@/components/ReportLayout/widgets/MapView/utils/types';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FilterContext } from '@/Providers/FilterProvider';
import { RootState } from '@/redux';
import { useProjectStatusMutation } from '@/redux/reports/reports.api';
import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import Summary from './Components/Summary/Summary';
import * as Styled from './style';
import { concatenateMapData, requestBody } from './utils';

interface ProjectStatusProps {
  projectID: string;
}

const ProjectStatus: FC<ProjectStatusProps> = ({ projectID }) => {
  const [mutate, { data, isLoading, isError }] = useProjectStatusMutation();

  const { mapFilters, dateFilters, filterAttributes, search } = useContext(FilterContext);

  const activeAccount = useSelector((state: RootState) => state.activeAccount.currentAccount);

  React.useEffect(() => {
    mutate({ body: requestBody(mapFilters, dateFilters, filterAttributes), query: {
      branch_id: projectID,
    } });
  }, [mutate, mapFilters, dateFilters, filterAttributes, activeAccount]);

  let combinedMapData: MapElement[] = [];

  if (!isLoading && data) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    combinedMapData = concatenateMapData(data);
  }

  if (isError) {
    localStorage.removeItem('mapFilters');
    localStorage.removeItem('filterAttributes');
  }

  const { t } = useCustomTranslation('');

  const filteredSummary = data?.dmas.filter(dashboard =>
    dashboard.id.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Styled.Container>
      {isLoading ? (
        <Styled.LoadingText>{t('MAP_VIEW.LOADING')}</Styled.LoadingText>
      ) : (
        <>
          {isError ? (
            <div>
              <p>
                {t('ERROR.REQUEST')}
              </p>
            </div>
          ) : (
            <div>
              <Styled.MapContainer data-testid="map-container">
                <GenerateMap
                  data={combinedMapData}
                  readonly={true}
                  modal={true}
                  showMapFilters={true}
                />
              </Styled.MapContainer>
              <div>
                {filteredSummary?.map((summary, key) => (
                  <div key={key}>
                    <Summary summary={summary}  />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </Styled.Container>
  );
};

export default ProjectStatus;
