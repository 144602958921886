import { Button, TextField } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IBranch } from '@/types/branch.type';
import { Field, Formik } from 'formik';
import React, { FC } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

export interface EditBranchForm {
  name: string;
}

interface EditBranchProps {
  branch: IBranch;
  onSubmit: (data: EditBranchForm) => void;
}

const EditBranch: FC<EditBranchProps> = ({ onSubmit, branch }) => {
  const { t, prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE.EDIT_BRANCH');
  const initialValues: EditBranchForm = {
    name: branch.name,
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({ errors, dirty }) => (
        <Styled.CompanyForm>
          <Field
            name="name"
            as={TextField}
            label={branch.name}
            placeholder={prefixedT('FORM.NAME.PLACEHOLDER')}
            error={errors.name && t(errors.name)}
          />
          <Styled.FormButton>
            <Button disabled={!dirty} width="100%" height="100%" type="submit">
              <Styled.ButtonContent>{prefixedT('FORM.SUBMIT')}</Styled.ButtonContent>
            </Button>
          </Styled.FormButton>
        </Styled.CompanyForm>
      )}
    </Formik>
  );
};

export default EditBranch;
