/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import ExpandIcon from '@/assets/icons/expand.png';
import SatelliteIcon from '@/assets/icons/satellite-icon.png';
import StreetIcon from '@/assets/icons/street-icon.png';
import React, { FC } from 'react';
import { MAP_STYLE, MAP_STYLE_SATELLITE } from '../../utils/config';
import * as Styled from './style';

interface FilterMapViewProps {
  map: any;
  onMapSizeToggle: () => void;
}

const FilterMapView: FC<FilterMapViewProps> = ({ map, onMapSizeToggle }) => {
  const toggleMapStyle = (mapView: string) => {
    if (mapView === 'satellite') {
      map.current.setStyle(MAP_STYLE_SATELLITE);
    } else {
      map.current.setStyle(MAP_STYLE);
    }
  };

  const toggleMapSize = () => {
    onMapSizeToggle();
  };

  return (
    <Styled.Container>
      <Styled.ExpandButton onClick={toggleMapSize}>
        <img width={20} height={20} alt="Expand Icon" src={ExpandIcon} />
      </Styled.ExpandButton>
      <Styled.Button onClick={() => toggleMapStyle('satellite')}>
        <img width={20} height={20} alt="satellite icon" src={SatelliteIcon} />
      </Styled.Button>
      <Styled.Button onClick={() => toggleMapStyle('street')}>
        <img width={20} height={20} alt="street icon" src={StreetIcon} />
      </Styled.Button>
    </Styled.Container>
  );
};

export default FilterMapView;
