import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';
import * as Styled from './style';

type ExceptionsProps = {
  value: number | undefined;
};

const Exceptions: FC<ExceptionsProps> = ({ value }) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE');
  const amount = value || 0;
  return (
    <Styled.ExceptionTab value={amount}>
      <p>{amount}</p>
      <p>{prefixedT('SUMMARY.EXCEPTIONS.SUBTITLE')}</p>
    </Styled.ExceptionTab>
  );
};

export default Exceptions;
