import { Button, TextField } from '@/components';
import { AuthLayout } from '@/layouts';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { ILoginValues } from '@/types/auth.type';
import { IErrorData } from '@/types/rtk.type';
import { Field, Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { ValidationSchema } from './schema';
import * as Styled from './style';

interface IProps {
  initialValues: ILoginValues;
  onSubmit: (values: ILoginValues, actions: FormikHelpers<ILoginValues>) => void;
  loginError: IErrorData | null;
}

const Login: FC<IProps> = ({ initialValues, onSubmit }) => {
  const { t, prefixedT } = useCustomTranslation('LOGIN_PAGE');

  return (
    <AuthLayout>
      <Styled.Wrapper data-testid="loginPage">
        <Styled.LogoContainer />
        <Styled.Title>{prefixedT('TITLE')}</Styled.Title>
        <Styled.SubTitle>{prefixedT('SUBTITLE')}</Styled.SubTitle>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ errors }) => (
            <Styled.Form data-testid="login-form">
              <Styled.Fields>
                <Field
                  type="email"
                  name="email"
                  as={TextField}
                  label={prefixedT('FORM.EMAIL.LABEL')}
                  placeholder={prefixedT('FORM.EMAIL.PLACEHOLDER')}
                  error={errors.email && t(errors.email)}
                  testId="login-email"
                />
                <Field
                  type="password"
                  name="password"
                  as={TextField}
                  label={prefixedT('FORM.PASSWORD.LABEL')}
                  placeholder={prefixedT('FORM.PASSWORD.PLACEHOLDER')}
                  error={errors.password && t(errors.password)}
                  testId="login-password"
                />
              </Styled.Fields>
              <Styled.ButtonContainer>
                <Styled.Wrapper>
                  <Button testId="submit-btn" width="100%" height="50px" type="submit">
                    {prefixedT('FORM.SUBMIT')}
                  </Button>
                </Styled.Wrapper>
                <Styled.ForgotLink to="/auth/forgot">
                  {prefixedT('FORGOT_PASSWORD')}
                </Styled.ForgotLink>
              </Styled.ButtonContainer>
            </Styled.Form>
          )}
        </Formik>
      </Styled.Wrapper>
    </AuthLayout>
  );
};
export default Login;
