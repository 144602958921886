import {
  useCreateUserMutation,
  useGetProfileQuery,
  useGetRolesQuery
} from '@/redux/user/user.api';
import { IRole, UserRoles } from '@/types/user.type';

export const useCreateEmployee = () => {
  const { data: user } = useGetProfileQuery();
  const rolesResult = useGetRolesQuery();
  const [createEmployee, { isLoading: employeeCreateLoading }] = useCreateUserMutation();
  const isSuperAdmin = (user?.role as IRole)?.code === UserRoles.SUPER_ADMIN;

  let roles = (rolesResult.data || []);
  if (!isSuperAdmin) {
    roles = roles.filter(({ code }) => code !== UserRoles.SUPER_ADMIN);
  }

  return {
    createEmployee,
    roles,
    employeeCreateLoading,
    isRolesLoading: rolesResult.isLoading,
  };
};
