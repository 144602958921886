import FunnelChartComponent from '@/components/FunnelChart/FunnelChart';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import ProportionalBar from '@/components/ProportionalBar/ProportionalBar';
import withPageLoader from '@/HOCs/withPageLoader';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IUser } from '@/types/user.type';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IDashboardInfo } from '../../DashboardContainer';
import Exceptions from '../Exceptions/Exceptions';
import { BuildFunnelChartData } from '../utils';
import WaterCost from '../WaterCost/WaterCost';
import PostProjectReportButton from './PostProjectReportButton';
import * as Styled from './style';

type UserInfoProps = {
  user: IUser;
};

type DashboardInfoProps = {
  dashboard: IDashboardInfo;
};

type DashboardProps = Partial<UserInfoProps> & Partial<DashboardInfoProps>;

const CompanyDashboard: FC<DashboardProps> = ({ dashboard }) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE');
  const funnelData = BuildFunnelChartData(
    dashboard?.summary?.leaks_identified?.value,
    dashboard?.summary?.number_scheduled?.value,
    dashboard?.summary?.number_marked?.value,
    dashboard?.summary?.number_repaired?.value
  );

  return (
    <>
      {dashboard && (
        <Styled.RowContainer>
          <Styled.ProjectTitleContainer>
            <Styled.ProjectName>{dashboard?.name}</Styled.ProjectName>
            <Styled.ProjectTimeline>{prefixedT('SUMMARY.TIMELINE.PROJECT')}</Styled.ProjectTimeline>
          </Styled.ProjectTitleContainer>
          <Styled.DashboardContainer>
            <Styled.RowOne>
              <div>
                <Styled.ProgressTab>
                  <ProgressBar
                    startDate={dashboard?.summary?.start_date?.value}
                    endDate={dashboard?.summary?.end_date?.value}
                    progress={dashboard?.summary?.percentage_complete?.value || 0}
                  />
                </Styled.ProgressTab>
              </div>
              <Styled.ProgressTab>
                <ProportionalBar
                  numbers={[
                    dashboard?.summary?.small_leaks?.value,
                    dashboard?.summary?.medium_leaks?.value,
                    dashboard?.summary?.large_leaks?.value,
                  ]}
                />
              </Styled.ProgressTab>
            </Styled.RowOne>
            <Styled.RowTwo>
              <div>
                <Styled.Subheading>{prefixedT('SUMMARY.FIDO_FUNNEL')}</Styled.Subheading>
                <FunnelChartComponent dimensions={300} data={funnelData} />
              </div>
              <WaterCost
                cost={dashboard?.summary?.cost_year?.value || 0}
                sessions={dashboard?.summary?.sessions_analised?.value || 0}
              />
              <div>
                <Styled.Subheading>{prefixedT('VOLUMETRICS.SUBTITLE')}</Styled.Subheading>
                <Styled.LeakageValue>
                  {dashboard?.summary?.total_leakage?.value}
                </Styled.LeakageValue>
                <Styled.LeakageUnit>ML/DAY</Styled.LeakageUnit>
              </div>
              <div>
                <Styled.ReportContainer>
                  <div>
                    <Styled.Subheading>{prefixedT('SUMMARY.REPORTS.TITLE')}</Styled.Subheading>
                    <Styled.ReportsTab>
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={`/app/project-status?project=${dashboard?.id}`}
                      >
                        <Styled.ReportLinks>
                          {prefixedT('SUMMARY.REPORTS.PROJECT')}
                        </Styled.ReportLinks>
                      </Link>
                    </Styled.ReportsTab>
                    <Styled.ReportsTab>
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={`/app/delivery-performance-BDE?project=${dashboard?.id}&project-name=${dashboard.name}`}
                      >
                        <Styled.ReportLinks>
                          {prefixedT('SUMMARY.REPORTS.DELIVERY')} BDE
                        </Styled.ReportLinks>
                      </Link>
                    </Styled.ReportsTab>
                    <Styled.ReportsTab>
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={`/app/delivery-performance-LE?project=${dashboard?.id}&project-name=${dashboard.name}`}
                      >
                        <Styled.ReportLinks>
                          {prefixedT('SUMMARY.REPORTS.DELIVERY')} LE
                        </Styled.ReportLinks>
                      </Link>
                    </Styled.ReportsTab>
                    <PostProjectReportButton projectId={dashboard.id} />
                  </div>
                  <div>
                    <Exceptions value={dashboard?.summary?.issues?.value} />
                  </div>
                </Styled.ReportContainer>
              </div>
            </Styled.RowTwo>
          </Styled.DashboardContainer>
        </Styled.RowContainer>
      )}
    </>
  );
};

export default withPageLoader(CompanyDashboard);
