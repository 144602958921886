import { FilterBreadcrumb } from '@/components';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { FilterAttributes, FilterContext, MapFilters } from '@/Providers/FilterProvider';
import moment from 'moment';
import React, { FC, useContext } from 'react';
import * as Styled from './style';

const BreadCrumbContainer: FC = () => {
  const { t, prefixedT } = useCustomTranslation('BREADCRUMB.FILTERS');
  const { dateFilters, filterAttributes, setFilterAttributes, mapFilters, setMapFilters } = useContext(FilterContext);

  const status = [
    ...Object.entries(filterAttributes.predicted_size)
      .filter(([, value]) => value === true)
      .map(([key]) => key),
  ];

  const predicted_size = [
    ...Object.entries(filterAttributes.status)
      .filter(([, value]) => value === true)
      .map(([key]) => key),
  ];

  const removeBreadcrumb = (value: string) => {
    const newFilterAttributes: FilterAttributes = JSON.parse(JSON.stringify(filterAttributes)) as FilterAttributes;
    if (value in newFilterAttributes.status) {
      (newFilterAttributes.status as Record<string, boolean>)[value] = false;
    } else if (value in newFilterAttributes.predicted_size) {
      (newFilterAttributes.predicted_size as Record<string, boolean>)[value] = false;
    }
    setFilterAttributes(newFilterAttributes);
  };

  const removeMapFilterBreadcrumb = (value: string) => {
    const newMapFilters: MapFilters = JSON.parse(JSON.stringify(mapFilters)) as MapFilters;

    const updatedMapFilters = newMapFilters.filters.filter((item) => item !== value);

    setMapFilters({filters: updatedMapFilters});
  };

  return (
    <Styled.BreadCrumbContainer>
      <FilterBreadcrumb
        title={prefixedT('DATE_FROM') + ': '}
        value={moment(dateFilters.dateFrom).format('YYYY-MM-DD')}
      />
      <FilterBreadcrumb
        title={prefixedT('DATE_TO') + ': '}
        value={moment(dateFilters.dateTo).format('YYYY-MM-DD')}
      />
      {status.map((value, key) => {
        const formattedValue = value.replace(/\s+/g, '').toUpperCase();
        return (
          <FilterBreadcrumb
            key={key}
            title={t('REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE') + ': '}
            icon={true}
            value={value}
            valueTitle={t(`REPORT.FILTERS.DROPDOWN.PREDICTEDSIZE.${formattedValue}`)}
            onClick={removeBreadcrumb}
          />
        );
      })}
      {predicted_size.map((value, key) => {
        const formattedValue = value.replace(/\s+/g, '_').toUpperCase();
        const valueTitle = t(`REPORT.FILTERS.DROPDOWN.STATUS.${formattedValue}`);
        return (
          <FilterBreadcrumb
            key={key}
            title={t('REPORT.FILTERS.DROPDOWN.STATUS') + ': '}
            icon={true}
            value={value}
            valueTitle={valueTitle}
            onClick={removeBreadcrumb}
          />
        );
      })}
      {mapFilters.filters.map((value: string, key: number) => {
        const valueTitle = t(`MAP_VIEW.FILTERS.TITLES.${value}`);
        return (<FilterBreadcrumb
          key={key}
          title={'Map Filter' + ': '}
          icon={true}
          value={value}
          valueTitle={valueTitle}
          onClick={removeMapFilterBreadcrumb}
        />
        );
      })}
    </Styled.BreadCrumbContainer>
  );
};

export default BreadCrumbContainer;
