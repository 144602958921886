import { ILog, ILogFilters } from '@/types/log.type';
import { IPaginationProps, IPaginationResponse, ISort } from '@/types/rtk.type';
import { buildQueryParams } from '@/utils/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../api';

export const logApi = createApi({
  reducerPath: 'logQuery',
  baseQuery: apiBaseQuery,
  tagTypes: ['Log'],
  endpoints: (builder) => ({
    getLogs: builder.query<IPaginationResponse<ILog> | ILog[], IPaginationProps & ILogFilters & ISort | void>({
      query: (params) => `/logs?${buildQueryParams(params || {})}`,
      providesTags: (result) => {
        const data = (result as IPaginationResponse<ILog>)?.data || result;
        return data
          ? [
            ...data.map(({ id }) => ({ type: 'Log' as const, id })),
            { type: 'Log', id: 'PARTIAL-LIST' },
          ]
          : [{ type: 'Log', id: 'PARTIAL-LIST' }];
      },
    }),
  }),
});

export const { useGetLogsQuery } = logApi;
