import { ReactComponent as Gear } from '@/assets/icons/gear.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const GearIcon = styled(Gear)`
  width: 23px;
  height: 20px;
  path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.black};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const Container = styled.div`
  max-height: 500px;
  overflow: auto;
`;
