import React, { FC } from 'react';
import * as Styled from './style';

interface ImageContainerProps {
  linkToAssets: string[];
}

const ImageContainer: FC<ImageContainerProps> = ({ linkToAssets }) => (
  <Styled.Wrapper>
    {linkToAssets.map((asset, key) => (
      <Styled.ImageContainer key={key}>
        <Styled.Image src={asset} alt={asset} />
      </Styled.ImageContainer>
    ))}
  </Styled.Wrapper>
);

export default ImageContainer;
