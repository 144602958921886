import React, { ChangeEvent, FC } from 'react';
import * as Styled from './style';

type Props = {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  label?: string;
  disabled?: boolean;
  testId?: string;
};

const Switch: FC<Props> = ({ checked, onChange, name, label, disabled, testId }) => (
  <Styled.Container disabled={Boolean(disabled)} data-testid={testId || 'switch'}>
    {label && <Styled.LabelText data-testid="switch-label">{label}</Styled.LabelText>}
    <Styled.Label checked={checked} />
    <Styled.Input
      disabled={disabled}
      name={name}
      checked={checked}
      onChange={!disabled ? onChange : () => {}}
      type="checkbox"
      data-testid="switch-checkbox"
    />
  </Styled.Container>
);
export default Switch;
