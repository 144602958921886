import { DangerDialog } from '@/components';
import { Modal } from '@/components/Modal';
import { ITablePaginate, TableSortOrders } from '@/components/Table/types';
import withPageLoader from '@/HOCs/withPageLoader';
import { ModalStatus } from '@/hooks/useModalStatus';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { ICompany } from '@/types/company.type';
import { IRole, IUser } from '@/types/user.type';
import React, { FC } from 'react';
import { CreateUser } from './CreateUser';
import { CreateUserForm } from './CreateUser/CreateUser';
import { UserTable } from './UserTable';

interface UsersProps {
  isAddUserModalOpen: boolean;
  onAddUserModalShow: () => void;
  onAddUserModalHide: () => void;
  onDeleteUser: () => void;
  deleteConfirmation: (user: IUser) => void;
  onCreateUser: (data: CreateUserForm) => void;
  deleteUserModal: ModalStatus;
  users: IUser[];
  roles: IRole[];
  companies: ICompany[];
  onPageChange: (page: ITablePaginate) => void;
  page: number;
  pages: number;
  userCreateLoading: boolean;
  totalCount: number;
  onUserEdit: (user: IUser) => void;
  setSorting: (orderBy: string, order: string) => void;
  order: TableSortOrders;
  orderBy: string;
}

const Users: FC<UsersProps> = ({
  isAddUserModalOpen,
  onAddUserModalHide,
  onAddUserModalShow,
  onCreateUser,
  onDeleteUser,
  deleteConfirmation,
  deleteUserModal,
  roles,
  companies,
  users,
  onPageChange,
  page,
  pages,
  userCreateLoading,
  totalCount,
  onUserEdit,
  setSorting,
  order,
  orderBy,
}) => {
  const { prefixedT } = useCustomTranslation('USERS_PAGE');
  return (
    <div data-testid="usersPage" style={{ padding: '40px' }}>
      <Modal title="Add New User" isOpen={isAddUserModalOpen} onClose={onAddUserModalHide}>
        <CreateUser
          roles={roles}
          companies={companies}
          onSubmit={onCreateUser}
          userCreateLoading={userCreateLoading}
        />
      </Modal>
      <DangerDialog
        title={prefixedT('DELETE_USER.TITLE')}
        open={deleteUserModal.isOpen}
        onCancel={deleteUserModal.hide}
        onConfirm={onDeleteUser}
      />
      <UserTable
        users={users}
        onPageChange={onPageChange}
        page={page}
        pages={pages}
        totalCount={totalCount}
        onAddUserModalShow={onAddUserModalShow}
        onUserEdit={onUserEdit}
        onUserDelete={deleteConfirmation}
        setSorting={setSorting}
        order={order}
        orderBy={orderBy}
      />
    </div>
  );
};

export default withPageLoader(Users);
