import { WidgetType } from '@/types/report.type';
import Filters from './Filters/Filters';
import MapView from './MapView/MapView';
import Summary from './Summary/Summary';
import TableOfResults from './TableOfResults/TableOfResults';

const mapping = {
  [WidgetType.MAP]: MapView,
  [WidgetType.RESULTS]: TableOfResults,
  [WidgetType.FILTERS]: Filters,
  [WidgetType.SUMMARY]: Summary,
};

export default mapping;
