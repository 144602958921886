import useCustomTranslation from '@/localization/useCustomTranslation';
import React, { FC } from 'react';

const EditCompanyCrumb: FC = () => {
  const { prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE');

  return (
    <span>{prefixedT('CRUMB')}</span>
  );
};

export default EditCompanyCrumb;
