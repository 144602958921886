import { IErrorData } from '@/types/rtk.type';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const getRTKError = (error?: FetchBaseQueryError | SerializedError) => {
  const errorObject = (error as FetchBaseQueryError)?.data || null;
  return errorObject as IErrorData | null;
};

export const buildQueryParams = (params: { [key: string]: any } = {}) =>
  Object.keys(params)
    .map((el) => (params[el] ? `${el}=${params[el]}` : null))
    .filter((el) => Boolean(el))
    .join('&');
