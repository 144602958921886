import DropDown, { DropDownOption } from '@/components/DropDown/DropDown';
import TextField from '@/components/TextField/TextField';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { ReportDataSource } from '@/types/report.type';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { InitialSummaryValues, Option, types } from './service';
import * as Styled from './style';

type Props = {
  filter: Option;
  source: ReportDataSource | undefined;
  index: number;
  blockRef: React.RefObject<HTMLDivElement>;
};

const optionsMapper = (el: { name: string; id: number }) => ({ label: el.name, value: el.id });

const SummarySettingsItem: FC<Props> = ({ source, filter, index, blockRef }) => {
  const { setFieldValue, values, errors } = useFormikContext<InitialSummaryValues>();
  const {t} = useCustomTranslation('');

  const onChange = (name: string, value: string | number) => {
    if (['columnId'].includes(name)) {
      setFieldValue(`options[${index}].typeId`, null);
    }
    if (['columnId', 'typeId'].includes(name)) {
      setFieldValue(`options[${index}].title`, '');
    }
    setFieldValue(`options[${index}].${name}`, value);
  };

  const onDelete = (id: string) => {
    const _filters = values.options.filter((item) => item.id !== id);
    setFieldValue('options', _filters, true);
  };

  return (
    <Styled.FilterItemContainer error={Boolean(errors.options && errors.options[index])}>
      <Styled.FilterItem>
        <Field
          as={DropDown}
          options={source?.columns?.map(optionsMapper) || []}
          label={t('SUMMARY.FORM.COLUMN.LABEL')}
          placeholder={t('SUMMARY.FORM.COLUMN.PLACEHOLDER')}
          onChange={(option: DropDownOption) => onChange('columnId', option.value)}
          value={filter.columnId}
          countOptions={4}
          blockRef={blockRef}
        />
        <Field
          as={DropDown}
          options={types.map(optionsMapper) || []}
          label={t('SUMMARY.FORM.TYPE.LABEL')}
          placeholder={t('SUMMARY.FORM.TYPE.PLACEHOLDER')}
          onChange={(option: DropDownOption) => onChange('typeId', option.value)}
          value={filter.typeId}
          countOptions={4}
          blockRef={blockRef}
        />
        <Field
          as={TextField}
          label={t('SUMMARY.FORM.TITLE.LABEL')}
          placeholder={t('SUMMARY.FORM.TITLE.PLACEHOLDER')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('title', e.target.value)}
          value={filter.title}
          blockRef={blockRef}
        />
      </Styled.FilterItem>
      <Styled.FilterDeleteContainer onClick={() => onDelete(filter.id)}>
        <Styled.FilterDelete>
          <Styled.DeleteLight />
        </Styled.FilterDelete>
      </Styled.FilterDeleteContainer>
    </Styled.FilterItemContainer>
  );
};

export default SummarySettingsItem;
