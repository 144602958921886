import { Styles } from '@/types/styles.type';
import React, { FC } from 'react';
import * as Styled from './style';

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLElement>) => void;
  value: string | number;
  label?: string;
  placeholder?: string;
  name: string;
  type?: string;
  disabled?: boolean;
  error?: string;
  testId?: string;
  styles?: Styles;
}

const TextField: FC<IProps> = ({
  onChange,
  value = '',
  label,
  placeholder,
  name,
  type = 'text',
  disabled,
  error,
  testId,
  styles,
}) => (
  <Styled.Container>
    {label && (
      <Styled.Label labelStyle={styles?.label} isError={!!error}>
        {label}
      </Styled.Label>
    )}
    <Styled.Field
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      name={name}
      type={type}
      disabled={disabled}
      error={error}
      data-testid={testId || 'text-field'}
    />
    {Boolean(error) && <Styled.Error>{error}</Styled.Error>}
  </Styled.Container>
);
export default React.memo(TextField);
