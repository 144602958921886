import { DangerDialog, Modal } from '@/components';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ITablePaginate } from '@/components/Table/types';
import withPageLoader from '@/HOCs/withPageLoader';
import { ModalStatus } from '@/hooks/useModalStatus';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { IBranch } from '@/types/branch.type';
import { EditCompanyValues, ICompany } from '@/types/company.type';
import { IRole, IUser, UserRoles } from '@/types/user.type';
import React, { FC } from 'react';
import { AddBranch } from './AddBranch';
import { AddBranchForm } from './AddBranch/AddBranch';
import { BranchesTable } from './BranchesTable';
import { CreateUser } from './CreateUser';
import { CreateUserForm } from './CreateUser/CreateUser';
import EditBranch, { EditBranchForm } from './EditBranch/EditBranch';
import { EditCompanyForm } from './EditCompanyForm';
import { EmployeesTable } from './EmployeesTable';
import * as Styled from './style';
interface EditCompanyProps {
  company?: ICompany;
  selectedBranch?: IBranch;
  showEditBranch: (branch: IBranch) => void;
  showDeleteEmployee: (employee: IUser) => void;
  showDeleteBranch: (branch: IBranch) => void;
  onCompanyEdit: (data: EditCompanyValues, callback?: () => void) => void;
  onEmployeeCreate: (data: CreateUserForm) => void;
  onBranchAdd: (data: AddBranchForm) => void;
  onBranchEdit: (data: EditBranchForm) => void;
  onBranchDelete: () => void;
  onCompanyDelete: () => void;
  onEmployeeDelete: () => void;
  employeePage: number;
  onEmployeePageChange: (p: ITablePaginate) => void;
  onEmployeeEdit: (user: IUser) => void;
  employees: IUser[];
  employeesCount: number;
  roles: IRole[];
  pages: number;
  deleteCompanyModal: ModalStatus;
  deleteEmployeeModal: ModalStatus;
  employeeModal: ModalStatus;
  branchAddModal: ModalStatus;
  branchEditModal: ModalStatus;
  branchDeleteModal: ModalStatus;
  profile: IUser;
}

const EditCompany: FC<EditCompanyProps> = ({
  company,
  roles,
  selectedBranch,
  showEditBranch,
  showDeleteBranch,
  onBranchAdd,
  onBranchEdit,
  onBranchDelete,
  onCompanyEdit,
  onEmployeeCreate,
  onCompanyDelete,
  onEmployeeDelete,
  showDeleteEmployee,
  branchAddModal,
  branchEditModal,
  branchDeleteModal,
  employees,
  employeesCount,
  employeePage,
  pages,
  deleteCompanyModal,
  deleteEmployeeModal,
  employeeModal,
  onEmployeePageChange,
  onEmployeeEdit,
  profile,
}) => {
  const { prefixedT } = useCustomTranslation('EDIT_COMPANY_PAGE');
  return (
    <>
      {profile?.role?.code === UserRoles.SUPER_ADMIN && <Breadcrumbs />}
      {company && (
        <>
          <EditCompanyForm
            company={company}
            onSubmit={onCompanyEdit}
            onDelete={deleteCompanyModal.show}
            profile={profile}
          />
          <Styled.EditCompanyTable>
            <BranchesTable
              branches={company.branches || []}
              totalCount={company.branches?.length}
              onBranchAdd={branchAddModal.show}
              onBranchEdit={showEditBranch}
              onBranchDelete={showDeleteBranch}
            />
          </Styled.EditCompanyTable>
          <Styled.EditCompanyTable>
            <EmployeesTable
              employees={employees}
              totalCount={employeesCount}
              page={employeePage}
              pages={pages}
              onEmployeeDelete={showDeleteEmployee}
              onEmployeeEdit={onEmployeeEdit}
              onEmployeeAdd={employeeModal.show}
              onEmployeePageChange={onEmployeePageChange}
            />
          </Styled.EditCompanyTable>
          <Modal
            title={prefixedT('ADD_EMPLOYEE.TITLE')}
            isOpen={employeeModal.isOpen}
            onClose={employeeModal.hide}
          >
            <CreateUser roles={roles} company={company} onSubmit={onEmployeeCreate} />
          </Modal>
          <Modal
            title={prefixedT('ADD_BRANCH.TITLE')}
            isOpen={branchAddModal.isOpen}
            onClose={branchAddModal.hide}
          >
            <AddBranch onSubmit={onBranchAdd} branches={company.branches || []} />
          </Modal>
          <Modal
            title={prefixedT('EDIT_BRANCH.TITLE')}
            isOpen={branchEditModal.isOpen}
            onClose={branchEditModal.hide}
          >
            {selectedBranch && <EditBranch branch={selectedBranch} onSubmit={onBranchEdit} />}
          </Modal>
          <DangerDialog
            title={prefixedT('DELETE_BRANCH.TITLE')}
            open={branchDeleteModal.isOpen}
            onCancel={branchDeleteModal.hide}
            onConfirm={onBranchDelete}
          />
          <DangerDialog
            title={prefixedT('DELETE_EMPLOYEE.TITLE')}
            open={deleteEmployeeModal.isOpen}
            onCancel={deleteEmployeeModal.hide}
            onConfirm={onEmployeeDelete}
          />
          <DangerDialog
            title={prefixedT('DELETE_COMPANY.TITLE')}
            open={deleteCompanyModal.isOpen}
            onCancel={deleteCompanyModal.hide}
            onConfirm={onCompanyDelete}
          />
        </>
      )}
    </>
  );
};
export default withPageLoader(EditCompany);
