import { InitialRequestState } from '@/types/request.type';
import { IInitialUserState } from '@/types/user.type';
import { getApiEntities } from '@/utils/redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import config from '../config';
import activeAccount, { ActiveAccountState } from './activeAccount/activeAccount.slice';
import { branchApi } from './branches/branches.api';
import { companyApi } from './company/company.api';
import { dashboardApi } from './dashboard/dashboard.api';
import { logApi } from './log/log.api';
import { portalCompaniesApi } from './portalCompanies/portalCompanies.api';
import { reportApi } from './reports/reports.api';
import request from './request/request.slice';
import { sweepApi } from './sweep/sweep.api';
import { userApi } from './user/user.api';
import user from './user/user.slice';

export interface RootState {
  user: IInitialUserState;
  request: InitialRequestState;
  activeAccount: ActiveAccountState;
}

const apiEntities = [userApi, companyApi, branchApi, logApi, reportApi, portalCompaniesApi, sweepApi, dashboardApi];
const sliceEntities = { user, request, activeAccount };

const { reducers, middlewares } = getApiEntities(apiEntities);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['activeAccount'],
};

const rootReducer = combineReducers({ ...sliceEntities, ...reducers });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: config.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => [...middlewares, ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  })],
});

export const persistor = persistStore(store);
