import { IRole, UserRoles } from '@/types/user.type';
export const UserRole = {
  SAD: 'USER_ROLE.SUPER_ADMIN',
  CA: 'USER_ROLE.COMPANY_ADMIN',
  COM: 'USER_ROLE.COMPANY_MANAGER',
  COA: 'USER_ROLE.COMPANY_ENGINEER',
  COE: 'USER_ROLE.COMPANY_ANALYST',
};

export const checkRestictedPermissions = (permissions: string[], role = '') =>
  permissions.includes(role);

export const filterRolesAccordingToUserRole = (roles: IRole[], code: string) => {
  const adminRoles = [UserRoles.COMPANY_ADMIN, UserRoles.SUPER_ADMIN];

  switch (code) {
    case UserRoles.SUPER_ADMIN:
      return roles;
    case UserRoles.COMPANY_ADMIN:
      return roles.filter((role) => role.code !== UserRoles.SUPER_ADMIN);
    default:
      return roles.filter((role) => !adminRoles.includes(role.code));
  }
};
