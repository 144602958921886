import { ReactComponent as PlusButtonIcon } from '@/assets/icons/plus.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const EditCompanyTable = styled.div`
  margin-top: 39px;
`;

export const EditButtonContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover svg path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  }
`;

export const PlusIcon = styled(PlusButtonIcon)`
  margin-right: 5px;
  & path {
    fill: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightBlue};
  }
`;
