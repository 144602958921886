import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow_down.svg';
import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

const LANGUAGE_ITEM_HEIGHT = 35;
const LANGUAGE_LIST_WIDTH = 160;

export const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ActiveLanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  & :first-child {
    margin-right: 8px;
  }

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    & :first-child {
      margin-right: 0;
    }
  }
`;

export const ArrowDownIcon = styled(ArrowIcon)<{
  open: boolean;
}>`
  transform: scaleY(${({ open }) => (open ? -1 : 1)});
  transition: 0.3s ease transform;
`;
export const CheckLanguageIcon = styled(CheckIcon)``;

export const ActiveLanguage = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;

export const LanguageList = styled.div<{
  open: boolean;
}>`
  position: absolute;
  z-index: 99;
  top: calc(100% + 10px);
  max-height: ${LANGUAGE_ITEM_HEIGHT * 5}px;
  overflow: hidden;
  transform: translateX(-10%);
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border-radius: 8px;
  border: 2px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.lightGray};
  padding: 8px 0;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.07);
  width: ${LANGUAGE_LIST_WIDTH}px;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    right: calc(50% - 34px);
  }
`;

export const LanguageItem = styled.div`
  height: ${LANGUAGE_ITEM_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 15px;
  :hover {
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.lightBlue}33;
  }
  transition: 0.3s ease background-color;
`;

export const ItemWrapper = styled.div<{
  bold: boolean;
}>`
  display: flex;
  align-items: center;

  font-weight: ${({ bold }) => (bold ? '500' : '400')};
  font-size: 14px;
  color: ${({ theme }: ThemeProps<Theme>) => theme.colors.deepDark};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  max-width: 22px;
`;

export const ItemName = styled.div`
  @media (max-width: ${({ theme }: ThemeProps<Theme>) => theme.breakpoints.m}) {
    display: none;
  }
`;
