import { useLazyGetCompaniesQuery } from '@/redux/company/company.api';
import {
  useCreateUserMutation,
  useGetProfileQuery,
  useGetRolesQuery
} from '@/redux/user/user.api';
import { ICompany } from '@/types/company.type';
import { IRole, UserRoles } from '@/types/user.type';

export const useCreateUserData = () => {
  const { data: user } = useGetProfileQuery();
  const [fetchCompanies, companiesResult] = useLazyGetCompaniesQuery();
  const rolesResult = useGetRolesQuery();
  const [createUser, { isLoading: userCreateLoading }] = useCreateUserMutation();
  const isSuperAdmin = (user?.role as IRole)?.code === UserRoles.SUPER_ADMIN;

  const fetchCreateUserData = async () => {
    const requests = [isSuperAdmin && fetchCompanies()];
    return await Promise.all(requests);
  };

  let roles = (rolesResult.data || []);
  let companies = (companiesResult.data || []) as ICompany[];
  if (!isSuperAdmin) {
    roles = roles.filter(({ code }) => code !== UserRoles.SUPER_ADMIN);
    if (user?.company) {
      companies = [user.company];
    }
  }

  return {
    fetchCreateUserData,
    createUser,
    companies,
    roles,
    userCreateLoading,
    isCompanyLoading: companiesResult.isFetching,
  };
};
