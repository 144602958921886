import styled from 'styled-components';

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }

  &:hover + label:before {
    background: #00699D;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 2px rgb(85 98 255 / 12%);
  }

  &:checked + label:before {
    background: #00699D;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
`;
