import { DropDown } from '@/components';
import { DropDownOption } from '@/components/DropDown/DropDown';
import useCustomTranslation from '@/localization/useCustomTranslation';
import { TimeRange, TimeRangeOptions } from '@/Providers/DashboardProvider';
import { format, sub } from 'date-fns';
import React, { FC } from 'react';

interface TimeRangeSelectProps {
  onTimeRangeChange: (option: { startDate: string; endDate: string; defaultTimeRange: TimeRangeOptions }) => void;
  timeRange: TimeRange;
}

const TimeRangeSelect: FC<TimeRangeSelectProps> = ({ onTimeRangeChange, timeRange }) => {
  const { prefixedT } = useCustomTranslation('DASHBOARD_PAGE');

  const handleOptionSelectTimeRange = (option: DropDownOption): void => {
    let startDate = '';
    let endDate = '';
    const currentDate = new Date();

    switch (option.value) {
      case TimeRangeOptions.Today:
        startDate = parseDate(currentDate);
        endDate = parseDate(currentDate);
        break;
      case TimeRangeOptions.LastWeek:
        startDate = parseDate(sub(currentDate, { days: 7 }));
        endDate = parseDate(currentDate);
        break;
      case TimeRangeOptions.LastMonth:
        startDate = parseDate(sub(currentDate, { months: 1 }));
        endDate = parseDate(currentDate);
        break;
      case TimeRangeOptions.LastYear:
        startDate = parseDate(sub(currentDate, { years: 1 }));
        endDate = parseDate(currentDate);
        break;
      case TimeRangeOptions.LastYear:
        break;
    }

    onTimeRangeChange({ startDate, endDate, defaultTimeRange: option.value as TimeRangeOptions});
  };

  return (
    <>
      <DropDown
        value={timeRange.defaultTimeRange}
        options={[{
          value: TimeRangeOptions.Today,
          label: prefixedT('TIME_RANGE.TODAY'),
        },
        {
          value: TimeRangeOptions.LastWeek,
          label: prefixedT('TIME_RANGE.LAST_7_DAYS'),
        },
        {
          value: TimeRangeOptions.LastMonth,
          label: prefixedT('TIME_RANGE.LAST_30_DAYS'),
        },
        {
          value: TimeRangeOptions.LastYear,
          label: prefixedT('TIME_RANGE.LAST_YEAR'),
        },
        ]}
        onChange={(value) => handleOptionSelectTimeRange(value as DropDownOption)}
        placeholder={timeRange.defaultTimeRange}
      />
    </>
  );
};

export default TimeRangeSelect;

export const parseDate = (date: Date): string => format(date, 'yyyy-MM-dd');
