import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const DescContainer = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.seashell};
  border-radius: 10px;
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};;
  text-transform: capitalize;
`;

export const DescTitle = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;
export const DescAnswer = styled.p`
  font-size: 40px;
`;
