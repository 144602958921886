import useOutsideClick from '@/hooks/useOutsideClick';
import { IReactChildren, ReactSVGIcon } from '@/types/common.type';
import { AnimatePresence } from 'framer-motion';
import React, { FC, ReactElement, useRef } from 'react';
import { StyledComponent } from 'styled-components';
import * as Styled from './style';

export type PopupMenuItem = {
  title: string;
  icon?: ReactSVGIcon | StyledComponent<any, any>;
  handler: () => void;
};

type Props = {
  open: boolean;
  onClose: () => void;
  control: ReactElement | ReactElement[] | IReactChildren | string;
  items: PopupMenuItem[];
};

const animationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

const PopupMenu: FC<Props> = ({ open, onClose, control, items }) => {
  const ref = useRef(null);

  useOutsideClick(ref, onClose, open);

  return (
    <Styled.Wrapper>
      <Styled.Control>{control}</Styled.Control>
      <AnimatePresence>
        {open && items.length ? (
          <Styled.Container data-testid="popup-menu" ref={ref} {...animationProps}>
            <Styled.Menu>
              {items.map(({ title, icon: Icon, handler }) => (
                <Styled.MenuItem onClick={handler} key={title} data-testid="popup-menu-item">
                  {Icon && <Icon />}
                  <Styled.MenuItemText>{title}</Styled.MenuItemText>
                </Styled.MenuItem>
              ))}
            </Styled.Menu>
          </Styled.Container>
        ) : null}
      </AnimatePresence>
    </Styled.Wrapper>
  );
};

export default PopupMenu;
