import { createBrowserRouter, useRoutes } from 'react-router-dom';
import { generateRouterObject, routes } from '.';

const router = generateRouterObject(routes);

const AppRouter = () => {
  const reactRoutes = useRoutes(router);
  return reactRoutes;
};

export const appRouter = createBrowserRouter(router);
export default AppRouter;
