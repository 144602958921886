import { Theme } from '@/theme';
import styled, { ThemeProps } from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const DateContainer = styled.div<{
  positive: boolean;
}>`
  background: ${({ theme }: ThemeProps<Theme>) => theme.colors.white};
  border-top: 10px solid ${({ positive }) => (positive ? '#4FE3C1' : '#F96579')};
  border-radius: 10px;
  padding: 20px 35px 30px 35px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.seashell};
  border-bottom: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.seashell};
  border-left: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.seashell};
`;

export const Title = styled.h3<{
  positive: boolean;
}>`
  color: ${({ positive }) => (positive ? '#4FE3C1' : '#F96579')};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
`;

export const Year = styled.span`
  color: #6C6D71;
  font-size: 40px;
`;

export const MonthAndDay = styled.span`
  color: #6C6D71;
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  margin-bottom: 10px;
`;
